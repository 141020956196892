import {useField, useFormikContext} from "formik";
import React, {useEffect} from "react";
import {red} from "../../styles/colors";
import {Box, Switch, TextField} from "@mui/material";
import ReactJson from "react-json-view";
import _ from "lodash";
import {RichTextEditor} from "../RichTextEditor";
import TextareaAutosize from '@mui/base/TextareaAutosize';

// @ts-ignore
export const MyTextArea = ({label, ...props}) => {
    // @ts-ignore
    const [field, meta] = useField(props);
    return (
        <div className={props.className ? props.className : "flex flex-col rounded-md"}>
            <label htmlFor={props.id || props.name}>{label}</label>

            <TextareaAutosize
                minRows={props.minRows ?? 3}
                className="text-area rounded-md" {...field} {...props} />
            {meta.touched && meta.error && <Box color={red}>{meta.error}</Box>}
        </div>
    );
};


export const MyJsonFormatedTextArea = ({label, ...props}) => {
    // @ts-ignore
    const [field, meta] = useField(props);

    const jsonString = field.value;

    //format json
    const jsonObject = JSON.parse(jsonString);
    const formattedJson = JSON.stringify(jsonObject, null, 2);

    //assign formatted json to field
    field.value = formattedJson;

    return (
        <div className={props.className ? props.className : "flex flex-col rounded-md"}>
            <label htmlFor={props.id || props.name}>{label}</label>

            <TextareaAutosize
                minRows={props.minRows ?? 3}
                className="text-area rounded-md" {...field} {...props} />
            {meta.touched && meta.error && <Box color={red}>{meta.error}</Box>}
        </div>
    );
};


//component that renders either  MyJsonTextArea or MyTextArea with a switch to toggle between the two
export const MyTextAreaOrJson = ({label, ...props}) => {
    const [mode, setMode] = React.useState<'json' | 'text'>('text');

    return <div>
        <Switch checked={mode === 'json'}
                onClick={() => {
                    setMode(mode === 'json' ? 'text' : 'json');
                }}/>

        {mode === 'json' ?
            <MyJsonTextArea label={label} {...props}/> :
            <MyJsonFormatedTextArea label={label} {...props}/>
        }
    </div>
}

export const MyJsonTextArea = ({label, ...props}) => {
    // @ts-ignore
    const {values, setFieldValue, setFieldTouched, getFieldMeta, getFieldProps} = useFormikContext<any>();
    const value = getFieldProps(props.name).value;
    //const [field, meta] = useField(props);
    return (
        <div className={props.className ? props.className : "flex flex-col"}>
            <label htmlFor={props.id || props.name}>{label}</label>

            {/* <textarea className="text-area" {...field} {...props} />*/}
            <ReactJson
                onEdit={(e) => {
                    setFieldValue(props.name, JSON.stringify(e.updated_src));
                }}
                onAdd={(e) => {
                    setFieldValue(props.name, JSON.stringify(e.updated_src));
                }}
                onDelete={(e) => {
                    setFieldValue(props.name, JSON.stringify(e.updated_src));
                }}
                src={JSON.parse(value as any)}/>

        </div>
    );
};


// @ts-ignore
export const MyRichText = ({label, resolver, calculatedValue, ...props}) => {
    // @ts-ignore
    const [field, meta, helpers] = useField(props.name);
    const [html, setHtml] = React.useState('');
    useEffect(() => {
        calculatedValue && setHtml(calculatedValue);
    }, [calculatedValue]);

    return (
        <div className="flex flex-col">
            <label htmlFor={props.id || props.name}>{label}</label>

            <RichTextEditor
                isEditable={props.isEditable ?? true}
                html={html}
                onChange={(e) => {
                    helpers.setValue(resolver ? resolver(e) : e);
                }}/>
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
};


// @ts-ignore
export const MyRichText2 = ({label, resolver, calculatedValue, maxWidth = '50%', ...props}) => {
    // @ts-ignore
    const [field, meta, helpers] = useField(props);
    const [html, setHtml] = React.useState('');
    useEffect(() => {
        calculatedValue && setHtml(calculatedValue);
    }, []);

    return (
        <div className="flex flex-col" style={{maxWidth: maxWidth}}>
            <label htmlFor={props.id || props.name}>{label}</label>

            <RichTextEditor
                html={html}
                onChange={(e) => {
                    helpers.setValue(resolver ? resolver(e) : e);
                }}/>
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
};


export const MyRichText3 = ({label, resolver, calculatedValue, maxWidth = '50%', ...props}) => {
    // @ts-ignore
    const [field, meta, helpers] = useField(props);
    const [html, setHtml] = React.useState('');

    //strore a state to toggle the changeFormExternal as useState
    const [changeFormExternal, setChangeFormExternal] = React.useState(false);


    useEffect(() => {
        setHtml(calculatedValue);
        setChangeFormExternal(true);

    }, [calculatedValue]);

    return (
        <div className="flex flex-col" style={{maxWidth: maxWidth}}>
            <label htmlFor={props.id || props.name}>{label}</label>

            <RichTextEditor
                html={html}
                onChange={(e) => {
                    if (changeFormExternal) {
                        setChangeFormExternal(false)
                    } else {
                        helpers.setValue(resolver ? resolver(e) : e);
                    }


                }}/>
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}
        </div>
    );
};