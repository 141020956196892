import {addMonths} from "date-fns";
import {SiDatadog} from "react-icons/si";
import React, {useEffect, useState} from "react";
import {CircleButton} from "../_common/lib";
import Tooltip from "@mui/material/Tooltip";
import {purple} from "@mui/material/colors";
import {useLocation} from "react-router-dom";
import {Rights, useRightCheck} from "../../utils/UseRightCheck";
import {SimpleComponentWithTitle} from "../../components/SimpleComponentWithTitle";
import TextField from "@mui/material/TextField";
import {ActionWrapper} from "../../components/table/ActionWrapper";

export function Datadog({productId, clientId, siteId, compteId, provenanceId, extractId, fromLocation, path}: {
    productId?: number,
    clientId?: number,
    siteId?: number,
    compteId?: number,
    extractId?: number,
    provenanceId?: number,
    fromLocation?: boolean,
    path?: string
}) {
    const from_ts = addMonths(new Date(), -1).getTime();
    const to_ts = Date.now();

    // @ts-ignore
    let env = window.REACT_APP_PROXY_TARGET || process.env.REACT_APP_PROXY_TARGET;
    switch (env) {
        case "production":
        case "Production":
            env = 'Production%20OR%20production';
            break;
        case "uat":
        case "Uat":
            env = 'Uat%20OR%20uat';
            break;
        case "development":
        case "Development":
        case "dev":
        case "Dev":
            env = 'Dev%20OR%20dev';
            break;
        default:
            env = 'Local%20OR%20local';
    }

    const datadog = useRightCheck(Rights.ClientModifierEnchere);


    const [params, setParams] = useState('');
    const location = useLocation();
    useEffect(() => {
        setParams(``);
        if (productId) {
            setParams((val) => val + `%20%40Properties.ProductId%3A${productId}`)
        }
        if (clientId) {
            setParams((val) => val + `%20%40Properties.usr.id%3A${clientId}`)
        }
        if (siteId) {
            setParams((val) => val + `%20%40Properties.SiteId%3A${siteId}`)
        }
        if (compteId) {
            setParams((val) => val + `%20%40Properties.CompteId%3A${compteId}`)
        }
        if (provenanceId) {
            setParams((val) => val + `%20%40Properties.ProvenanceId%3A${provenanceId}`)
        }
        if (extractId) {
            setParams((val) => val + `%20%40Properties.ExtractId%3A${extractId}`)
        }
        if (fromLocation || path) {
            setParams((val) => val + `%20%40MessageTemplate%3A%22%7BAction%7D%3A%20%7BPath%7D%20from%20%7BFrom%7D%20%28host%3A%20%7BHost%7D%29%22%20`);
            if (fromLocation && !clientId) {
                setParams((val) => val + `%20%40Properties.From%3A${(location.pathname as string).replace(/\d+/g, '*')}`);
            }

            if (path) {
                if (!path.startsWith('/')) {
                    path = '/' + path;
                }
                path = path.replace(/\{.*?\}/g, '*').replace(/\//g, '\\/');
                setParams((val) => val + `%20%40Properties.Path%3A%28${path}%20OR%20${path + '?*'}%29`);
            }
        }
    }, [params, env, from_ts, to_ts, location.pathname]);

    const href = `https://app.datadoghq.com/logs?query=${params}%20%40Properties.Environment%3A%28${env}%29&additional_filters=%5B%7B%7D%5D&agg_q=status%2Cservice&cols=service%2C%40Properties.usr.id%2C%40Properties.ProductId%2C%40Properties.SiteId%2C%40Properties.ProvenanceId&index=%2A&messageDisplay=inline&sort_m=%2C&sort_t=%2C&stream_sort=time%2Cdesc&top_n=10%2C10&top_o=top%2Ctop&viz=stream&x_missing=true%2Ctrue&from_ts=${from_ts}&to_ts=${to_ts}&live=true`;

    return useRightCheck(Rights.IT) ? <ActionWrapper  actionId={"dataDog"}><Tooltip title={`Logs Datadog`}>
        <a href={href} target="_blank">
            <CircleButton>
                <SiDatadog className={"text-2xl"} color={purple[800]}/>
            </CircleButton>
        </a>
    </Tooltip></ActionWrapper> : null
}


export function DatadogEndpointSearch() {

    const [endpoint, setEndpoint] = useState('');

    return <SimpleComponentWithTitle title="Datadog" icon={<SiDatadog color={purple[800]}/>}>
        <div className="flex gap-2 items-center">
            Endpoint:
            <TextField key="backend-endpoint" size="small" className="grow"
                       onChange={(event) => setEndpoint(event.target.value)}/>
            <Datadog path={endpoint}/>
        </div>
    </SimpleComponentWithTitle>;
}
