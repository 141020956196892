import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {AiOutlineMore} from 'react-icons/ai';
import {TableOptions} from "./FilteredTable";
import {FilterChip} from "./FilterChip";
import {SortChip} from "./SortChip";
import {HtmlTooltip} from "../forms/Tooltip";
import {Button} from "@mui/material";
import {BiReset} from "react-icons/bi";
import DateRangeFilter from "./filters/DateRangeFilter";
import {useViewConfigForRoute} from "../../dashboards/_common/UseViewConfigForRoute";
import {CustomFilter} from "../CustomFilter";
import ModalEditComponent from "../modals/ModalEditComponent";
import {VscJson} from "react-icons/vsc";
import ReactJson from "react-json-view";
import {useDebugMode} from "../../utils/UseDebugMode";
import {BsArrowsCollapse, BsArrowsExpand} from "react-icons/bs";
import {withStyles} from "@material-ui/core";
import MultiSelectFilter from "./filters/MultiSelectFilter";
import {useFilteringSortingPagination} from "../../utils/UseFilteringSortingPagination";
import {saveAs} from "file-saver";
import {toast} from "react-toastify";

const StyledMenuItem = withStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        //no animation on menu selection
        '&.Mui-selected': {
            backgroundColor: 'transparent',

        }

    },
})(MenuItem);
export default function MoreMenu<T>({
                                        options,
                                    }: {
    options: TableOptions<T>

}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const {
        instance,
        dataQuery,
        onDateChange,
        dateFilter,
        dateFilterColumn,
        filterOptions,
        dataQueryExtracts,
        queryKey
    } = options;
    const dateFilterColumnHeader = instance.getAllLeafColumns().find(f => f.id === dateFilterColumn);

    //get first letter of name capitalised
    const viewConfiguration = useViewConfigForRoute();
    const hideDateFilter = viewConfiguration?.hideDateFilter ?? false;
    const hideCustomBooleanFilter = viewConfiguration?.hideCustomBooleanFilter ?? false;
    const debugMode = useDebugMode();
    const src = (options as any).dataQuery?.data?.results ?? (options as any).dataQuery?.data;
    // @ts-ignore
    const header = (dateFilterColumnHeader)?.columnDef?.header();

    const [extractId, setExtractId] = React.useState<number | undefined>(undefined);
    const extractQuery = useFilteringSortingPagination({
        queryKey: `${queryKey}/extract/${extractId}`,
        filterOptions: filterOptions,
        isExport: true,
    });

    useEffect(() => {
        if (extractId) {
            extractQuery.refetch({}).then((data: any) => {
                const title = dataQueryExtracts?.data?.find((d: any) => d.id == extractId)?.label ?? 'Extract';
                const blob = new Blob([data.data], {type: "application/csv"});
                saveAs(blob, `${title}.csv`);
                toast.success(`Fichier téléchargé avec succès`);
            })
        }
    }, [extractId]);

    return (
        <React.Fragment>
            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                <Tooltip title="Plus d'options">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ml: 2}}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <AiOutlineMore className={"text-white text-2xl"}/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <StyledMenuItem>
                    {options.filterOptions?.columnFilters?.length > 0 &&
                        <FilterChip options={options}/>}
                </StyledMenuItem>
                <StyledMenuItem>
                    {options.filterOptions?.sorting && options.filterOptions?.sorting?.length > 0 &&
                        <SortChip options={options}/>}
                </StyledMenuItem>
                <StyledMenuItem>

                    <HtmlTooltip title={"Remettre le tri par défault"}>
                        <Button
                            className="p-2 border shadow rounded cursor-pointer  bg-transparent"
                            onClick={() => {
                                instance.getAllLeafColumns().forEach(f => {
                                    f.clearSorting();
                                });
                                instance.getAllLeafColumns().forEach(f => {
                                    if (options.defaultSorts && options.defaultSorts.map(d => d.id).includes(f.id)) {
                                        const sort = options.defaultSorts.find(d => d.id === f.id);
                                        f.toggleSorting(!!sort?.desc, true);
                                    }
                                })
                            }}><BiReset size={20}/></Button>
                    </HtmlTooltip>
                </StyledMenuItem>
                <StyledMenuItem>
                    {!hideDateFilter && dateFilterColumn && onDateChange &&
                        <DateRangeFilter dateFilter={dateFilter} onChange={(selection) => {
                            onDateChange(selection)
                        }}><span>{header}</span></DateRangeFilter>}
                </StyledMenuItem>
                <StyledMenuItem>
                    {!hideCustomBooleanFilter && <CustomFilter options={options}/>}
                </StyledMenuItem>
                <StyledMenuItem>
                    {dataQueryExtracts?.data?.length > 0 && <MultiSelectFilter title="Extraction"
                                                                               initialFilterValue={undefined}
                                                                               disabled={extractQuery.isLoading}
                                                                               options={dataQueryExtracts!.data!.map((d: any) => ({
                                                                                   id: d.id,
                                                                                   name: d.label
                                                                               }))}
                                                                               filterSelector="id"
                                                                               onChange={(value: any) => setExtractId(value ? value : undefined)}/>}
                </StyledMenuItem>
                {debugMode && <StyledMenuItem>
                    <ModalEditComponent label={"Debug"} actionId={'debug'}
                                        key={`debug_${dataQuery}_table`}
                                        icon={<VscJson/>}
                                        title={`Debug`}>
                        <div className="grid grid-cols-3 gap-2">
                                    <pre style={{overflow: "auto"}}>{<ReactJson collapsed={false}
                                                                                src={filterOptions as any}/>}</pre>
                            <pre style={{overflow: "auto"}}>{<ReactJson collapsed={true}
                                                                        src={options as any}/>}</pre>
                            <pre style={{overflow: "auto"}}>{<ReactJson collapsed={true}
                                                                        src={src}/>}</pre>
                        </div>
                    </ModalEditComponent>
                </StyledMenuItem>}
                <StyledMenuItem>
                    {options.canExpandCollapse && instance.getIsSomeRowsExpanded() &&
                        <Button className="p-2 border shadow rounded cursor-pointer"
                                onClick={() => instance.toggleAllRowsExpanded(false)}><BsArrowsCollapse
                            size={20}/></Button>}
                    {options.canExpandCollapse && !instance.getIsSomeRowsExpanded() &&
                        <Button className="p-2 border shadow rounded cursor-pointer"
                                onClick={() => instance.toggleAllRowsExpanded(true)}><BsArrowsExpand
                            size={20}/></Button>}
                </StyledMenuItem>

            </Menu>
        </React.Fragment>
    );
}