import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {AiOutlineCheck} from 'react-icons/ai';
import {ImCheckboxUnchecked} from 'react-icons/im';
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import React, {useEffect, useState} from "react";
import {FaTimesCircle} from "react-icons/fa";
import {MultiSelectFilterProps} from "./MultiSelectFilter";

export default function ButtonToggleFilter({initialFilterValue, onChange, options}: {
    options?: MultiSelectFilterProps[],
    initialFilterValue: any, onChange: (value: any) => void
}) {

    const [value, setValue] = useState(initialFilterValue);

    useEffect(() => {
        if (initialFilterValue === undefined) setValue(null);
    },[initialFilterValue]);

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string,
    ) => {
        setValue(newValue);
        onChange(Boolean(newValue));
    };

    return (
        <ToggleButtonGroup
            className="text-white"
            value={value}
            exclusive
            onChange={handleChange}
        >
            color="primary"
            {options?.map((option) => (<ToggleButton className="text-white" key={option.id} value={option.id}>{option.name}</ToggleButton>))}
        </ToggleButtonGroup>
    );
}


