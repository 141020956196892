import {QueryCache, QueryClient, QueryClientProvider} from "react-query";
import {AuthProvider, ConfigProvider, EnvironmentProvider} from "./context/auth-context";
import {HashRouter} from "react-router-dom";
import {ReactQueryDevtools} from "react-query/devtools";
import {toast} from "react-toastify";
import React, {Suspense} from "react";

import {HTML5Backend} from "react-dnd-html5-backend";

import {DndProvider} from "react-dnd";
import DebugWatcher from "./services/DebugWatcher";
import {useDebugMode} from "./utils/UseDebugMode";
import {ProSidebarProvider} from "react-pro-sidebar";

const queryClient = new QueryClient({
    queryCache: new QueryCache({}),
    defaultOptions: {
        queries: {
            useErrorBoundary: true,
            refetchOnWindowFocus: false,
            retry: false,
            onError: (error: any) => {
                let message = error?.response?.data?.detail;
                if ((message == null || message == "") && error?.response?.data) return;
                if (message == null || message == "") message = error?.message;
                toast.error(message);
            },
        },
        mutations: {
            onError: (error: any) => {
                let message = error?.response?.data?.detail;
                if ((message == null || message == "") && error?.response?.data) return;
                if (message == null || message == "") message = error?.message;
                toast.error(message);
            },
            onSuccess: (data: any) => {
                if (data?.data?.isSuccess === false) toast.error(data?.data?.libelle);
                else toast.success("Mise à jour effectuée");
            },
        },
    },
});

// @ts-ignore
function AppProviders({children}) {
    const debugMode = useDebugMode();
    return (
        <DndProvider backend={HTML5Backend}>
            <QueryClientProvider client={queryClient}>
                <EnvironmentProvider>
                    <ConfigProvider>
                        <AuthProvider>
                            <ProSidebarProvider>
                                <HashRouter>
                                    <>{children}
                                        <DebugWatcher/>
                                    </>
                                </HashRouter>
                                {debugMode && <ReactQueryDevtools initialIsOpen={false}/>}
                                <Suspense fallback={null}/>
                            </ProSidebarProvider>
                        </AuthProvider>
                    </ConfigProvider>
                </EnvironmentProvider>
            </QueryClientProvider>
        </DndProvider>
    );
}

export {AppProviders};
