import { Spinner } from "flowbite-react";
import React from "react";
import {useConfig} from "../../../context/auth-context";
import {createDataTagAttrs} from "../../../services/DataTagAttrs";

export type ButtonColor =
  | "blue-dark"
  | "blue-electric"
  | "blue-light"
  | "green-0"
  | "yellow-0"
  | "gray-0"
  | "white-0";

export interface ButtonProps {
  children: any;
  color? : ButtonColor;
  size?: "xs" | "sm" | "base" | "lg" | "xl" | "2xl";
  disabled?: boolean;
  loading?: boolean;
  title?:string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  uppercase?: boolean;
  rounded? : boolean;
  type?: "submit" | "button";
  dataTag?: string;
}

export const Button = (props: ButtonProps) => {

  const {config} = useConfig();
  const className = config.isBoLegacy ? "blue-dark-legacy" :  "blue-electric";

  const {
    children,
    color = className,
    size = "base",
    rounded = !config.isBoLegacy,
    title = '',
    disabled = false,
    loading = false,
    uppercase = false,
    onClick,
    type = "button",
  } = props;
  
  return (
    <button
      className={`inline-block px-6 py-2.5 font-medium text-${size} shadow-${size} leading-tight ${uppercase ? 'uppercase' :'' } ${rounded ? ' rounded-full' : ''} bg-${color} ${color === 'white-0' ? 'text-black' : 'text-white'} hover:opacity-80  hover:shadow-${size} focus:shadow-${size} focus:outline-none focus:ring-0  active:shadow-${size} transition duration-150 ease-in-out disabled:bg-gray-light disabled:cursor-not-allowed`}
      onClick={disabled ? () => null : onClick}
      disabled={props.disabled}
      title={title}
      onMouseEnter={() => props.onMouseEnter && props.onMouseEnter()}
      onMouseLeave={() => props.onMouseLeave && props.onMouseLeave()}
      type={type}
    >
      {loading && <Spinner color={color} />}
      {!loading && children}
    </button>
  );
  
};
