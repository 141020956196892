import create, {StateCreator} from "zustand";
import {IUserContext} from "../context/auth-context";
import {persist, PersistOptions} from "zustand/middleware";

type UserStore = {
    user: IUserContext | undefined;
    addUser: (User: IUserContext | undefined) => void;
    resetUser: () => void;
};

type UserStorePersist = (
    config: StateCreator<UserStore>,
    options: PersistOptions<UserStore>
) => StateCreator<UserStore>;

export const useUserStore = create<UserStore>(
    (persist as UserStorePersist)(
        (set) => ({
            user: undefined,
            addUser: (user) => set(() => ({user: user})),
            resetUser: () => set(() => ({user: undefined})),
        }),
        {
            name: "user",
        }
    )
);    