import { useMutation, useQuery, useQueryClient } from "react-query";
import { Rights, useRightCheck } from "../utils/UseRightCheck";
import { post, postAnonymous, postBlob, put } from "../common/services/api/service";
import ModalEditComponent from "./modals/ModalEditComponent";
import { Box } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { AiOutlineEdit } from "react-icons/ai";
import { ImPlus } from "react-icons/im";
import { UpdateStateButton } from "./UpdateStateButton";
import { FaTrash } from "react-icons/fa";
import * as Yup from "yup";
import { DebugComponent } from "../utils/DebugComponent";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { Breakpoint } from "@mui/system";
import { FullPageSpinner } from "../dashboards/_common/lib";
import { FieldProps } from "formik/dist/Field";
import { useDebugMode } from "../utils/UseDebugMode";
import { TextFieldMuiWrapper } from "./wrappers/TextFieldMuiWrapper";

type FieldDefinition<T> = {
    key: keyof T,
    component?: string | React.ComponentType<FieldProps<T>> | React.ComponentType | React.ForwardRefExoticComponent<T>,
    header?: string,
    type?: 'checkbox' | 'text' | 'number',
    validator?: any,
    field?: JSX.Element,
    fieldFactory?: (entity: T) => JSX.Element | null,
    hidden?: boolean,
    hideError?: boolean,
    hiddenFunction?: (entity: T) => boolean,
    onChange?: (entity: any) => void,
    multiline?: boolean,
    onDebug?: any,
    dataTag?: string,
    [x: string]: any;
};

export function SimpleFormModal<T>({
    entity,
    canDisplay,
    showDelete = () => true,
    right,
    url,
    urlDelete,
    queryKey,
    keyToRefresh,
    cols,
    title,
    icon,
    children,
    fullHeight,
    verb = 'put',
    payload,
    onSuccess = () => {
    },
    open = true,
    displaymode = 'circle',
    isFixed = false,
    label,
    closeOnConfirm = false,
    maxWidth = 'md',
    blobNameFactory,
    initialOpen = false,
    transformEntity = (entity: T) => entity,
    autoConfirm = false,
    urlFactory = (entity: T) => url,
    header,
    headerClassName,
    isAnonymous = false,
    ...other
}: {
    isAnonymous?: boolean,
    header?: JSX.Element,
    headerClassName?: string,
    autoConfirm?: boolean,
    transformEntity?: (entity: T) => T,
    initialOpen?: boolean,
    blobNameFactory?: (entity: T) => string,
    urlFactory?: (entity: T) => string,
    maxWidth?: Breakpoint,
    closeOnConfirm?: boolean,
    label?: JSX.Element,
    isFixed?: boolean,
    displaymode?: 'circle' | 'button' | 'component',
    payload?: any,
    onSuccess?: (entity: any) => any;
    open?: boolean;
    verb?: 'post' | 'put' | 'delete' | 'postBlob' | 'getBlob'
    fullHeight?: boolean;
    showDelete?: (entity: T) => boolean;
    children?: JSX.Element | null,
    urlDelete?: string,
    icon?: (entity: T) => JSX.Element,
    title?: (entity: T) => string,
    cols?: Array<FieldDefinition<T>>,
    entity?: T,
    canDisplay?: (entity: T) => boolean,
    right?: Rights | null,
    url: string,
    queryKey?: string,
    keyToRefresh?: any[],
    [x: string]: any;
}) {

    const queryClient = useQueryClient();
    let newVar = {};

    const debugMode = useDebugMode();

    if (transformEntity && entity) {
        entity = { ...transformEntity(entity) };
    }

    cols?.forEach(c => (newVar as any)[c.key] = c.validator ? c.validator : null);
    let schema = Yup.object().shape(newVar);

    const [valuesFromForm, setValuesFromForm] = React.useState<T>();

    function getPayload() {
        const subset = cols && _.pick(valuesFromForm, cols.map(c => c.key));
        return { ...payload, ...subset };
    }

    const query = useQuery(
        [url, getPayload()],
        () => postBlob(url, getPayload()),
        {
            enabled: false,
            retry: false,
            onError: (error) => {
                toast.error(`Erreur lors de la sauvegarde`);
            }
        });

    useEffect(() => {
        if (valuesFromForm) {
            query!.refetch({}).then((data: any) => {
                const blob = new Blob([data.data], { type: "text/csv" });
                if (blob.size > 0) {
                    const s = `${blobNameFactory ? blobNameFactory(valuesFromForm) : url}.csv`;
                    saveAs(blob, s);
                    toast.success(`Fichier téléchargé avec succès`);
                } else {
                    toast.error(`Aucune donnée à télécharger`);
                }
                setValuesFromForm(undefined);
            });
            ;
        }
    }, [valuesFromForm])
    
    const mutation = useMutation((value: any) => {
        const subset = cols && _.pick(value, cols.map(c => c.key));
        if (payload) {
            const promisePost = () => isAnonymous ? postAnonymous(url, { ...payload, ...subset }) : post(url, { ...payload, ...subset });
            return verb == 'post' ? promisePost() : put(urlFactory(entity!), { ...payload, ...subset });
        }

        const promisePost = () => isAnonymous ? postAnonymous(urlFactory(entity!), subset) : post(urlFactory(entity!), subset);
        return (verb == 'put') ? put(urlFactory(entity!), subset) : promisePost();
    }, {
        onSettled: () => {
            queryClient.invalidateQueries([queryKey]);
            keyToRefresh?.forEach(key => {
                queryClient.invalidateQueries(key);
                queryClient.refetchQueries(key);

            });
            setValuesFromForm(undefined);

        },
        onError: (error) => {
            toast.error(`Erreur lors de la sauvegarde`);
        },
        onSuccess: (data) => {
            onSuccess ? onSuccess(data) : toast.success(`Sauvegarde effectuée avec succès`);
            window.location.reload();
        }
    });
    const isLoading = mutation.isLoading || query.isLoading;

    let icon1 = icon && entity ? icon(entity) : entity ? <AiOutlineEdit /> : <ImPlus />;

    const [icon2, setIcon2] = useState(icon1);

    const now = new Date();
    const nowStr = now.toISOString();

    useEffect(() => {
        const fetching = query.isFetching;
        setIcon2(fetching ? <FullPageSpinner /> : icon1)
    }, [query.isFetching]);

    const reduce = cols?.reduce((acc, c) => ({
        ...acc,
        [c.key]: c.onDebug ? c.onDebug : `${c.key.toString()}${nowStr}`
    }), {}) as T;
    const newVar1 = debugMode ? reduce : {};
    return ((!right || useRightCheck(right)) && (!canDisplay || entity && canDisplay(entity)) && open ?
        <div className="flex flex-row gap-2 justify-center items-center">
            <Formik
                enableReinitialize={true}
                initialValues={entity || newVar1}
                validationSchema={schema}
                onSubmit={async (values) => {
                }}
            >
                {({ values, errors,resetForm , isValid, touched, handleChange, dirty }) => {

                    const title1 = title && entity ? title(entity) : label ?? 'Ajouter'
                    return (
                        <ModalEditComponent
                            autoConfirm={autoConfirm}
                            initialOpen={initialOpen}
                            displaymode={displaymode}
                            isLoading={isLoading}
                            closeOnConfirm={closeOnConfirm}
                            fullHeight={fullHeight}
                            fullWidth={true}
                            errors={errors}
                            dirty={dirty}
                            defaultActions={true}
                            isFixed={isFixed}
                            maxWidth={maxWidth}
                            actionId={"send-email-conseiller"}
                            icon={icon2}
                            {...other}
                            onConfirm={() => {
                                if (verb == 'getBlob' || verb == 'postBlob') {
                                    setValuesFromForm(values as T);
                                } else {
                                    mutation.mutate(values);
                                }
                            }}
                            label={title1}
                            title={title1}>
                            <Form>
                                <Box>
                                    <div className={headerClassName ?? "text-2xl font-bold pb-4"}>{header}</div>
                                </Box>

                                <div className={`${other.classNameForFields ?? 'grid grid-rows-1'}`}>
                                    {cols?.filter(c => !c.hidden && (!c.hiddenFunction || !c.hiddenFunction(values as T))).map(({
                                        header,
                                        hideError,
                                        key,
                                        component,
                                        type,
                                        field,
                                        multiline,
                                        fieldFactory,
                                        ...rest
                                    }) => {

                                        if (field) {
                                            field = React.cloneElement(field, { ['data-testid']: key.toString() });
                                        }

                                        const trigger = React.cloneElement(
                                            fieldFactory ? fieldFactory(values as T)! :
                                                field ?? <Field type={type ?? 'text'}
                                                    label={header ?? key}
                                                    className={"w-full"}
                                                    name={key}
                                                    dataTag={key.toString()}
                                                    component={component ?? TextFieldMuiWrapper}
                                                    multiline={multiline}
                                                    {...rest}

                                                />,);
                                        return <Box margin={1}>
                                            <>
                                                {trigger}
                                            </>
                                        </Box>;
                                    })}

                                </div>
                                <Box margin={1}>
                                    <div>{children}</div>
                                </Box>
                            </Form>
                            <DebugComponent simple={true} key={'values'} data={values}
                                collapsed={true}></DebugComponent>
                            <DebugComponent simple={true} key={'errors'} data={errors}
                                collapsed={false}></DebugComponent>

                        </ModalEditComponent>
                    );
                }}
            </Formik>
            {entity && right && showDelete(entity) &&
                <UpdateStateButton icon={<FaTrash />} verb={'delete'} entity={entity} canDisplay={() => true}
                    actionLabel={'Supprimer'} right={right!} url={urlDelete ?? url}
                    queryKey={queryKey!} />}
        </div> : null);
}
