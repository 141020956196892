import {TableOptions} from "./FilteredTable";
import {Header, Table} from "@tanstack/react-table";
import {Button, TableCell, TableFooter, TableHead, TableRow} from "@mui/material";
import {FaFilter} from "react-icons/fa";
import ConfirmDialogMarketPlace from "../modals/ConfirmDialogMarketPlace";
import React from "react";
import {useViewConfigForRoute} from "../../dashboards/_common/UseViewConfigForRoute";

export function Summary<T>({
                               options
                           }: { options: TableOptions<T> }) {
    const {instance, dataQuerySummary} = options;

    const viewConfiguration = useViewConfigForRoute();
    const hideSummary = viewConfiguration?.hideSummary ?? false;



    return (<>
            {!hideSummary && instance.getRowModel()?.rows.length > 0 && dataQuerySummary && dataQuerySummary.data &&
                <TableHead key={`footer-group-${dataQuerySummary.id}`}>
                    {instance.getFooterGroups().map(function (headerGroup: { id: React.Key | null | undefined; headers: any[]; }) {
                        const summaryColumns = Object.keys(dataQuerySummary.data);
                        return (<>
                            <TableRow key={`footer-group-${headerGroup.id}`}>
                                {headerGroup.headers.map((header) => {
                                        const value = summaryColumns?.includes(header.id) ? dataQuerySummary.data[header.id] : null;
                                        return <TableCell key={`footer-${header.id}`}
                                                          style={{width: header.getSize()}}>
                                            {value != null && <div
                                                className="flex flex-col flex-row items-stretch justify-between text-red-800 text-base font-bold text-center">
                                                {header?.column?.columnDef?.footer(value)}
                                            </div>}
                                        </TableCell>
                                    }
                                )}
                            </TableRow>
                        </>);
                    })}
                </TableHead>}
        </>

    );
}
