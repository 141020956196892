import {createBrowserHistory, createPath} from "history";

export function resetSearchQuery() {
    let history = createBrowserHistory();
    let location = history.location;
    let pathPieces = {
        pathname: location.pathname,
        search: '',
        hash: location.hash
    }
    let path = createPath(pathPieces);

    history.replace(path);
}
