import create from "zustand";
import {IConseillerFilter} from "../components/table/filters/ConseillersGlobalFilter";
import {useAuth} from "../context/auth-context";
import {FeatureTableProps} from "../utils/UseFullTable";


export type UseDataReturn<T> = {
    setViewConfig: (data: FeatureTableProps<T>[]) => void;
    viewConfig: FeatureTableProps<T>[];
};


export const useViewConfigStore = create<UseDataReturn<unknown>>((set) => ({
    viewConfig: [{"label": "Produits", "items": []}],
    setViewConfig: (arg: FeatureTableProps<unknown>[]) =>
        set(() => ({
            viewConfig: arg
        }))
}));
