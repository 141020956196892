import React, {useState} from "react";
import {DEV_SETTINGS_KEY} from "../components/table/FilteredTable";
import ExpandCard from "./ExpandCard";
import ReactJson from "react-json-view";

export function DebugComponent({data, title, collapsed = true, simple = true}: {collapsed?: boolean, data: any, title?: string, simple?: boolean }) {
    const [debugMode, setDebugMode] = React.useState(false);


    useState(() => {
        const saved = localStorage.getItem(DEV_SETTINGS_KEY);
        const initialValue = saved && JSON.parse(saved);
        if (initialValue) {
            setDebugMode(initialValue.debug)
        }

        return initialValue || "";
    });
    const expandCard = <ExpandCard initialOpen={collapsed} title={title}>
        <pre style={{overflow: "auto"}}>{ <ReactJson collapsed={true} src={data}/>}</pre>
    </ExpandCard>;
    return (debugMode
            ? simple ?  <pre style={{overflow: "auto"}}>{ <ReactJson collapsed={collapsed} src={data}/>}</pre> : expandCard
            : null
    );
}


