import {TableOptions} from "./FilteredTable";
import React, {useEffect, useState} from "react";
import MultiSelectFilterRemoteData from "./filters/MultiSelectFilterRemoteData";
import {FormControlLabel, Switch} from "@mui/material";
import {HtmlTooltip} from "../forms/Tooltip";
import Button from "@mui/material/Button";
import {RiFilterOffFill} from "react-icons/ri";
import {SearchParamFilter} from "../../utils/UseFullTable";
import {useViewConfigForRoute} from "../../dashboards/_common/UseViewConfigForRoute";
import {useDebugMode} from "../../utils/UseDebugMode";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {AiOutlineMore} from "react-icons/ai";
import MenuItem from "@mui/material/MenuItem";
import {FilterChip} from "./FilterChip";
import {SortChip} from "./SortChip";
import {BiReset} from "react-icons/bi";
import DateRangeFilter from "./filters/DateRangeFilter";
import {CustomFilter} from "../CustomFilter";
import ModalEditComponent from "../modals/ModalEditComponent";
import {VscJson} from "react-icons/vsc";
import ReactJson from "react-json-view";
import {BsArrowsCollapse, BsArrowsExpand} from "react-icons/bs";
import {TablePaginationWrapper} from "./TablePaginationWrapper";
import Menu from "@mui/material/Menu";
import {IoMdOptions} from "react-icons/io";
import {withStyles} from "@material-ui/core";

export function SearchBar<T>({
                                 options,
                                 onFullChange
                             }: { options: TableOptions<T>, onFullChange?: (value: any) => void }) {

    const {instance, searchBarParams} = options;
    const [searchBarParamsState, setSearchBarParamsState] = useState<SearchParamFilter<T>[] | undefined>(searchBarParams?.params);
    const column = instance?.getAllLeafColumns().find((f: any) => f.id === searchBarParams?.selectOptions.searchBy)!;
    const [additionalQueryParams, setAdditionalQueryParams] = useState(searchBarParams?.option.additionalQueryParameters ?? []);
    const [initialFilterValue, setInitialFilterValue] = useState<any[]>([]);

    const getOnChange = (value) => {
        if (!column) return;
        if (value === '' || value === undefined || value === null) {
            column.setFilterValue(undefined);
        } else {
            column.setFilterValue(options.showAllRows ? value : `==${value ?? ''}`);
        }
    };

    useEffect(() => {
        if (searchBarParamsState) {
            const additionalQueryParams = searchBarParamsState.map((r) => ({
                left: r.filterOptionForBackend,
                right: r.value,
                operator: '='
            }));

            setAdditionalQueryParams(searchBarParams?.option.additionalQueryParameters
                ? [...searchBarParams?.option.additionalQueryParameters, ...additionalQueryParams]
                : additionalQueryParams);
        }
    }, [searchBarParamsState]);

    return <div className="flex items-center justify-center">
        {searchBarParams && searchBarParams?.option && <div className="flex gap-2 grow">

            {!onFullChange && <HtmlTooltip title="Supprimer les filtres">
                <Button className="p-2 border shadow rounded cursor-pointer"
                        disabled={initialFilterValue.length === 0}
                        onClick={() => {
                            setInitialFilterValue([]);
                            instance?.getAllLeafColumns().forEach(f => {
                                if (!options.defaultFilters || !options.defaultFilters.map(d => d.id).includes(f.id)) {
                                    f.setFilterValue(undefined)
                                }
                            });
                        }}><RiFilterOffFill size={20}/></Button>
            </HtmlTooltip>}

            <div className={""}><MultiSelectFilterRemoteData
                disableCloseOnSelect={false}
                endAdornment={<MenuOptions searchBarParamsState={searchBarParamsState}
                                           setSearchBarParam={setSearchBarParamsState}/>}
                disabled={initialFilterValue.length > 0}
                url={searchBarParams?.option.url!}
                filterSelector="id"
                additionalQueryParameters={additionalQueryParams}
                initialFilterValue={initialFilterValue}
                title="Rechercher"
                placeHolder={`Rechercher par ${searchBarParamsState?.filter(r => r.value).map((r) => r.label).join(', ')}`}
                hideOptionId={searchBarParams?.selectOptions.hideOptionId == true}
                showSelectAll={false}
                onChange={getOnChange}
                onFullChange={(values) => onFullChange ? onFullChange(values) : setInitialFilterValue(values)}/></div>


        </div>}
    </div>;
}

const StyledMenuItem = withStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        //no animation on menu selection
        '&.Mui-selected': {
            backgroundColor: 'transparent',

        }

    },
})(MenuItem);

export default function MenuOptions<T>({searchBarParamsState, setSearchBarParam}: {
    searchBarParamsState: SearchParamFilter<T>[] | undefined,
    setSearchBarParam: React.Dispatch<React.SetStateAction<SearchParamFilter<T>[] | undefined>>
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                <Tooltip title="Afficher les options de recherche" key={"tooltipSearchBar"}>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ml: 2}}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <IoMdOptions className={"text-2xl"}/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                transitionDuration={0}
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <StyledMenuItem key={"menuItem"}>

                    <div
                        className="flex flex-wrap flex-col">
                        <span>Selectionner les critères </span>

                        {searchBarParamsState && searchBarParamsState.map((filter,index) =>
                            <div className="flex gap-2 flex-col">
                                <FormControlLabel label={filter.label} key={index}
                                                  control={<Switch checked={filter.value} key={index}
                                                                   disabled={filter.disabled}
                                                                   onChange={(e) => {
                                                                       setSearchBarParam(searchBarParamsState
                                                                           .map((currentFilter) =>
                                                                               currentFilter.filterOptionForBackend === filter.filterOptionForBackend
                                                                                   ? {
                                                                                       ...currentFilter,
                                                                                       value: e.target.checked
                                                                                   }
                                                                                   : filter.forcedFilterOptionForBackendWhenTrue
                                                                                       ? {
                                                                                           ...currentFilter,
                                                                                           value: filter.forcedFilterOptionForBackendWhenTrue.includes(currentFilter.filterOptionForBackend),
                                                                                           disabled: e.target.checked
                                                                                       }
                                                                                       : currentFilter
                                                                           ))
                                                                   }}/>}/>
                            </div>)}
                    </div>
                </StyledMenuItem>

            </Menu>
        </React.Fragment>
    );
}