import create from "zustand";
import {ISite} from "../common/services/interfaces/Site";

type SiteStore = {
    site: ISite | null | Partial<ISite>;
    addSite: (site: ISite | null | Partial<ISite>) => void;
    resetSite: () => void;
};

export const useSiteStore = create<SiteStore>((set) => ({
    site: null,
    addSite: (site) => set(() => ({site: site})),
    resetSite: () => set(() => ({site: null})),
}));
