import {TableOptions} from "./FilteredTable";
import {Table} from "@tanstack/react-table";
import Filter from "./filters/Filter";
import React, {useState} from "react";
import {FaFilter} from "react-icons/fa";
import {Button} from "@mui/material";
import ConfirmDialogMarketPlace from "../modals/ConfirmDialogMarketPlace";
import {useTranslation} from "react-i18next";


export function FilterSearches<T>({
                                      options
                                  }: { options: TableOptions<T>, table: Table<T> }) {

    const {instance, filtersInModal} = options;
    const [modalOpen, setModalOpen] = useState(false);
    const { t } = useTranslation();


    const facets = options.facets?.map((f) => {
            const select = instance.getAllLeafColumns().find((r) => r.id === f.key);
            return <div className={f.component === "MultiSelectFilterRemoteData" ? `w-64 ${filtersInModal && 'my-1'}` : (f.component === "MultiSelectFilter" && filtersInModal ? 'pl-1 my-1' : '' )}>
                <Filter
                    column={select!}
                    hideOperatorMenu={true}
                    facets={options.facets}
                    autoFocus={true}
                    options={options}
                    onChange={() => {
                        instance.setPageIndex(0)
                    }}
                    onOperatorChange={() => {
                        instance.setPageIndex(0)
                    }}
                /></div>;
        });

    return (
        <div className="flex flex-wrap gap-2 md:gap-6 items-center">
            {filtersInModal ?
                <>
                    <Button startIcon={<FaFilter size={12}/>} onClick={()=> setModalOpen(!modalOpen)} className="bg-gray-200 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                        {t("common::Filtrer")}
                    </Button>
                    <ConfirmDialogMarketPlace
                        setOpen={setModalOpen}
                        open={modalOpen}
                        withActions={false}
                        onConfirm={()=> setModalOpen(false)}
                    >
                        <p className="font-bold ml-2 my-2 text-lg">{t("common::Filtres")} :</p>
                        {facets}
                    </ConfirmDialogMarketPlace>
                </> :
                <>
                    {facets}
                </>
            }
        </div>
    );
}


