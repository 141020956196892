import {Column, ColumnOrderState, flexRender, Header, Table} from "@tanstack/react-table";
import {useDrag, useDrop} from "react-dnd";
import React, {useEffect} from "react";
import {Badge, TableCell} from "@mui/material";
import {HtmlTooltip} from "../forms/Tooltip";
import Button from "@mui/material/Button";
import {AiOutlineGroup, AiOutlineUngroup} from "react-icons/ai";
import {TableOptions} from "./FilteredTable";
import Filter from "./filters/Filter";
import {BiDownArrowAlt, BiUpArrowAlt} from "react-icons/bi";
import {TableHeadCellSortLabel} from "./TableHeadCellSortLabel";

const reorderColumn = (
    draggedColumnId: string,
    targetColumnId: string,
    columnOrder: string[]
): ColumnOrderState => {
    columnOrder.splice(
        columnOrder.indexOf(targetColumnId),
        0,
        columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
    )
    return [...columnOrder]
}

export function DraggableColumnHeader<T>({
                                             header,
                                             table,
                                             options
                                         }: { options: TableOptions<T>, header: Header<T, unknown>, table: Table<T> }) {
    const {getState, setColumnOrder} = table
    const {columnOrder} = getState()
    const {column} = header
    const {instance, title, facets, dataQuerySummary, filterOptions} = options;

    const hiddenColumns = options.hiddenCols;
    const fixedCols = options.fixedCols;

    const hasFilterWithValue = filterOptions?.columnFilters?.find(f => {
        const component = facets?.find(facet => facet.key === f.id)?.component;
        //check if columns is not hidden
        const b = !fixedCols?.includes(f.id as keyof T) ?? false
        const isHidden = hiddenColumns?.includes(f.id as keyof T) ?? false;
        return !isHidden && f.value && (component === 'MultiSelectFilterRemoteData' || component == 'MultiSelectFilter')
    });


    const [, dropRef] = useDrop({
        accept: 'column',
        drop: (draggedColumn: Column<T>) => {
            if (column.id !== 'select' && column.id !== 'expander') {
                const newColumnOrder = reorderColumn(
                    draggedColumn.id,
                    column.id,
                    columnOrder
                )
                setColumnOrder(newColumnOrder)
            }
        },
    })

    const [{isDragging}, dragRef, previewRef] = useDrag({
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => column,
        type: 'column',
    })



    // @ts-ignore
    const header1 = (header.column.id != 'select' && header.column.id != 'expander') ? header?.column?.columnDef?.header() : '';
    const groupButton = <>
        <Badge
            overlap="circular"
            {...{
                onClick: header.column.getToggleGroupingHandler(),
                style: {
                    cursor: 'pointer',
                },
            }}
        >
            {header.column.getIsGrouped()
                ? <HtmlTooltip title={'Dissocier'}>
                    <Button><AiOutlineUngroup className="text-xl"/></Button>
                </HtmlTooltip>
                : <HtmlTooltip title={'Grouper'}>
                    <Button><AiOutlineGroup className="text-xl"/></Button>
                </HtmlTooltip>}
        </Badge></>;
    const className = header.column.getCanSort()
        ? 'cursor-pointer select-none line-clamp-2'
        : 'line-clamp-2';
    const filter = <>{!options.showFiltersOnTop && !options.hideFilters && header.column.getCanFilter() ? (
        <div className="flex flex-row items-center justify-center w-full">
            <Filter
                column={header.column}
                facets={facets}
                options={options}
                onChange={(value) => {
                    instance.setPageIndex(0)
                }}
                onOperatorChange={(value) => {
                    instance.setPageIndex(0)
                }}
            />
        </div>
    ) : <></>}</>;

    //check if column is sortable
    const sortButton = header.column.getCanSort();

    const classToApply = header.column.getCanSort() ? 'flex flex-row hover:underline whitespace-nowrap overflow-hidden overflow-ellipsis cursor-pointer' : 'flex flex-row whitespace-nowrap overflow-hidden overflow-ellipsis';
    const colHeader = <>{<div
        className="flex flex-row items-center justify-center static p-1"
        ref={previewRef}>
        {<HtmlTooltip title={header1 || ''}>
            <div
                className={classToApply}
                {...{onClick: header.column.getToggleSortingHandler()}}
            >
                {header.column.getCanGroup() ? groupButton : null}

                <div>
                    {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                    )}
                </div>

                {sortButton && <TableHeadCellSortLabel header={header} options={options} table={instance}/>}

                <div
                    {...{
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                    }}
                />
            </div>
        </HtmlTooltip>}
    </div>}</>;
    const summaryColumns = (dataQuerySummary && dataQuerySummary.data && Object.keys(dataQuerySummary.data)) ?? [];
    const value = (summaryColumns?.includes(header.id)) ? dataQuerySummary?.data[header.id] : null;

    if (header.column.id === 'select' || header.column.id === 'expander') {

    }
    // @ts-ignore
    const footer = value != null && header.column?.columnDef?.footer && header.column?.columnDef?.footer(value);
    return (
        <TableCell ref={dropRef}
                   colSpan={header.colSpan}
                   style={{
                       width: header.getSize(),
                       opacity: isDragging ? 0.5 : 1,
                       backgroundColor: "aliceblue"
                   }} key={header.id}>
            {header.isPlaceholder ? null : (
                <div
                    className={`flex flex-col  ${header.column.id != 'select' ? 'items-center justify-between' : 'items-start justify-center'} ${(options.showFiltersOnTop || options.hideFilters) ? '' : hasFilterWithValue ? '' : ''} font-bold capitalize`}>
                    <div className={"flex gap-2 items-center justify-center"}>
                        <span>{colHeader}</span>
                        <span>{value != null && <div
                            className="flex flex-row items-center justify-center text-red-800 text-sm font-bold">{footer}</div>}</span>
                    </div>
                    {filter}
                </div>
            )}
        </TableCell>
    )
}
