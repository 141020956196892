import React, {useEffect, useState} from "react";
import {SubMenu} from "react-pro-sidebar";
import StyledMenuItem, {MenuItemProps} from "./StyledMenuItem";
import {Rights, useRightsCheck} from "../utils/UseRightCheck";
import {useLocation} from "react-router-dom";
import {AgorastoreService, useServicesCheck} from "../utils/UseServiceCheck";
import {GiCancel, GiConfirmed} from "react-icons/gi";
import {danger} from "../styles/colors";
import {MdPendingActions} from "react-icons/md";
import {AiFillDollarCircle, AiOutlineCalendar} from "react-icons/ai";
import { FaBiohazard } from "react-icons/fa";
/*
import { PiSirenFill } from "react-icons/pi";
*/


export function IconeResolver(reference: string) {
    switch (reference) {
        case "confirmation":
            return <GiConfirmed className={"text-2xl"} color={"blue"}/>
        case "annulation":
            return <GiCancel className={"text-2xl"} color={danger}/>
        case "verification":
            return <MdPendingActions className={"text-2xl"} color={"orange"}/>
        case "planification":
            return <AiOutlineCalendar className={"text-2xl"}/>
        case "hazard":
            return <FaBiohazard className={"text-2xl"} color={danger}/>
        case "dollar":
            return <AiFillDollarCircle className={"text-2xl"} color={"green"}/>
     /*   case "siren":
            return <PiSirenFill className={"text-2xl"} color={"green"}/>*/

    }

    return null;
}

export default StyledSubMenu;


export function StyledSubMenu({icon, label, services,open, items, iconReference, emojis}: SubMenuProps) {

    const hasService = hasServices(services);
    const rights = hasRights(items);
    const isActive = hasActiveChild(items);
    const [isOpen, setIsOpen] = useState(isActive || hasService || (open ?? false));
    useEffect(() => setIsOpen(isActive || hasService || (open ?? false)), [hasService, isActive,open]);

    const emoji = emojis && emojis[0] || null;
    const emojiNode = emoji ? <span className="text-lg">{emoji}</span> : null;


    return rights ?
        <SubMenu icon={(iconReference && IconeResolver(iconReference)) ?? icon ?? emojiNode}
                 defaultOpen={isOpen}
                 open={isOpen}
                 aria-label={label}
                 key={label}
                 prefix={<p style={{fontWeight: isActive ? 'bold' : 'unset'}}>{label}</p>}
                 onOpenChange={(val) => setIsOpen(val)}>
            {items.map((item, index) => isSubMenuProps(item)
                ? <StyledSubMenu  key={index} {...item} aria-label={label}/>
                : <StyledMenuItem key={index} {...item} aria-label={label}/>)}
        </SubMenu> : null;
}


const hasActiveChild = (items: (MenuItemProps | SubMenuProps)[]) => {
    const pathname = useLocation().pathname;

    return items.some(item => isChildActive(pathname, item));
}

const isChildActive = (pathname: string, props: SubMenuProps | MenuItemProps) =>
    isSubMenuProps(props)
        ? props.items.some(item => isChildActive(pathname, item))
        : pathname.replace("/", "") == props.to

const hasServices = (services: AgorastoreService[] | undefined) => services ? useServicesCheck(services) : false;

const hasRights = (items: (MenuItemProps | SubMenuProps)[]) => useRightsCheck(getRights(items))

const getRights = (items: (MenuItemProps | SubMenuProps)[]) => {
    const rights: Rights[] = [];
    items.forEach(item => {
        if (isSubMenuProps(item)) {
            rights.push(...getRights(item.items));
        } else {
            rights.push(item.right);
        }
    });

    return Array.from(new Set(rights));
}

const isSubMenuProps = (props: SubMenuProps | MenuItemProps): props is SubMenuProps => (props as SubMenuProps).items !== undefined

export interface SubMenuProps {
    icon?: React.ReactNode,
    iconReference?: string,
    emojis?: string[],
    label: string,
    open?:boolean,
    services?: AgorastoreService[],
    items: (MenuItemProps | SubMenuProps)[]
}
