import {createRoot} from 'react-dom/client';
import {Root} from './Root';
import React, {Profiler} from 'react';

import './index.css';

import {AppProviders} from "./appProviders";
import {createTheme, CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import {frFR} from '@mui/material/locale';
import {datadogLogs} from "@datadog/browser-logs";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const theme = createTheme({
    components: {
        MuiModal: {
            defaultProps: {
                container: rootElement,
            },
        },
        MuiPopover: {
            defaultProps: {
                container: rootElement,
            },
        },
        MuiPopper: {
            defaultProps: {
                container: rootElement,
            },
        },
    },
    frFR
});

const reactappproxytarget = window.REACT_APP_PROXY_TARGET;
const reactappproxytarget1 = process.env.REACT_APP_PROXY_TARGET;
const env = reactappproxytarget || reactappproxytarget1;

datadogLogs.init({
    clientToken: 'pubec96cb29abc2a42220a32eb30a560468',
    site: 'datadoghq.com',
    sessionSampleRate: 100,
    env: env,
    service: 'agorastore-frontend'
});

datadogLogs.setGlobalContext({Properties: {Environment: env}});
datadogLogs.setGlobalContextProperty("host", window.location.host);

if (env === 'local') {
    datadogLogs.logger.setHandler('console');
}

root.render(
    <Profiler id="App Root" phases={['mount']}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <AppProviders>
                    <Root/>
                </AppProviders>
            </ThemeProvider>
        </StyledEngineProvider>
    </Profiler>
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// Changes made here
/*
serviceWorker.register({
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", event => {
                if (event.target.state === "activated") {
                    console.log("New content is available and will be used when all tabs for this page are closed. See https://bit.ly/CRA-PWA.")
                    window.location.reload()
                }
            })
            waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
        }
    },
})
*/
