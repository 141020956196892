import {MutableRefObject, useEffect, useRef, useState} from 'react';
import * as signalR from '@microsoft/signalr';
import {useMount} from "./Mount";
import AppSettings from "../common/services/AppSettings";
import {datadogLogs} from "@datadog/browser-logs";
import {useFeatureFlagCheck} from "../dashboards/_common/UseFeatureFlagCheck";


const disconnectGraceTimeMs = 2 * 60 * 1000; // 2 minutes

/*
const logger = datadogLogs.logger;
*/
const logScope = 'useLiveConnectionInternal';

type TimeoutHandle = ReturnType<typeof setTimeout> | null;

/**
 * Convenience helper for "cancel timeout if needed" operations.
 */
function cancelTimeout(handle: TimeoutHandle): TimeoutHandle {
    if (handle !== null) {
        clearTimeout(handle);
    }

    return null;
}

/**
 * This hook creates a connection to our SignalR Hub (WebSocket server) and maintains it (i.e. it will
 * automatically try to reconnect, forever, in the even the connection would be lost.
 *
 *
 * @return {{ connected: boolean, connectionRef: MutableRefObject<signalR.HubConnection | null> }}
 *  * connected will be true when the signalR connection is active
 *  * connectionRef contains a ref to the active connection (or null if there's no active connection). To be used
 *  solely to register event handlers.
 *
 * NOTE: Here lies the SignalR documentation: https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client
 * @param hubPath
 * @param onError
 * @param onConnexionSuccess
 * @param onEndTryReconnect
 */
export function useLiveConnectionInternal(hubPath: string | null,
                                          onError?: (message: any) => void,
                                          onConnexionSuccess?: () => void,
                                          onEndTryReconnect?: () => void
): { connected: boolean; connectionRef: MutableRefObject<signalR.HubConnection | null>; } {
    const connectionRef = useRef<signalR.HubConnection | null>(null);
    const isMounted = useMount();
    const [connected, setConnected] = useState(false);
    const flagCheck = useFeatureFlagCheck('SIMULATION_SIGNALR_DISCONNECTION')


    // Creates the connection if not done already (the connection handles reconnections to the websocket server in case it
    // is lost all by itself)
    useEffect(() => {

        if (!hubPath) {
            return;
        }
        if (connectionRef.current !== null) {
            return;
        }

        // Connection state can only be changed if component is mounted and a connection currently exists
        function canChangeConnectionState() {
            //console.log('canChangeConnectionState', isMounted, connectionRef.current);
            return isMounted() && connectionRef.current !== null;
        }

        let documentIsVisible = true;

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${AppSettings.getSignalRHubUrl()}${hubPath}`)
            .configureLogging(signalR.LogLevel.Warning)
            // Exponential backoff, with a 5s cap
            // retry intervals: [1s, 2s, 4s, 8s, 8s, 8s, ...]
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (retryContext) => {
                    if (!canChangeConnectionState() || !documentIsVisible) {
                        onEndTryReconnect && onEndTryReconnect();
                        // Stops the retry loop as peer the signalR documentation
                        return null;
                    }

                    //console.log('signalR retrying closure invoked', { ...retryContext, scope: logScope });
                    return computeNextRetryDelayInSeconds(retryContext.previousRetryCount) * 1000;
                },
            })
            .build();

        connection.onreconnected((newConnectionId) => {
            if (canChangeConnectionState()) {
                setConnected(true);
            }
        });

        connection.onreconnecting(() => {

            if (canChangeConnectionState()) {
                setConnected(false);
            }
        });

        connection.onclose((err) => {
            if (canChangeConnectionState()) {
                setConnected(false); // Note: likely a no-op since "onreconnecting" event already fired and connected state didn't change since then
            }
        });

        connectionRef.current = connection;

        // Establish tries to start the connection in the background every 1s
        let reconnectHandle: ReturnType<typeof setTimeout> | null = null;

        async function establishWebsocketConnection(connection: signalR.HubConnection, connectionRetryCount: number) {
            try {

                await connection.start();

                if (canChangeConnectionState()) {
                    setConnected(true);
                }
            } catch (err) {
                onError && onError(err);
                if (connectionRetryCount > (flagCheck ? 1 : 3)) {
                    onEndTryReconnect && onEndTryReconnect();
                }

                const retryDelayInMilliseconds = computeNextRetryDelayInSeconds(connectionRetryCount) * 1000;

                // Retry until we succeed or lose authentication
                if (canChangeConnectionState()) {
                    reconnectHandle = setTimeout(() => {
                        reconnectHandle = null;

                        if (canChangeConnectionState()) {
                            const connectionRetryCount1 = connectionRetryCount + 1;
                            establishWebsocketConnection(connection, connectionRetryCount1);
                        }
                    }, retryDelayInMilliseconds);
                }


            }
        }

        // Handle document visibility changes
        let disconnectHandle: ReturnType<typeof setTimeout> | null = null;

        async function visibilityChanged() {
            documentIsVisible = document.visibilityState !== 'hidden';

            if (connectionRef.current !== null) {
                const connection = connectionRef.current;

                // Cancel pending forced disconnection if any
                disconnectHandle = cancelTimeout(disconnectHandle);
                reconnectHandle = cancelTimeout(reconnectHandle);

                // When document gets visible we should re-establish connection if needed
                if (documentIsVisible) {
                    if (
                        connection.state === signalR.HubConnectionState.Disconnected ||
                        connection.state === signalR.HubConnectionState.Disconnecting
                    ) {
                        establishWebsocketConnection(connection, 0);
                    }
                }

                // When document gets invisible we should close connection after a grace period of time
                else {
                    disconnectHandle = setTimeout(() => {
                        disconnectHandle = null;
                        reconnectHandle = cancelTimeout(reconnectHandle);

                        connection.stop().catch(() => {
                        }); // Silently discard disconnection error
                    }, disconnectGraceTimeMs);
                }
            }
        }

        document.addEventListener('visibilitychange', visibilityChanged);

        // Start establishing initial connection if document is visible
        visibilityChanged();

        // Before the effect is re-triggered, we want to make sure we're cleaning up connections previously created by the
        // effect.
        return () => {
            connectionRef.current = null;
            setConnected(false);

            disconnectHandle = cancelTimeout(disconnectHandle);
            reconnectHandle = cancelTimeout(reconnectHandle);

            document.removeEventListener('visibilitychange', visibilityChanged);

            connection.stop().catch((err) => console.log('error closing signalR hub connection', {
                err,
                scope: logScope
            }));
        };
    }, [isMounted, hubPath]);

    return {connected, connectionRef};
}

// Given a count of already made connection attempts, this method computes the amount of seconds to wait before
// triggering the next try.
// We're simply following an exponential backoff with a base duration of two seconds here, and a cap of 8 seconds (or 3
// attempted retries): the first retry will occor at 2^0 = 1s after the connection loss, then at 2^1=2s then 4s, then 8s
// and then, every 8s.
function computeNextRetryDelayInSeconds(retryCount: number) {
    // exponential backoff limit
    if (retryCount > 3) return 8;
    // exponential backoff
    return Math.pow(2, retryCount) * 1;
}
