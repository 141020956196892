import {useQuery} from "react-query";
import {get} from "../../common/services/api/service";
import {ServicesDto, ServiceWithSettingsDto} from "../../common/services/interfaces/IAdministrateurRightsDto";

export function useServices(): { id: number, name: string, group: string }[] {
    const {data: services} = useQuery<ServicesDto[]>('administrateurs-services',
        () =>
            get(`administrateurs-services`).then((data) => data),
        {staleTime: Infinity});

    return services ? services.map((r: ServicesDto) => ({id: r.id, name: r.label, group: ''})) : [];
}

export function useServiceWithSettings(): ServiceWithSettingsDto[] {
    const {data: services} = useQuery<ServiceWithSettingsDto[]>('administrateurs-services',
        () => get(`administrateurs-services`).then((data) => data),
        {staleTime: Infinity});

    return services ? services : [];
}