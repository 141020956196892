import {useState} from 'react';
import {readStorage, writeStorage, clearStorage} from './PersistentStorage';

/** Active value */
const ActiveValue = 'active';

/** Key enabling debug panel */
export const DebugRootKeyName = 'on';

/**
 * List of all flags available in Pigment ordered by categories
 * AllFlags[Space] is an array containing all the feature flags supported by Pigment today
 *
 * Add `impactDb:true` if the feature may impact was will be stored within databases.
 * Add `unstable:true` if the feature is known to be highly unstable (possibly no impact on dbs but can negatively impact user experience)
 */
export const AllFlags = {
    debug: [
        [DebugRootKeyName, {}]
    ]
    // Enable sorting of feature flags
} as const;


/** Check if a flag is active or not */
export function isFlagActive<TSpace extends keyof typeof AllFlags>(
    spaceName: TSpace,
    flagName: typeof AllFlags[TSpace][number][0]
) {
    return readStorage(spaceName, flagName) === ActiveValue;
}

/**
 * Hook to alter our flags while keeping a refreshed* status for each flag
 *
 * *WARNING: Updating flags through this hook will not trigger re-render for other users of the hook.
 *           Calling toggleFlag, will trigger a state update of the hook causing a re-render of the component using it (thus updating flags).
 */
export function useFlags() {
    const [, setFlagsVersion] = useState(0);
    const [toggleFlag] = useState(
        () =>
            function <TSpace extends keyof typeof AllFlags>(spaceName: TSpace, flagName: typeof AllFlags[TSpace][number][0]) {
                toggleFlagInternal(spaceName, flagName);
                setFlagsVersion((v) => (v + 1) | 0); // update state to trigger re-render
            }
    ); // toggleFlag pointer will never be updated accross re-renders

    return {toggleFlag, isFlagActive};
}

// Various helpers to update the value of our flags

/** Enable flag */
function enableFlaginternal<TSpace extends keyof typeof AllFlags>(
    spaceName: TSpace,
    flagName: typeof AllFlags[TSpace][number][0]
) {
    return writeStorage(spaceName, flagName, ActiveValue);
}

/** Disable flag */
function disableFlagInternal<TSpace extends keyof typeof AllFlags>(
    spaceName: TSpace,
    flagName: typeof AllFlags[TSpace][number][0]
) {
    return clearStorage(spaceName, flagName);
}

/** Toggle flag */
function toggleFlagInternal<TSpace extends keyof typeof AllFlags>(
    spaceName: TSpace,
    flagName: typeof AllFlags[TSpace][number][0]
) {
    if (isFlagActive(spaceName, flagName)) disableFlagInternal(spaceName, flagName);
    else enableFlaginternal(spaceName, flagName);
}
