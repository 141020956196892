import * as React from 'react'
import {FaTimesCircle,} from 'react-icons/fa'
import {CircleButton, FullPageSpinner} from "../dashboards/_common/lib";
import Tooltip from "@mui/material/Tooltip";
import {IconButton} from '@mui/material';
import {BsInfoCircle} from "react-icons/bs";

// @ts-ignore
export function TooltipButton({label, highlight, onClick, icon, mutation, className = '', ...rest}) {
    const isFetching = false // useIsFetching() // TODO: useIsFetching prevent click on button on form without query

    const dataTag = rest['dataTag'] ? rest['dataTag'] : 'tooltip-button'
    return (

        <div>
            <Tooltip title={mutation?.isError ? mutation?.error?.message : label ?? ''}>
                <div>
                    <CircleButton
                        data-testid={dataTag}
                        className={className + ' ' + (rest?.disabled ? 'cursor-not-allowed' : '')}
                        disabled={(mutation?.isFetching || mutation?.isLoading) || isFetching || rest.disabled}
                        onClick={(event) => {
                            onClick();
                            event.preventDefault();
                        }}
                        {...rest}>
                        {(mutation?.isFetching || mutation?.isLoading) ? <FullPageSpinner/> : mutation?.isError ?
                            <FaTimesCircle/> : icon}
                    </CircleButton>
                </div>
            </Tooltip>
        </div>
    )
}


export function TooltipButtonLink({label, highlight, onClick, icon, mutation, className = '', href = '',id, ...rest}) {
    const isFetching = false // useIsFetching() // TODO: useIsFetching prevent click on button on form without query
    return (
        <a
            target={"_blank"}
            href={href && href}
            data-testid={id && id}
        >
            <Tooltip title={mutation?.isError ? mutation?.error?.message : label ?? ''}>
                <CircleButton
                    className={className}
                    disabled={(mutation?.isFetching || mutation?.isLoading) || isFetching || rest.disabled}
                    onClick={onClick}
                    {...rest}>
                    {(mutation?.isFetching || mutation?.isLoading) ? <FullPageSpinner/> : mutation?.isError ?
                        <FaTimesCircle/> : icon}
                </CircleButton>
            </Tooltip>
        </a>
    )
}

export function SimpleTooltip({label, icon = <BsInfoCircle/>, className = '', size = 'medium'}: {
    size?: 'small' | 'medium' | 'large',
    label: string,
    icon?: any,
    className?: string
}) {
    return <Tooltip title={label} className={className}>
        <IconButton size={size}>{icon}</IconButton>
    </Tooltip>
}



