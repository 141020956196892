import {TextField} from '@mui/material';
import React from 'react';
import {createDataTagAttrs} from "../../services/DataTagAttrs";

function TextfieldWrapper(props) {
    const {dataTag, ...other} = props;

    return (
        <TextField
            {...createDataTagAttrs(props.dataTag)}
            {...other}>
            {props.children}
        </TextField>
    );
}

export default TextfieldWrapper;



