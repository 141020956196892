import create from "zustand";

export enum EnchereSource {
    SideBar = 1,
    MesEncheres
}

export type EnchereContext = { source?: EnchereSource }

type EnchereContextStore = {
    enchereContext: EnchereContext;
    addEnchereContext: (EnchereContext: EnchereContext) => void;
};

export const useEnchereContextStore = create<EnchereContextStore>((set) => ({
    enchereContext: { },
    addEnchereContext: (EnchereContext) => set(() => ({enchereContext: EnchereContext})),
}));
