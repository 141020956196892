import React, {Component} from "react";
import loadingData from '../../lottie/loading.json';
import {FullPageSpinner, Spinner} from "../../dashboards/_common/lib";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};


export function FullScreenSpinner(props) {
    return (
        <FullPageSpinner/>
        /*<div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-screen md:inset-0 h-screen z-30">
                <div className="relative bg-gray-100 opacity-70">
                    <div className={"flex justify-center items-center h-screen"}>
                        <Lottie
                            className={"z-40"}
                            options={defaultOptions}
                            height={100}
                            width={100} />
                    </div>
                </div>
        </div>*/
    )
}
