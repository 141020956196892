import {useMutation, useQueryClient} from "react-query";
import {useState} from "react";
import {GrRevert} from "react-icons/gr";
import {Rights, useRightCheck} from "../utils/UseRightCheck";
import {TooltipButton} from "./tooltip-button";
import {danger} from "../styles/colors";
import ConfirmDialog from "./modals/ConfirmDialog";
import {del, post, put} from "../common/services/api/service";
import {AiOutlineEdit} from "react-icons/ai";
import * as React from "react";
import Button from "@mui/material/Button";
import {Modal} from "./stories/Modal/Modal";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {LoadingButton} from "./loading-button";
import {CircleButton} from "../dashboards/_common/lib";
import {ImPlus} from "react-icons/im";
import {ActionWrapper} from "./table/ActionWrapper";
import {Droits, useDroitCheck} from "../utils/UseDroitCheck";

export function UpdateStateButton<T>({
                                         icon,
                                         actionId,
                                         entity,
                                         canDisplay,
                                         actionLabel,
                                         right,
                                         droit,
                                         url,
                                         queryKey,
                                         keyToRefresh,
                                         payload,
                                         verb = 'put',
                                         message,
                                         className,
                                         showConfirmation = false,
                                         onSuccess,
                                         onError,
                                         displaymode = 'circle',
                                         confirmModal,
                                         modalTitle,
                                         disabled,
                                         onSubmiAttempt,
                                         dataTag
                                     }: {
    onSubmiAttempt?: () => Promise<boolean>,
    disabled?: boolean,
    actionId?: string,
    confirmModal?: JSX.Element,
    displaymode?: 'circle' | 'button',
    onSuccess?: (any) => void,
    onError?: (any) => void,
    showConfirmation?: boolean,
    className?: string,
    message?: JSX.Element,
    verb?: 'post' | 'put' | 'delete',
    icon?: JSX.Element,
    payload?: any,
    entity?: T,
    canDisplay?: (entity: T) => boolean,
    actionLabel: JSX.Element | string,
    modalTitle?: JSX.Element | string,
    right?: Rights,
    droit?: Droits,
    url: string,
    queryKey: string,
    keyToRefresh?: string[],
    dataTag?: string
}) {

    function getOnClick() {
        if (showConfirmation) {
            setConfirmOpen(true)
        } else {
            if (onSubmiAttempt) {
                onSubmiAttempt().then((result) => {
                    if (result) {
                        executeAction();
                    }
                })
            }
            else {
                executeAction();
            }
        }
    }

    const {t} = useTranslation();

    function executeAction() {
        mutation.mutate();
    }

    const [confirmOpen, setConfirmOpen] = useState(false)

    const queryClient = useQueryClient();

    const mutation = useMutation(() => {
        return verb == 'post' ? post(url, payload ?? {}) : verb == 'put' ? put(url, payload ?? {}) : del(url)
    }, {
        onSettled: (data: any) => {
            queryClient.invalidateQueries([queryKey]);

        },
        onSuccess: (success: any) => {
            if (onSuccess) {
                onSuccess(success.data)
            } else
                toast.success("Mise à jour effectuée");
            keyToRefresh?.forEach(key => queryClient.invalidateQueries([key]));
        },
        onError: (error: any) => {
            if (onError) {
                onError(error)
            } else
                toast.error("Erreur lors de la mise à jour");
        }
    })

    const modal = React.cloneElement(confirmModal ?? <ConfirmDialog
        title={modalTitle ?? actionLabel}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={executeAction}
    >
        <div id="modal-delete">
            {message ?? "Etes vous sur ?"}
        </div>
    </ConfirmDialog>, {
        onConfirm: executeAction,
        title: modalTitle ?? actionLabel,
        open: confirmOpen,
        setOpen: setConfirmOpen
    });
    let b = (entity && canDisplay && canDisplay(entity)) ?? true;
    let c = !right || useRightCheck(right);
    let d = !droit || useDroitCheck(droit);
    return (<ActionWrapper actionId={actionId}>
        {b && c && d ?
            <div className="flex justify-center items-center">
                {displaymode == 'button' && <LoadingButton
                    dataTag={dataTag}
                    disabled={disabled}
                    highlight={verb == 'delete' ? true : undefined}
                    children={actionLabel}
                    className={className}
                    label={actionLabel}
                    icon={icon || <AiOutlineEdit/>}
                    onClick={getOnClick}
                    mutation={mutation}
                />}
                {displaymode == 'circle' && <TooltipButton
                    className={className}
                    dataTag={dataTag}
                    label={actionLabel}
                    icon={icon || <AiOutlineEdit/>} highlight={undefined}
                    onClick={getOnClick}
                    mutation={mutation}
                />}
                {modal}

            </div> : null}
    </ActionWrapper>);
}
