import {useQuery} from "react-query";
import {get} from "../../common/services/api/service";
import {Settings} from "../../context/auth-context";

export function useAdministrateurSettings(): Settings[] {
    const {data: settings} = useQuery<Settings[]>('administrateurSettings',
        () => get(`administrateurSettings`).then((data) => data),
        {staleTime: Infinity});

    return settings ?? [];
}