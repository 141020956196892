import React from "react";
import {MenuItem} from "react-pro-sidebar";
import {styled} from "@mui/material/styles";
import {NavLink} from "react-router-dom";
import {TbPoint} from "react-icons/tb";
import {Rights, useRightCheck} from "../utils/UseRightCheck";
import {IconeResolver} from "./StyledSubMenu";


export const StyledNavLink = styled(NavLink)(({}) => ({"&.active": {fontWeight: 'bold'}}));

export function EmojiDisplay({emojis}: { emojis?: number[]}) {
    return (
        <div className={"flex  mr-2"}>
            {emojis?.map((emoji,index) => {
                const emojiCharacter = String.fromCodePoint(emoji);
                return <span className="text-2xl" key={index} >{emojiCharacter}</span>;
            })}
        </div>
    );
}

export default function StyledMenuItem({icon = <TbPoint/>, label, to, right, iconReference, emojis}: MenuItemProps) {
    let iconDisplay = (iconReference && IconeResolver(iconReference)) ?? <EmojiDisplay emojis={emojis} />;

    return useRightCheck(right) ?
        <StyledNavLink to={to} aria-label={label}>
            <MenuItem icon={iconDisplay} title={label} key={label}>
                {label}
            </MenuItem>
        </StyledNavLink> : null;
}

export interface MenuItemProps {
    icon?: React.ReactNode,
    label: string,
    to: string,
    emojis?: number[],
    right: Rights,
    iconReference?: string
    open?: boolean
}
