import {createDataTagAttrs} from "../../services/DataTagAttrs";
import React from "react";
import {TextField} from "formik-mui";
import {useFormikContext} from "formik";
import {DebugComponent} from "../../utils/DebugComponent";

export function TextFieldMuiWrapper(props) {
    const {dataTag, ...other} = props;

    return (
        <div className="flex flex-col w-full">
           {/* <DebugComponent data={{name: }}/>*/}
            <TextField
                {...createDataTagAttrs(props.dataTag)}
                {...other}>
                {props.children}
            </TextField>
        </div>
    );
}
