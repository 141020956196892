import React from 'react';
import Badge from '@mui/material/Badge';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import {TableOptions} from "./FilteredTable";
import {Header, Table} from "@tanstack/react-table";
import {BiDownArrowAlt} from "react-icons/bi";


export function TableHeadCellSortLabel<T>  ({
                                               header,
                                               table,
                                               options
                                           }: { options: TableOptions<T>, header: Header<T, unknown>, table: Table<T> }) {
    const {getState, setColumnOrder, } = table
    const {column} = header

    const { sorting } = getState();

    return (
        <Tooltip arrow placement="top" title={''}>
            <Badge
                badgeContent={sorting?.length > 1 ? column.getSortIndex() + 1 : 0}
                overlap="circular"
            >
                <TableSortLabel
                    className={"text-xl"}
                    aria-label={''}
                    active={!!column.getIsSorted()}
                    direction={
                        column.getIsSorted()
                            ? (column.getIsSorted() as 'asc' | 'desc')
                            : undefined
                    }
                    sx={{
                        flex: '0 0',
                        width: '2.4ch',

                    }}
                    IconComponent={BiDownArrowAlt}
                    onClick={(e) => {
                        e.stopPropagation();
                        header.column.getToggleSortingHandler()?.(e);
                    }}
                />
            </Badge>
        </Tooltip>
    );
}


