import {useQuery} from "react-query";
import {getAnonymous} from "../../common/services/api/service";
import {IFeatureFlags} from "../../common/services/interfaces/IVenteProduit";
import {useDebugMode} from "../../utils/UseDebugMode";

export function useFeatureFlagCheck(key: string, idSite?: number): boolean {
    const debugMode = useDebugMode();

    const {data} = useQuery<IFeatureFlags[]>('featureFlags',
        async () => await getAnonymous(`featureflags`),
        {
            enabled: key !== undefined,
            staleTime: Infinity,
            cacheTime: 1000 * 60 * 5,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
        });
    if (idSite) {
        const val = data?.find(x => x.key === key && x.isActive);
        if (!val || (val && val.featureFlagSiteEnabled?.sitesExcluded && val.featureFlagSiteEnabled?.sitesExcluded.includes(idSite) || (val && val.featureFlagSiteEnabled?.sitesIncluded && !val.featureFlagSiteEnabled?.sitesIncluded.includes(idSite))))
            return false;
        if (val.featureFlagSiteEnabled?.sitesIncluded && val.featureFlagSiteEnabled?.sitesIncluded.includes(idSite))
            return true;
    }


    let find = data?.find(x => x.key === key);

    let active = find?.isActive;

    if (!active) return false;

    let isFfDebugMode = find?.debugMode;

    if (isFfDebugMode && !debugMode) return false;

    return active;
}

export function useFeatureFlag(key: string): IFeatureFlags | undefined {

    const {data} = useQuery<IFeatureFlags[]>('featureFlags',
        async () => await getAnonymous(`featureflags`),
        {
            enabled: key !== undefined,
            staleTime: 1000 * 60 * 5,
            cacheTime: 1000 * 60 * 5,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
        });

    return data?.find(x => x.key === key);
}



