import { useQuery } from "react-query";
import { get, getAnonymous } from "../../common/services/api/service";
import { RightsDto } from "../../common/services/interfaces/IAdministrateurRightsDto";
import { useRightsStore } from "../../stores/administrateurs-rights";
import { ItemListWithGroup } from "../../common/services/interfaces/ItemList";

export function useRights() {
  const { rights, setRights } = useRightsStore();

  const { data: rightRefs } = useQuery<RightsDto[]>(
    "administrateurs-rights",
    () => getAnonymous(`administrateurs-rights`).then((data) => data),
    {
      staleTime: Infinity,
      onSuccess: (data) => setRights(data),
    }
  );

  return rightRefs ?? rights;
}

export function useDroits() {
  const { droits, setDroits } = useRightsStore();

  const { data: rightRefs } = useQuery<RightsDto[]>(
    "administrateurs-droits",
    () => get(`administrateurs-droits`).then((data) => data),
    {
      staleTime: Infinity,

      enabled: !droits || droits.length === 0,
      onSuccess: (data) => setDroits(data),
    }
  );

  return rightRefs ?? droits;
}

export function useRightsFilter() {
  const rightRefs = useRights();
  return rightRefs
    ? rightRefs!.map(
        (r: RightsDto) =>
          ({
            id: r.id,
            name: r.label,
            group: r.group ?? "-",
          } as ItemListWithGroup)
      )
    : [];
}
