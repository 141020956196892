import create, {StateCreator} from "zustand";
import {persist, PersistOptions} from "zustand/middleware";
import {RightsDto} from "../common/services/interfaces/IAdministrateurRightsDto";

type RightsStore = {
    rights: RightsDto[];
    droits: RightsDto[];
    setRights: (rights: RightsDto[]) => void;
    setDroits: (droits: RightsDto[]) => void;
}

type RightsStorePersist = (
    config: StateCreator<RightsStore>,
    options: PersistOptions<RightsStore>
) => StateCreator<RightsStore>

export const useRightsStore = create<RightsStore>(
    (persist as RightsStorePersist)(
        (set) => ({
            rights: [],
            droits: [],
            setRights: (rights) => set({rights}),
            setDroits: (droits) => set({droits}),
        }),
        {
            name: "rights",
            version: 1
        }
    )
);