import * as React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {
    createStaticRanges,
    DateRange,
    DateRangePicker,
    defaultInputRanges,
    defaultStaticRanges,
    Range
} from "react-date-range";
import {useEffect, useState} from "react";
import {addDays, addYears, endOfYear, format, isSameDay, startOfYear, subDays} from "date-fns";
import {BiColumns} from "react-icons/bi";
import BasicMenu from 'components/Menu';
import {AiFillCalendar} from 'react-icons/ai';
import {resetSearchQuery} from "../../../utils/ResetSearchQuery";
import {IoCloseOutline} from "react-icons/io5";
import {useDebounce} from "react-use";
import {HtmlTooltip} from "../../forms/Tooltip";
import Button from "@mui/material/Button";
import {fr} from 'date-fns/locale'
import {useDebugMode} from "../../../utils/UseDebugMode";
import {LoadingButton} from "../../loading-button";
import ButtonWrapper from "../../wrappers/ButtonWrapper";
import {FullPageSpinner} from "../../../dashboards/_common/lib";
import {FaTimesCircle} from "react-icons/fa";
import {TableOptions} from "../FilteredTable";

export default function SimpleDateRangeFilter<T>({onChange, title, initialFilterValue,options}: {
    initialFilterValue?: any,
    title: string,
    options: TableOptions<T>,
    onChange: (value: string) => void
}) {
    const [state, setState] = useState<Range[] | undefined>(undefined);

    const [val, setVal] = React.useState('');

    useEffect(() => {
        if (initialFilterValue) {
            const split = initialFilterValue.split('|');
            const startDate = new Date(split[0]);
            const endDate = new Date(split[1]);

            setState([{
                startDate: startDate,
                endDate: endDate,
                key: 'selection'
            }]);
        } else {
            setVal('');
            setState(undefined);
        }
    }, [initialFilterValue]);


    const staticRangesLabels = {
        Today: "Aujourd'hui",
        Yesterday: "Hier",
        "This Week": "Cette semaine",
        "Last Week": "La semaine dernière",
        "This Month": "Ce mois-ci",
        "Last Month": "Le mois dernier",
        "This Year": "Cette année",
        "Last Year": "L'année dernière",
    };

    const inputRangesLabels = {
        "days up to today": "jours jusqu'à aujourd'hui",
        "days starting today": "jours à partir d'aujourd'hui",
    };

    function translateRange(dictionary) {
        return (item) =>
            dictionary[item.label] ? {...item, label: dictionary[item.label]} : item;
    }

    const defineds = {
        startOfYear: startOfYear(new Date()),
        endOfYear: endOfYear(new Date()),
        startOfLastYear: startOfYear(addYears(new Date(), -1)),
        endOfLastYear: endOfYear(addYears(new Date(), -1)),
    };
    const addRanges = [{
        label: 'This Year',
        range: () => ({
            startDate: defineds.startOfYear,
            endDate: defineds.endOfYear,
        }),
        isSelected(range) {
            const definedRange = this.range();
            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            );
        },
    },
        {
            label: 'Last Year',
            range: () => ({
                startDate: defineds.startOfLastYear,
                endDate: defineds.endOfLastYear,
            }),
            isSelected(range) {
                const definedRange = this.range();
                return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                );
            },
        },]

    const ruStaticRanges = createStaticRanges([...defaultStaticRanges, ...addRanges]).map(
        translateRange(staticRangesLabels)
    );

    const ruInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));
    return (
        <div className="flex gap-2">

            <BasicMenu triggerButton={<Button
                id="basic-button"
                aria-haspopup="true"
            >
                <div className="p-2 border-0 shadow rounded cursor-pointer text-white bg-transparent">{state ?
                    <div className="flex gap-2 items-center ">
                    <span className="text-blue-dark">
                        <span>{format(state[0]!.startDate!, 'dd/MM/yyyy')}</span>
                        <span className="p-2">→</span>
                        <span>{format(state[0]!.endDate!, 'dd/MM/yyyy')}</span>
                    </span>
                    </div>
                    : <span className="text-blue-dark">Période</span>}
                </div>

            </Button>} tooltip={'Filtrer par date'} label={<AiFillCalendar/>}>
                <div className={"flex flex-col gap-2"}>
                    <DateRangePicker
                        editableDateInputs={true}
                        months={2}
                        onChange={item => {
                            setState([item.selection]);
                            setVal(`${format(item.selection.startDate!, 'yyyy-MM-dd')}|${format(item.selection.endDate!, 'yyyy-MM-dd')}`);
                        }}

                        moveRangeOnFirstSelection={false}
                        showMonthAndYearPickers={true}
                        ranges={state ?? [
                            {
                                startDate: subDays(new Date(), 7),
                                endDate: new Date(),
                                key: 'selection'
                            }
                        ]}
                        direction="horizontal"
                        locale={fr}

                        staticRanges={ruStaticRanges}
                        inputRanges={ruInputRanges}
                    />
                    <div className={"flex justify-end"}>
                        <ButtonWrapper
                            disabled={!val}
                            className="bg-blue-electric h-14 disabled:bg-[#D8D8D8] text-[11px] text-white py-2 px-4  active:shadow-md rounded-full focus:outline-none active:shadow-none mr-2 md:h-12 font-extrabold md:mt-8 md:block w-48 text-center"
                            onClick={() => {
                                onChange(val);
                            }}
                        >
                            {(options.dataQuery?.isFetching || options.dataQuery?.isLoading) ? <FullPageSpinner/> : 'Appliquer'}
                        </ButtonWrapper></div>
                </div>
            </BasicMenu>
            {val && <HtmlTooltip
                title={'Réinitaliser filtre'}>
                <button onClick={(event) => {
                    onChange('');
                    resetSearchQuery();
                    setVal('')
                    setState(undefined);
                }}><IoCloseOutline className="text-xl"/></button>
            </HtmlTooltip>}
        </div>
    );
}

