import {useQuery} from "react-query";
import {get, getBlob} from "../common/services/api/service";
import {ColumnFiltersState, SortingState} from "@tanstack/react-table";
import {DateFilter} from "../dashboards/encherisseurs/TableClients";
import {createBrowserHistory} from "history";

export interface ICustomFilter {
    [key: string]: string | number | Date | boolean | null;
}

export interface ICustomFilterBoolean<T> {
    key: keyof T;
    defaultActivated: boolean;
    detailOn: string,
    detailOff: string;
    targetOnValue?: boolean | number;
    targetOffValue?: boolean | null;
}

export type TableQueryOptions<T> = {
    queryKey?: string,
    updateUrlQueryString?: boolean,
    customFilter?: ICustomFilter,
    customFilterBoolean?: ICustomFilterBoolean<T>[],
    pageIndex?: number,
    pageSize?: number,
    sorting?: SortingState,
    columnFilters: ColumnFiltersState,
    dateFilter?: Partial<DateFilter>,
    filterSetup?: string[],
    dateFilterColumn?: string,
    additionalFilters?: ColumnFiltersState | null,
    dateFilterCols?: string[] | null,
    coumpoundFilter?: string
}


export function getUrl<T>({
                              queryKey,
                              filterOptions,
                              updateUrlQueryString
                          }: {
    filterOptions: TableQueryOptions<T>,
    queryKey: string,
    updateUrlQueryString?: boolean | null
}) {
    let {
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
        dateFilter,
        dateFilterColumn,
        additionalFilters,
        customFilter,
        coumpoundFilter,
    } = filterOptions;

    const columnFilters1 = columnFilters || [];
    columnFilters = additionalFilters ? [...columnFilters1, ...additionalFilters] : columnFilters;
    let page = ''
    if (pageIndex != null) {
        page = `page=${pageIndex + 1}&pageSize=${pageSize}`;
    }

    const strings1 = (sorting || [])
        .map(s => {
            return (`${s.desc ? '-' : ''}${s.id}`);
        });
    let sorts = '';
    if (strings1.length > 0) sorts = `&sorts=${strings1.join(',')}`;

    const strings = (columnFilters || [])
        .filter(f => f.value !== null)
        .map(filter => {
            return (`${filter.id}${filter.value}`);
        });
    let f = strings;
    const dates = dateFilter?.startDate && `${dateFilterColumn}==${dateFilter.startDate}|${dateFilter.endDate}`;
    if (dates) f.push(dates);

    if (customFilter) {
        f.push(...Object.entries(customFilter).filter(([key, value]) => value)
            .map(([key, value]) => `${key}==${value}`));
    }

    let filters = '';
    if (f.length > 0) filters = `&filters=${f.join(',')}`;

    if (coumpoundFilter) {
        //append coumpound filter
        filters += `,${coumpoundFilter}`;
    }
    const s = (page || sorts || filters) ? `?${page}${sorts}${filters}` : '';
    const url = `${queryKey}${s}`;

    if (updateUrlQueryString) {
        let history = createBrowserHistory();
        let location = history.location;
        history.push({
            pathname: location.pathname,
            hash: location.hash,
            search: s,
        })
    }

    return url;

}


function useFilteringSortingPagination<T>({
                                              queryKey,
                                              filterOptions,
                                              isExport,
                                              isExportAsynchronous,
                                              updateUrlQueryString,
                                              enabledFactory = () => true,
                                              selectedCols
                                          }: {
    enabledFactory?: (filterOptions: TableQueryOptions<T>) => boolean,
    filterOptions: TableQueryOptions<T>,
    queryKey: string,
    isExport?: boolean | null,
    isExportAsynchronous?: boolean | null,
    updateUrlQueryString?: boolean | null,
    selectedCols?: string[]
}) {
    let {
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
        dateFilter,
        additionalFilters,
        customFilter,
        coumpoundFilter,
    } = filterOptions;


    return useQuery(
        [queryKey, pageIndex, pageSize, sorting, columnFilters, dateFilter, additionalFilters, customFilter, coumpoundFilter],
        () => {
            const url = getUrl({queryKey, filterOptions, updateUrlQueryString});
            if (isExport) {
                const urlWithColumns = `${url}&columns=${selectedCols?.join(',')}`;
                return isExportAsynchronous ? get(urlWithColumns) : getBlob(urlWithColumns);
            } else {
                return get(url);
            }
        },
        {
            keepPreviousData: true,
            useErrorBoundary: false,
            enabled: !isExport && !!queryKey && enabledFactory(filterOptions)
        }
    )

}

export {useFilteringSortingPagination};
