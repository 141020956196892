import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {HtmlTooltip} from "./forms/Tooltip";
import {CircleButton} from "../dashboards/_common/lib";
import {useEffect} from "react";

export default function BasicMenu({tooltip, children, label, onClose, triggerButton, closeRequest}: {closeRequest?: boolean, triggerButton?: JSX.Element, tooltip?: string, label: React.ReactNode, children: React.ReactNode, onClose?: () => void }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        onClose?.()
    };

    const trigger = React.cloneElement(triggerButton ??  <Button
        id="basic-button"
        className="p-0"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
    >
        {label}
    </Button>, {
        onClick: handleClick,
    });


    return (
        <>
            <HtmlTooltip title={tooltip ?? ''}>
                {trigger}
            </HtmlTooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}

                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {children}
            </Menu>
        </>


    );
}
