/**
 * For some purposes like:
 * - user experience (eg.: Intercom)
 * - testing (eg.: Cypress)
 * we have to be able to identify pieces of our UI in order to be able to use or highlight it.
 *
 * This function ease the tagging part and apply all the tags we need in the platform.
 * Simplify call `createDataTagAttrs` as follow:
 *   <div {...createDataTagAttrs('tag-name')} />
 *
 * @param dataTag Tag to be applied.
 * It may not be unique, it is just an helper to identify and find the component easily.
 * (compared to find by labels, by accessibility helper... that are subjects to change)
 */
export function createDataTagAttrs(dataTag: string | undefined) {
  if (dataTag === undefined) {
    return {} as const;
  }
  return {
    // Simplify usage of element selection for the tour service (otherwise it requires explicit CSS selectors)
    'data-tour-target': dataTag,
    // Most tests frameworks come with built-in selectors for that one
    'data-testid': dataTag,
  } as const;
}

/**
 * Quickly transform a label to some kind of tag
 * "My Label" -> "my-label"
 *
 * @param label Original label
 */
export function labelToTag(label: string) {
  return label.toLowerCase().replace(/\s+/g, '-');
}
