import {Button} from '@mui/material';
import React from 'react';
import {createDataTagAttrs} from "../../services/DataTagAttrs";

function ButtonWrapper(props) {

    const {dataTag, ...other} = props;

    return (
        <Button
            {...createDataTagAttrs(props.dataTag)}
            {...other}
            >
            {props.children}
        </Button>
    );
}

export default ButtonWrapper;
