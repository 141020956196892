import {useAuth} from "../context/auth-context";
import {useServices} from "../dashboards/_common/UseServices";

export function useServiceCheck(service: AgorastoreService) {
    const services = useServices()
    const {user} = useAuth();

    return user?.services?.map(f => services?.find(tt => tt.id === f)).map(l => l?.id).includes(service) ?? false
}

export function useServicesCheck(services: AgorastoreService[]) {
    const allServices = useServices();
    const {user} = useAuth();

    const userServices = user?.services?.map(id => allServices?.find(r => r.id === id)).map(l => l?.id);
    const servicesToCheck = services.map(service => service);
    const intersection = userServices?.filter(service => service && servicesToCheck.includes(service));

    return (intersection && intersection.length > 0) ?? false;
}

export enum AgorastoreService {
    None = 0,
    IT = 1,
    Collectivity = 2,
    Entreprise = 3,
    Finances = 4,
    ImmobilierAcheteurs = 5,
    ImmobilierVendeurs = 6,
    Marketing = 7,
    ServiceClient = 8,
    Direction = 9
}

