import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import AppSettings, {AzureBlobStorageUrlKey} from "../AppSettings";
import {localStorageKey} from "../../../auth-provider";
import {toast} from "react-toastify";
import {datadogLogs} from "@datadog/browser-logs";
import jwtDecode from "jwt-decode";

let instance: AxiosInstance;

function getLocalAccessToken() {
    return window.localStorage.getItem("accessToken");
}

function getLocalRefreshToken() {
    return window.localStorage.getItem("refreshToken");
}

export const setInstanceNew = (url: string, onError: () => void, onCheck403: () => void) => {
    instance = axios.create({
        baseURL: url,
        headers: {
            "Content-Type": "application/json",
        },
    });
    instance.interceptors.request.use(
        async (config) => {

            if (config?.headers) {
                const host = datadogLogs.getGlobalContext().host
                if (host) {
                    config.headers["X-Host-From"] = host as string;
                }

                const location = datadogLogs.getGlobalContext().location
                if (location) {
                    config.headers["X-Location-From"] = location as string;
                }
            }

            const newConfig = {
                ...config,
                headers: config.headers
            };

            if (config?.data?.anonymous) {
                return config;
            }

            if (config?.headers) {
                const token = getLocalAccessToken();

                if (token && !config.url?.startsWith(AppSettings.get(AzureBlobStorageUrlKey))) {
                    config.headers["Authorization"] = 'Bearer ' + token;
                }

                if (!config.url?.includes("renew")) {

                    const refreshToken = getLocalRefreshToken();
                    if (!refreshToken || isTokenExpired(refreshToken)) {
                        onError();
                        window.localStorage.removeItem("accessToken");
                        window.localStorage.removeItem("refreshToken");
                    }

                    const tokenExpired = isTokenExpired(token);
                    if (tokenExpired) {
                        const access_token = await postAnonymous("/renew", {refreshToken});
                        const value = (access_token as any).data.token.toString();
                        window.localStorage.setItem(localStorageKey, value);
                        newConfig!.headers!["Authorization"] = 'Bearer ' + value;
                        return newConfig

                    }
                }
            }
            return config;
        },
        (error) => {
            onError();
            window.localStorage.removeItem("accessToken");
            window.localStorage.removeItem("refreshToken");
            return Promise.reject(error);
        }
    );
    instance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (err.response) {
                // Access Token was expired
                if ((err.response.status === 401) && !originalConfig._retry && err.response.config.url !== '/renew') {
                    originalConfig._retry = true;
                    const refreshToken = getLocalRefreshToken();
                    if (!refreshToken || isTokenExpired(refreshToken)) {
                        window.localStorage.removeItem("accessToken");
                        window.localStorage.removeItem("refreshToken");
                        onError();
                        return Promise.reject(err);
                    }
                    const access_token = await postAnonymous("/renew", {refreshToken});
                    const value = (access_token as any).data.token.toString();
                    window.localStorage.setItem(localStorageKey, value);
                    return instance(originalConfig);
                }

                if (err.response.status === 401 && (err.response.config.url === '/renew')) {
                    onError();
                }
                if (err.response.status === 403) {
                    onCheck403();

                    if (err.response.data) {
                        return Promise.reject(err.response.data);
                    }
                }
                if (err.response.data) {
                    if (!(err.response.config.url.startsWith('payable-product') && err.response.status === 400)) {
                        toast.error(err.response.data);
                    }
                }
            }

            return Promise.reject(err);
        }
    );
};


export const isTokenExpired = token => {
    try {
        const payload = jwtDecode(token);
        const epoch = Math.round(Date.now() / 1000);
        return (payload as any).exp <= epoch;
    } catch (err: any) {
        return true;
    }
};

export async function get(url: string): Promise<any> {
    const {data, status, statusText} = await instance.get(url);
    return data;
}

export async function getAnonymous(url: string): Promise<any> {

    const {data, status, statusText} = await instance.get(url, {data: {anonymous: true}});
    return data;
}

export async function getBlob(url: string): Promise<any> {
    return instance.get(url, {responseType: 'blob'}).then((res) => res.data);
}

export async function getBlobAnonymous(url: string): Promise<any> {
    return instance.get(url, {responseType: 'blob', data: {anonymous: true}}).then((res) => res.data);
}

export async function getBlobFull(url: string): Promise<any> {
    return instance.get(url, {responseType: 'blob'}).then((res) => res);
}

export async function getBlobFullAnonymous(url: string): Promise<any> {
    return instance.get(url, {responseType: 'blob', data: {anonymous: true}}).then((res) => res);
}

export async function post<T>(url: string, data: any): Promise<T> {
    return instance.post(url, data);
}

export async function postAnonymous<T>(url: string, data: any): Promise<T> {
    return instance.post(url, {...data, anonymous: true});
}


export async function postBlob<T>(url: string, data: any): Promise<T> {
    return instance.post(url, data, {responseType: 'blob'}).then((res) => res.data);
}

export async function put<T>(url: string, data: any): Promise<T> {
    return instance.put(url, data);
}

export async function putAnonymous<T>(url: string, data: any): Promise<T> {
    return instance.put(url, {...data, anonymous: true});
}


export async function del<T>(url: string): Promise<T> {
    return instance.delete(url);
}

export async function postFormData(url: string, formData: FormData): Promise<any> {
    const options: AxiosRequestConfig = {
        method: 'POST',
        url: url,
        headers: {
            Accept: 'text/plain',
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    };

    return await instance.request(options);

}
