import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { AiFillEdit, AiOutlineEdit } from 'react-icons/ai';
import DialogTitle from "@mui/material/DialogTitle";
import { TooltipButton } from "../tooltip-button";
import DialogContent from "@mui/material/DialogContent";
import { Breakpoint } from "@mui/system";
import { gray, text } from "../../styles/colors";
import { Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "../loading-button";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { FullPageSpinner } from "../../dashboards/_common/lib";
import { ActionWrapper } from "../table/ActionWrapper";
import { useEffect } from "react";
import { useConfig } from "../../context/auth-context";
import ButtonWrapper from "../wrappers/ButtonWrapper";

const useStyles = makeStyles({
    dialog: {
        position: 'fixed',
        left: 0,
        top: 0
    }
});

export default function ModalEditComponent({
    children,
    actions,
    label,
    title,
    icon,
    maxWidth,
    fullWidth = true,
    fullScreen = false,
    fullHeight = true,
    disabled = false,
    initialOpen = false,
    onOpenDialog,
    onCloseDialog,
    displaymode = 'circle',
    isFixed = false,
    onConfirm,
    onDeny,
    defaultActions = false,
    actionId,
    errors,
    isLoading,
    autoConfirm = false,
    disableEnforceFocus = true,
    dirty,
    getOpenStateMutator,
    ...other
}: {
    dirty?: boolean,
    disableEnforceFocus?: boolean,
    autoConfirm?: boolean,
    isLoading?: boolean,
    errors?: any,
    actionId?: string,
    defaultActions?: boolean,
    isFixed?: boolean,
    displaymode?: 'circle' | 'button' | 'component',
    children: React.ReactNode,
    actions?: JSX.Element,
    label?: string | JSX.Element,
    title: string | JSX.Element,
    icon?: React.ReactNode,
    maxWidth?: Breakpoint,
    fullWidth?: boolean,
    fullHeight?: boolean,
    disabled?: boolean,
    fullScreen?: boolean,
    initialOpen?: boolean,
    onOpenDialog?: () => void,
    onCloseDialog?: () => void,
    onConfirm?: () => void,
    onDeny?: () => void,
    getOpenStateMutator?: (mutator: (isOpen: boolean) => void) => void,
    [x: string]: any;
}) {
    const [open, setOpen] = React.useState(initialOpen);
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        if (getOpenStateMutator) {
            getOpenStateMutator(setOpen);
        }
    }, [])


    useEffect(() => {
        if (autoConfirm && open) {
            onConfirm && onConfirm();
            setOpen(false);

        }
    }, [autoConfirm, open])

    const handleClickOpen = () => {
        setOpen(true);
        onOpenDialog && onOpenDialog();
    };

    const handleClose = () => {
        setOpen(false);
        onCloseDialog && onCloseDialog();
    };

    const newVar = (errors && typeof errors === 'object') && Object.keys(errors).length > 0;
    const { config } = useConfig();
    const className = config.isBoLegacy ? "bg-blue-dark-legacy" : "bg-blue-dark";

    return (
        <ActionWrapper actionId={actionId}>
            <div onClick={e => e.stopPropagation()}>
                <React.Fragment>
                    <div className="flex justify-center items-center">
                        {displaymode == 'button' &&
                            <LoadingButton
                                children={label ?? `Editer`}
                                className={"bg-blue-electric text-white"}
                                label={label}
                                disabled={disabled}
                                icon={icon || <AiOutlineEdit />} highlight={undefined}
                                onClick={handleClickOpen}
                                mutation={undefined}
                            />}
                        {displaymode == 'circle' &&
                            <TooltipButton
                                disabled={disabled}
                                label={label ?? `Editer`}
                                icon={icon ?? <AiFillEdit color={!disabled ? text : gray} />} highlight={undefined}
                                onClick={handleClickOpen} mutation={undefined} />}
                        {displaymode == 'component' && <div className="w-full cursor-pointer" onClick={handleClickOpen}>{icon}</div>}
                    </div>
                    <Dialog
                        open={open}
                        disableEnforceFocus={disableEnforceFocus}
                        fullScreen={fullScreen}
                        fullWidth={fullWidth}
                        onClose={handleClose}
                        {...other}
                        maxWidth={maxWidth || 'xl'}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle className={`${className} inline-flex flow-root px-0 py-0`}>
                            <div className="float-left text-white py-5 px-3 md:py-6 md:px-4 line-clamp-2 max-w-[75%]">
                                <Typography className="font-bold text-base overflow-ellipsis" gutterBottom>{title}</Typography>
                            </div>
                            <Button
                                className="float-right text-white h-full font-bold text-base py-5 px-3 md:py-6 md:px-4 hover:bg-blue-lightened-dark"
                                onClick={handleClose}
                            >
                                <p className="my-auto">{t("common::Fermer")}</p>
                                <CloseIcon className={"ml-2"} />
                            </Button>
                        </DialogTitle>

                        <DialogContent style={fullHeight ? { height: '80vh' } : {}}>
                            <div {...other} className="pt-8 h-full">{children}</div>
                        </DialogContent>
                        {defaultActions && <DialogActions className="p-0">
                            <div className="flex items-center h-fit w-full p-0">
                                <ButtonWrapper data-modal-toggle="defaultModal" type="button"
                                    className="uppercase text-blue-dark bg-white hover:bg-gray-100 rounded-bl-lg border-2 text-sm font-medium px-8 py-8 w-1/2 hover:text-gray-900 focus:z-10"
                                    onClick={() => {
                                        setOpen(false);
                                        onDeny && onDeny();
                                    }}>{t("common::Annuler")}
                                </ButtonWrapper>
                                <ButtonWrapper data-modal-toggle="defaultModal"
                                    dataTag={`modal-confirm-button${actionId ? "-" + actionId : ""}`}
                                    disabled={!dirty || newVar || isLoading}
                                    className="uppercase  disabled:bg-[#D8D8D8] text-white bg-blue-electric hover:opacity-70 font-medium border-2 rounded-br-lg text-sm px-8 py-8 text-center w-1/2 focus:z-10"
                                    onClick={() => {
                                        setOpen(false);
                                        onConfirm && onConfirm();
                                    }}>{!isLoading ? t("common::Valider") :
                                        <div className="flex items-center justify-center"><FullPageSpinner /></div>}
                                </ButtonWrapper>
                            </div>
                        </DialogActions>}
                        {actions && <DialogActions>{actions}</DialogActions>}
                    </Dialog>
                </React.Fragment>
            </div>
        </ActionWrapper>)
}

export function ModalEditComponent2({
    children,
    label,
    icon,
    maxWidth,
    fullWidth = true,
    disabled = false,
    initialOpen = false,
    onOpenDialog,
    onCloseDialog,
    displaymode = 'circle',
    setClosed,
}: {
    children: React.ReactNode,
    label?: string,
    icon?: React.ReactNode,
    maxWidth?: Breakpoint,
    fullWidth?: boolean,
    disabled?: boolean,
    initialOpen?: boolean,
    onOpenDialog?: () => void,
    onCloseDialog?: () => void,
    displaymode?: 'circle' | 'button' | 'component',
    setClosed?: boolean,
}) {
    const [open, setOpen] = React.useState(initialOpen);

    const handleClickOpen = () => {
        setOpen(true);
        onOpenDialog && onOpenDialog();
    };

    const handleClose = () => {
        setOpen(false);
        onCloseDialog && onCloseDialog();
    };

    return (
        <div onClick={e => e.stopPropagation()} onContextMenu={e => e.stopPropagation()}>
            <React.Fragment>
                <div className="flex justify-center items-center">
                    {displaymode == 'button' && <LoadingButton
                        children={label ?? `Editer`}
                        className={"bg-blue-electric"}
                        label={label}
                        icon={icon || <AiOutlineEdit />} highlight={undefined}
                        onClick={handleClickOpen}
                        mutation={undefined}
                    />}
                    {displaymode == 'circle' && <TooltipButton
                        disabled={disabled}
                        label={label ?? `Editer`}
                        icon={icon ?? <AiFillEdit color={!disabled ? text : gray} />} highlight={undefined}
                        onClick={handleClickOpen} mutation={undefined} />}
                    {displaymode == 'component' && <div className="w-full" onClick={handleClickOpen}>{icon}</div>}
                </div>
                <Dialog
                    open={setClosed ? setClosed : open}
                    fullWidth={fullWidth}
                    onClose={handleClose}
                    maxWidth={maxWidth || 'xl'}
                    aria-labelledby="form-dialog-title"
                >
                    {children}
                </Dialog>
            </React.Fragment>
        </div>)
}
