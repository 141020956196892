import * as React from 'react';
import {useEffect, useState} from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useQuery, useQueryClient} from "react-query";
import {get} from "../../../common/services/api/service";
import {Box, BoxProps, Card, Checkbox, Divider, Grid, Popper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {FullPageSpinner} from "../../../dashboards/_common/lib";
import {styled} from "@mui/material/styles";
import {useViewConfigForRoute} from "../../../dashboards/_common/UseViewConfigForRoute";


export const StyledInput = styled(TextField)(({theme}) => ({
    borderColor: theme.palette.mode === 'light' ? '#fff' : '#388bfd',
    width: '100%',
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${
                theme.palette.mode === 'light'
                    ? '#fff'
                    : 'rgb(12, 45, 107)'
            }`,
            borderColor: theme.palette.mode === 'light' ? '#fff' : '#388bfd',
        },
    },
}));

export type MultiSelectFilterRemoteDataProps = {
    endAdornment?: React.ReactNode,
    startAdornment?: React.ReactNode,
    isMulti?: boolean;
    disableCloseOnSelect?: boolean;
    queryString?: string;
    getLabel?: (item: any) => string;
    transform?: (data: any) => any;
    autoFocus?: boolean;
    placeHolder?: string,
    disabled?: boolean,
    width?: string,
    showDisabled?: boolean,
    hideOptionId?: boolean,
    url: string,
    additionalQueryParameters?: { left: string; operator: string; right: string }[]
    initialFilterValue: any,
    initialInputValue?: string | null,
    filterSelector: 'id' | 'name',
    title: string,
    onChange?: (value: string) => void,
    onFullChange?: (value: any[]) => void,
    onInputChange?: (value: string) => void,
    onEnterDown?: (event: any, search: string, optionsCount: number) => void,
    showSelectAll?: boolean,
}


export default function MultiSelectFilterRemoteData({
                                                        url,
                                                        additionalQueryParameters,
                                                        disabled,
                                                        onChange,
                                                        onFullChange,
                                                        title,
                                                        placeHolder,
                                                        width,
                                                        initialFilterValue,
                                                        initialInputValue,
                                                        filterSelector,
                                                        showDisabled = false,
                                                        autoFocus = false,
                                                        transform,
                                                        getLabel,
                                                        queryString = 'query=',
                                                        isMulti = true,
                                                        onInputChange,
                                                        disableCloseOnSelect,
                                                        hideOptionId = false,
                                                        onEnterDown,
                                                        showSelectAll = true,
                                                        endAdornment = <></>,
                                                        startAdornment = <></>

                                                    }: MultiSelectFilterRemoteDataProps) {

    const find = useViewConfigForRoute();
    const useDefaultFiltersAsAddionalQueryParameters = find?.useDefaultFiltersAsAddionalQueryParameters;

    useEffect(() => {
        if (initialFilterValue.length == 0 && value?.length > 0) setValue([]);
    }, [initialFilterValue]);


    if (initialFilterValue) {
        if (!Array.isArray(initialFilterValue)) {
            initialFilterValue = initialFilterValue.split('|').map((r: any) => ({id: +r, name: r}));
        }
    } else {
        initialFilterValue = []
    }

    const [value, setValue] = React.useState<any[]>(initialFilterValue);
    const [inputValue, setInputValue] = React.useState(initialInputValue ? initialInputValue : "");
    const [options, setOptions] = React.useState<any[]>([]);

    const debouncedSearch = useDebounce(inputValue, 500);

    const queryClient = useQueryClient();

    const {isLoading, isFetching} = useQuery(
        [url, {debouncedSearch}],
        () => {
            let array: string[] = [];
            if (additionalQueryParameters && additionalQueryParameters.length > 0) {
                array = additionalQueryParameters.filter(r => r.right != null).map((q) => `${q.left}${q.operator}${q.right}`);
            }

            if (useDefaultFiltersAsAddionalQueryParameters) {
                const defaultFilters = find?.defaultFilters;
                if (defaultFilters && defaultFilters.length > 0) {
                    defaultFilters.forEach((q) => {
                        //split on == to get the operator
                        const value = (q.value as any)?.split('==')[1];
                        array.push(`${q.id}=${value}`);
                    });
                }
            }

            if (inputValue) {
                array.push(`${queryString}${encodeURIComponent(inputValue)}`);
            }
            let urlWithQuery = `${url}?${array.join('&')}`;

            return get(urlWithQuery).then(data => transform ? transform(data) : data);
        },
        {
            onSuccess: (data) => {
                //order by group
                data = data.sort((a, b) => (a.group > b.group) ? 1 : -1);
                setOptions(data);
                onInputChange && onInputChange(inputValue);
            },
            enabled: !!debouncedSearch
        }
    )

    const queryKey = [url, {debouncedSearch}];
    const cachedData = queryClient.getQueryData(queryKey);

    useEffect(() => {
        if (additionalQueryParameters) {
            setInputValue('');
            setOptions([]);
        }
    }, [additionalQueryParameters]);

    useEffect(() => {
        if (cachedData) {
            const cachedData1 = cachedData as unknown as any[];
            setOptions(cachedData1);
        }
    }, [cachedData]);

    const [checkAllOptions, setCheckAllOptions] = React.useState(false);
    useEffect(() => {
        if (value?.length == 0) setOptions(cachedData ? cachedData as unknown as any[] : []);
        setCheckAllOptions(value?.length == options?.length)
        setInputValue('');
    }, [value]);


    return (
        <Autocomplete


            multiple={isMulti}
            freeSolo
            loading={isLoading || isFetching}
            loadingText={isLoading || isFetching ? <FullPageSpinner/> : ''}
            noOptionsText={'aucune donnée ne correspond au critère de recherche'}
            size="small"
            color={"info"}
            id="fixed-tags-demo"
            value={value}
            readOnly={disabled ?? false}
            limitTags={1}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            autoHighlight
            includeInputInList
            inputValue={inputValue}
            filterSelectedOptions
            disableCloseOnSelect={disableCloseOnSelect ?? true}
            onChange={(event, newValue) => {

                const newVar = [...newValue];
                setValue(newVar);
                onChange && onChange(newValue.map((v) => filterSelector == 'name' ? v.name : v.id).join('|'));
                onFullChange && onFullChange(newValue);
                //closeMenu();
                //close the menu
                const newValues = newValue as any[];

                setCheckAllOptions(newVar.length == options?.length);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                setCheckAllOptions(false)
            }}
            groupBy={(option) => option.group || ''}
            PopperComponent={(param) => {
                let boxProps = param as BoxProps;
                return (

                    <Popper   {...param}>
                        <Box {...boxProps} />
                        {showSelectAll && options.length > 0 && <>
                            <Divider/>
                            <Box
                                sx={{
                                    backgroundColor: "white",
                                    height: "45px",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap"
                                }}
                            >
                                <Card>
                                    <Checkbox
                                        checked={checkAllOptions}
                                        onClick={() => {
                                            const newValues = !checkAllOptions ? options : [];
                                            setValue(newValues)
                                            setCheckAllOptions(!checkAllOptions)
                                            onChange && onChange(newValues.map((v) => filterSelector == 'name' ? v.name : v.id).join('|'));
                                            onFullChange && onFullChange(newValues);
                                        }}
                                        id="check-all"
                                        sx={{marginRight: "8px"}}
                                        onMouseDown={(e) => e.preventDefault()}
                                    />
                                    Select All
                                </Card>
                            </Box>
                        </>}
                    </Popper>
                );
            }}
            getOptionLabel={(option: any) =>
                typeof option === "string" ? option : getLabel ? getLabel(option) : option.name
            }
            renderTags={(tagValue, getTagProps) => {
                //if disableAutocomplete is true, then we don't want to show the input
                /* if (disableAutocomplete) {
                     return <>{inputValue}</>
                 }*/

                return tagValue.sort((a, b) =>
                    hideOptionId
                        ? ((a.name > b.name) ? 1 : -1)
                        : ((a.id > b.id) ? 1 : -1))
                    .map((option, index) =>
                        <Chip {...getTagProps({index})}
                              label={getLabel
                                  ? getLabel(option)
                                  : hideOptionId
                                      ? option.name
                                      : `(${option.id}) ${option.name}`}/>);
            }}
            style={{width: '100%', minWidth: '200px', backgroundColor: 'white', padding: '0px'}}
            renderInput={(params) => {
                //if disableAutocomplete is true, then we don't want to show the input
                /*if (disableAutocomplete) {
                    return <>{inputValue}</>
                }*/

                return (
                    <StyledInput autoFocus={autoFocus} {...params}
                                 InputProps={{
                                     ...params.InputProps,
                                     endAdornment: endAdornment,
                                     classes: {
                                         adornedEnd: "pr-2"
                                     }
                                 }}
                                 variant="outlined"


                                 placeholder={placeHolder}
                                 onKeyDown={(event: any) => {
                                     if (event.key === 'Backspace') {
                                         event.stopPropagation();
                                     } else if (event.key === 'Enter') {
                                         onEnterDown && onEnterDown(event, inputValue, options.length);
                                     }
                                 }}/>
                );
            }}
            getOptionDisabled={(option) => showDisabled && option.disabled}
            renderOption={(props, option) => {


                return (
                    <li {...props}>
                        <Grid container alignItems="center">

                            <Grid item xs>
                                <Typography variant="body2" color="text.secondary">
                                    {getLabel
                                        ? <strong>{getLabel(option)}</strong>
                                        : <>
                                            {hideOptionId ? '' : `(${option.id}) `}
                                            <strong>{option.name}</strong></>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
            isOptionEqualToValue={(option, value) => option.id == value.id}
        />
    );
}


// see https://github.com/tannerlinsley/react-query/issues/293
// see https://usehooks.com/useDebounce/
export function useDebounce(value: unknown, delay: number) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}



