import * as React from 'react';
import {resetSearchQuery} from "../../../utils/ResetSearchQuery";
import {IoCloseOutline} from "react-icons/io5";
import {HtmlTooltip} from "../../forms/Tooltip";
import {DateTimePicker} from "../../DateTimePicker";
import {useEffect} from "react";

export default function SimpleDatePickerFilter({
                                                   initialFilterValue,
                                                   onChange,
                                                   isYearMonthMode = true
                                               }: { isYearMonthMode?: boolean, initialFilterValue?: any, onChange: (value: string) => void }) {
    const [value, setValue] = React.useState<string>(initialFilterValue || '');
    useEffect(() => {
        setValue(initialFilterValue || '');
    }, []);

    return (
        <div className="flex gap-2">
            <DateTimePicker
                label=""
                margin={'none'}
                dateInput={value ? new Date(value) : undefined}
                isYearMonthMode = {isYearMonthMode}
                onChange={item => {
                    setValue(item)
                    onChange(item)
                }}/>
            {value && <HtmlTooltip
                title={'Réinitaliser filtre'}>
                <button onClick={(event) => {
                    onChange('');
                    resetSearchQuery();
                    setValue('')
                }}><IoCloseOutline className="text-xl"/></button>
            </HtmlTooltip>}
        </div>
    );
}

