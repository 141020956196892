import {TableOptions} from "../FilteredTable";
import {SearchParamFilter} from "../../../utils/UseFullTable";
import * as React from "react";
import {InputAdornment, Switch} from "@mui/material";
import TextField from "@mui/material/TextField";
import MultiSelectFilterRemoteData, {MultiSelectFilterRemoteDataProps} from "./MultiSelectFilterRemoteData";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";

export function MultiSelectFilterRemoteDataWrapper<T>({props, options, searchBarParamsState}: {
    props: MultiSelectFilterRemoteDataProps,
    options: TableOptions<T>,
    searchBarParamsState?: SearchParamFilter<T>[],
}) {

    const {instance} = options;

    let targetColumn;
    if (searchBarParamsState && searchBarParamsState.length > 0) {
        const filter = searchBarParamsState[0];
        targetColumn = instance.getAllLeafColumns().find((r) => r.id === filter.freeTextField);
    }
    const [freeText, setFreeText] = React.useState<boolean>(false);
    const inputRef = React.useRef<any>(null);
    const handleKeyDown = React.useCallback(
        (event) => {
            if (event.key === 'Enter') {
                let value = inputRef.current?.value;
                if (targetColumn) {
                    const value1 = `@=${value ?? ''}`;
                    targetColumn?.setFilterValue(value1);
                }
            }
        },
        [props.onEnterDown]
    );
    React.useEffect(() => {
        const node = inputRef.current;
        if (node) {
            node.addEventListener('keydown', handleKeyDown);
            return () => {
                node.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [handleKeyDown]);


    const switch1 = <Tooltip
        title={ !freeText ? "Recherche libre" : "Recherche parmi les valeurs"}
        placement="top"
        children={<Switch checked={freeText}
                          onClick={() => {
                              setFreeText(!freeText);
                                if (targetColumn && freeText) {
                                    targetColumn?.setFilterValue('');
                                    inputRef.current.value = '';
                                }
                          }}
        />}
    />;
    return (
        <>
            {freeText ? <TextField
                    inputRef={inputRef}
                    label={props.title}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {switch1}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <>
                                    <div
                                        onClick={() => {
                                            let value = inputRef.current?.value;
                                            if (targetColumn) {
                                                const value1 = `@=${value ?? ''}`;
                                                targetColumn?.setFilterValue(value1);
                                            }
                                        }}
                                        className="cursor-pointer">
                                        <SearchIcon/>
                                    </div>

                                    <div
                                        onClick={() => {
                                            if (targetColumn) {
                                                targetColumn?.setFilterValue('');
                                                inputRef.current.value = '';
                                            }
                                        }}
                                        className="cursor-pointer"
                                    >
                                        X
                                    </div>


                                </>
                            </InputAdornment>
                        )
                    }}
                /> :
                <MultiSelectFilterRemoteData
                    endAdornment={targetColumn && switch1}
                    {...props}/>}</>
    )
}