import React from "react";
import {TableOptions} from "./FilteredTable";
import {Column, ColumnFilter, Row} from "@tanstack/react-table";
import {useViewConfigForRoute} from "../../dashboards/_common/UseViewConfigForRoute";
import {Chip, Stack} from "@mui/material";
import {FaFilter} from "react-icons/fa";
import {FiFilter} from "react-icons/fi";
import {HtmlTooltip} from "../forms/Tooltip";
import Button from "@mui/material/Button";
import {RiFilterOffFill} from "react-icons/ri";

export function FilterChip<T>({
                                  options,
                              }: {
    multiLineActions?: React.ReactNode,
    options: TableOptions<T>,
    children?: React.ReactNode;
    actions?: (props: { row: Row<T> }) => React.ReactElement
}) {
    const {
        instance,
        showAllRows,

    } = options;
    const viewConfiguration = useViewConfigForRoute();
    const fixedDefaultFilters = viewConfiguration?.fixedDefaultFilters ?? false;

    let columnFilters = options.filterOptions.columnFilters
        .filter(r => {
            return !(options.filterOptions.customFilter && Object.keys(options.filterOptions.customFilter).includes(r.id));
        })
        .filter(r => r.value !== null)
        .map((filter: ColumnFilter, index: number) => {
            const columns = instance.getAllLeafColumns().filter(f => f.id === filter.id);
            if (columns.length == 0) {
                return null;
            }

            const column = columns[0]
            if (!column.getIsVisible()) {
                return null;
            }

            if (!column.getCanFilter()) {
                return null;
            }
            return [column, filter]

        })
        .filter(r => r !== null);

    return (
        <>

            <Stack>
                <div className="flex gap-2 flex-col space-x-1">
                    {columnFilters
                        .map((data, index: number) => {

                            if (!data) return null;

                            const column = data[0] as Column<T>;
                            const filter = data[1] as ColumnFilter;

                            // @ts-ignore
                            const header = column?.columnDef?.header() || filter.id;
                            let operatorIcon = <></>
                            if (!showAllRows) {
                                const operatorValue = filter.value as string;
                                const operator = operatorValue?.substring(0, 2);
                                const value = operatorValue?.substring(2);
                                const boolFilter = value === 'true' ? true : value === 'false' ? false : null;
                                operatorIcon = (operator === "==" && boolFilter == null) || boolFilter == true
                                    ? <FaFilter size={20}/>
                                    : <FiFilter size={20}/>;
                            } else {

                                operatorIcon = filter.value != false
                                    ? <FaFilter size={20}/>
                                    : <FiFilter size={20}/>;
                            }

                            return <Chip icon={operatorIcon}
                                         label={header}
                                         size="small"
                                         key={index}
                                         className=""
                                         variant="outlined"
                                         onDelete={() => {
                                             column.setFilterValue(undefined);
                                             options.facets?.find(f => f.key === filter.id)?.onReset?.();
                                         }}/>
                        })}


                </div>
            </Stack>

            {!fixedDefaultFilters && columnFilters.length > 0 &&
                <HtmlTooltip title={"Supprimer les filtres"}>
                    <Button className="p-2 border shadow rounded cursor-pointer "
                            onClick={() => {
                                instance.getAllLeafColumns().forEach(f => {
                                    if (!options.defaultFilters || !options.defaultFilters.map(d => d.id).includes(f.id)) {
                                        f.setFilterValue(undefined)
                                    }
                                });
                            }}><RiFilterOffFill size={20}/></Button>
                </HtmlTooltip>}

        </>
    );
}
