//function to wrap call to postMessageToParent
import AppSettings, {DesktopUrl, DesktopUrlStaging} from "../common/services/AppSettings";
import {useEnvironment} from "../context/auth-context";
import {useFeatureFlagCheck} from "../dashboards/_common/UseFeatureFlagCheck";

export function postMessageToParent(message: any, postToStaging: boolean = false, targetOrigin: string = AppSettings.get(DesktopUrl)) {
    try {
        window.parent.postMessage(message, targetOrigin);
        if (postToStaging) {
            window.parent.postMessage(message, AppSettings.get(DesktopUrlStaging));
        }
    }
    catch (e) {
        console.log("error in postMessageToParent", e);
    }

}
