import {MdFileDownload} from "react-icons/md";
import React from "react";
import {saveAs} from "file-saver";
import {TableOptions} from "./table/FilteredTable";
import {TooltipButton} from "./tooltip-button";
import {useFilteringSortingPagination} from "../utils/UseFilteringSortingPagination";
import {toast} from "react-toastify";
import useAdminActions from "../utils/useAdminActions";
import {getBlob} from "../common/services/api/service";
import {useAdminActionConnectedStore} from "../stores/signalr";
import {FullPageSpinner} from "../dashboards/_common/lib";

export function ExportButton<T>(props: { options: TableOptions<T> }) {
    const {
        title,
        queryKey,
        filterOptions,
        keyField,
        instance,
        isExportAsynchronous
    } = props.options;

    let colToExport: string[] = [];

    instance.getAllLeafColumns()
        .filter(c => c.getIsVisible())
        .forEach((col) => {
            //get meta data
            const meta = col.columnDef.meta;
            const displayProperty = (meta as any)?.displayProperty;
            const excludedFromExport = (meta as any)?.excludedFromExport;
            if (displayProperty) {
                colToExport.push(displayProperty);
            }
            if (!excludedFromExport)
                colToExport.push(col.id);
        });

    const rows = props.options.instance.getSelectedRowModel().rows;

    const value = rows.map(r => {
        const originalElement = r.original[keyField];
        return originalElement;
    }).join('|');
    const newVar = {id: keyField.toString(), value: `==${value}`};
    const columnFilters = rows.length > 0 ? [...filterOptions.columnFilters, newVar] : filterOptions.columnFilters;
    const dataQueryExport = useFilteringSortingPagination({
        queryKey: `${queryKey}/export`,
        filterOptions: {
            ...filterOptions,
            columnFilters
        },
        selectedCols: colToExport,
        isExport: true,
        isExportAsynchronous: isExportAsynchronous
    });

    const [isLoading, setIsLoading] = React.useState(false);
    async function download() {
        isExportAsynchronous && setIsLoading(true)
        dataQueryExport.refetch({}).then((data: any) => {
            if (isExportAsynchronous) {
                toast.info(`Votre export ${title ?? ''} est en cours de génération et sera bientôt disponible.`);
            } else {
                const blob = new Blob([data.data], {type: "application/csv"});
                saveAs(blob, `Export_${title ?? ''}.csv`);
                toast.success(`${title ?? ''} exporté avec succès`);
            }
        })
    }

    useAdminActions(undefined, "exportGenerated", (result) => {
        getBlob(result.exportUrl).then((blob) => {
            saveAs(blob, `Export_${title ?? ''}.csv`);
            toast.success(`${title ?? ''} exporté avec succès`);
            setIsLoading(false)
        }, (error) => {
            toast.error(`Erreur lors de l'export de ${title ?? ''}`);
        })
    });

    const {connectedState} = useAdminActionConnectedStore();

    return (
        <TooltipButton
            label={'Télécharger'}
            disabled={isExportAsynchronous && (!connectedState.global || isLoading)}
            mutation={dataQueryExport}
            onClick={download}
            icon={isExportAsynchronous && (!connectedState.global || isLoading) ? <FullPageSpinner/> : <MdFileDownload size={20}/>}
            highlight={undefined}
            className="bg-transparent shadow border-0 text-white"/>
    )

}


