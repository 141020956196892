import create from "zustand";

export interface ConnectedState {
    [productId: number]: boolean,

    global?: boolean | null
}


type SignalRStore = {
    connectedState: ConnectedState;
    setConnectedState: (bidding: ConnectedState) => void;
};

export const useConnectedStore = create<SignalRStore>((set) => ({
    connectedState: {global: null},
    setConnectedState: (connectedState) => set(() => ({connectedState: connectedState})),
}));


//----------------------------------------
export interface AdminActionConnectedState {
    global?: boolean | null
}

type AdminActionSignalRStore = {
    connectedState: AdminActionConnectedState;
    setConnectedState: (state: AdminActionConnectedState) => void;
};

export const useAdminActionConnectedStore = create<AdminActionSignalRStore>((set) => ({
    connectedState: {global: null},
    setConnectedState: (connectedState) => set(() => ({connectedState: connectedState}))
}));
    