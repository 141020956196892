import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import React, {PropsWithChildren, useEffect, useState} from "react";
import {MdCheck, MdCheckBoxOutlineBlank, MdClose} from 'react-icons/md';
import {InputAdornment, MenuItem, Select} from '@mui/material';
import {IoCloseOutline} from "react-icons/io5";


export type metaOptions = { iconYes: JSX.Element, iconNo: JSX.Element }
export default function BooleanFilter({
                                          initialFilterValue,
                                          indeterminate,
                                          onChange,
                                          values,
                                          meta,
                                          children
                                      }: PropsWithChildren<{
    meta?: any,
    initialFilterValue: any,
    indeterminate?: boolean,
    onChange: (value: boolean | string | null) => void,
    values?: { value: boolean, label: string }[]
}>) {

    const [value, setValue] = useState(initialFilterValue?.toString() || '');

    useEffect(() => {
        setValue(initialFilterValue?.toString() || '');
    }, [initialFilterValue]);


    const metaOptions = meta || {iconYes: <MdCheck/>, iconNo: <MdClose/>}

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newValue: string,
    ) => {
        setValue(newValue);
        onChange(newValue == 'true' ? true : newValue == 'false' ? false : newValue == 'null' ? 'null' : null);
    };

    return values
        ? <Select value={value}
                  size={'small'}
                  fullWidth={true}
                  onChange={(e) => {
                      setValue(e.target.value as string);
                      onChange(e.target.value);
                  }}
                  endAdornment={value != '' &&
                      <InputAdornment position="end">
                          <button onClick={() => {
                              setValue('');
                              onChange(null);
                          }}><IoCloseOutline className="text-xl"/></button>
                      </InputAdornment>
                  }>
            <MenuItem key={'empty'} value={''}>&nbsp;</MenuItem>
            {values.map((v, i) => <MenuItem key={i} value={v.value.toString()}>{v.label}</MenuItem>)}
        </Select>
        : (indeterminate
                ? <div className={"flex gap-2 items-center"}>
                    <ToggleButtonGroup
                        color="secondary"
                        value={value}
                        exclusive
                        onChange={handleChange}>
                        <ToggleButton value="true">
                            <span className="flex items-center justify-center text-2xs"><MdCheck/></span>
                        </ToggleButton>
                        <ToggleButton value="false">
                            <span className="flex items-center justify-center text-2xs"><MdClose/></span>
                        </ToggleButton>
                        <ToggleButton value="null">
                            <span className="flex items-center justify-center text-2xs"><MdCheckBoxOutlineBlank/></span>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {children}
                </div>
                : <ToggleButtonGroup
                    color="secondary"
                    value={value}
                    exclusive
                    onChange={handleChange}>
                    <ToggleButton value="true">
                        <span className="flex items-center justify-center text-xl">{metaOptions.iconYes}</span>
                    </ToggleButton>
                    <ToggleButton value="false">
                        <span className="flex items-center justify-center text-xl">{metaOptions.iconNo}</span>
                    </ToggleButton>
                </ToggleButtonGroup>
        );
}


