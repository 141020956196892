import {TableOptions} from "./FilteredTable";
import React, {useEffect} from "react";
import {
    Pagination,
    PaginationItem,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import {makeStyles} from "@material-ui/core/styles";
import {Events, scroller} from 'react-scroll'


export type PaginationOptions = {
    position?: "left" | "center" | "end";
    size?: "medium" | "large" | "small" | undefined;
    itemIconRight?: any;
    itemIconLeft?: any;
    itemSelectedColor?: "blue-dark" | "blue-lightened-dark" | "blue-electric" | "blue-lightened-electric" | "blue-pastel" | "blue-light";
    itemColor?: "gray-medium";
    fontWeight?: number;
};

function getColorCode(color: string | undefined) {
    switch (color) {
        case 'blue-dark':
            return '#28418c';
        case 'blue-lightened-dark':
            return '#3d5cb7';
        case 'blue-electric':
            return '#1869ff';
        case 'blue-lightened-electric':
            return '#6396f6';
        case 'blue-pastel':
            return '#8bb4ff';
        case 'blue-light':
            return '#caeafb';
        case 'gray-medium':
            return '#5d5d5d';
        case 'white':
            return '#ffffff';
        default:
            return undefined;
    }
}


export function TablePaginationWrapper<T>({
                                              options,
                                              ranges = [10, 20, 50, 100, 200]
                                          }: {
    ranges?: number[],
    options: TableOptions<T>;
}) {
    const {instance, dataQuery} = options;

    const shouldIndexBe1 = options.useCustomPagination;

    const CustomRightIcon = () => <>{options.customPaginationOptions?.itemIconRight}</>;
    const CustomLeftIcon = () => <>{options.customPaginationOptions?.itemIconLeft}</>;
    const scrollBackToFirstRow = () => {
        let goToContainer = new Promise((resolve, reject) => {

            Events.scrollEvent.register('end', () => {
                resolve({});
                Events.scrollEvent.remove('end');
            });

            scroller.scrollTo('scroll-container', {
                duration: 200,
                delay: 0,
                smooth: 'easeInOutQuart'
            });

        });

        goToContainer.then(() =>
            scroller.scrollTo('scroll-element-0-0', {
                duration: 200,
                delay: 0,
                smooth: 'easeInOutQuart',
                containerId: 'scroll-container',
                offset: -150
            }));
    }

    const useStyles = makeStyles(() => ({
        override: {
            "& .Mui-selected": {
                background: "transparent",
                color: `${getColorCode(options.customPaginationOptions?.itemSelectedColor)} !important`,
                border: `2px solid ${getColorCode(options.customPaginationOptions?.itemSelectedColor)}`
            },
            "& .Mui-selected.Mui-selected:hover": {
                background: getColorCode(options.customPaginationOptions?.itemSelectedColor),
                color: "#ffffff !important",
                border: `2px solid ${getColorCode(options.customPaginationOptions?.itemSelectedColor)}`
            },
            "& .MuiTablePagination-displayedRows": {
                color: getColorCode("white"),

            },
            "& .MuiTablePagination-actions": {
                color: getColorCode("white"),
            },
            "& .MuiTablePagination-root": {
                width: "100%",
            },
            //MuiTablePagination-selectLabel
            "& .MuiTablePagination-selectLabel": {
                color: getColorCode("white"),
            },
            "& .MuiInputBase-root": {
                color: getColorCode("white"),
            },
            "& .MuiTablePagination-selectIcon": {
                color: getColorCode("white"),
            }

        }
    }));

    const classes = useStyles();


    const [page, setPage] = React.useState<number>(shouldIndexBe1 ? dataQuery?.data?.currentPage : dataQuery?.data?.currentPage - 1 ?? 0);

    useEffect(
        () => setPage(shouldIndexBe1 ? dataQuery?.data?.currentPage : dataQuery?.data?.currentPage - 1),
        [dataQuery?.data?.currentPage]
    );
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const {data: _data, ...targetProps} = dataQuery;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
        instance.setPageIndex(newPage);
        scrollBackToFirstRow();
    };

    const handleChangePageForCustomPagination = (
        event: React.ChangeEvent<unknown>,
        newPage: number
    ) => {
        setPage(newPage);
        instance.setPageIndex(newPage - 1);
        scrollBackToFirstRow();
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        instance.setPageSize(Number(event.target.value));
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <>
            {instance.getRowModel()?.rows.length > 0 &&
                !options.hidePagination &&
                !options.showAllRows && (
                    <>
                        {options.useCustomPagination ? (
                            <Pagination
                                count={dataQuery?.data?.pageCount ?? 10}
                                page={dataQuery?.data?.currentPage ?? 1}
                                color="primary"
                                size={options.customPaginationOptions?.size}
                                className={`flex justify-${options.customPaginationOptions?.position ?? "center"} ${classes.override}`}
                                onChange={handleChangePageForCustomPagination}
                                renderItem={(item) => (
                                    <PaginationItem
                                        components={{previous: CustomLeftIcon, next: CustomRightIcon}}
                                        {...item}
                                    />
                                )}
                            />
                        ) : (
                            <div className="">
                                <TablePagination
                                    showFirstButton={false}
                                    showLastButton={false}
                                    classes={{root: classes.override}}
                                    count={dataQuery?.data?.rowCount ?? 20}
                                    page={page}
                                    rowsPerPageOptions={ranges ?? []}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={dataQuery?.data?.pageSize ?? 20}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        )}
                    </>
                )}
            {
                instance.getRowModel()?.rows.length > 0 && options.showAllRows && (
                    <div className="flex justify-center">
                        {instance.getRowModel()?.rows.length} lignes
                    </div>)
            }
        </>
    );
}
