import {TableOptions} from "./FilteredTable";
import React from "react";
import {
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import {DraggableColumnHeader} from "./DraggableColumnHeader";
import {styled} from "@mui/material/styles";

const TRow = styled(TableRow)(() => (
    {
        height: '7rem',
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 10px"
    }));

export function TableHeadWrapper<T>({
                               options, multiLineActions
                           }: { options: TableOptions<T>, multiLineActions?: React.ReactNode }) {
    const {instance, dataQuery} = options;

    return (<>
            {!options.hideHeader && <TableHead className="bg-[#F0F8FF]">
                {instance.getHeaderGroups().map((headerGroup: { id: React.Key | null | undefined; headers: any[]; }) => (
                    <TableRow sx={{
                        "& th": {
                            fontSize: "1rem",
                            color: "rgba(96, 96, 96)",
                        }
                    }} key={headerGroup.id} className="bg-[#F0F8FF] border-b-[#e0e0e0]">
                        { multiLineActions && instance.getSelectedRowModel().rows.length >= 1 && options.multilineActionsOnHeader ?
                            <>
                                <DraggableColumnHeader
                                    key={headerGroup.headers[0].id}
                                    header={headerGroup.headers[0]}
                                    options={options}
                                    table={instance}
                                />
                                <TableCell  style={{backgroundColor: "aliceBlue"}} colSpan={headerGroup.headers.length - 1}>

                                    <div className="flex justify-between items-center">
                                        {multiLineActions}
                                        <span className="text-lg font-bold">{instance.getIsAllRowsSelected() ? dataQuery?.data?.rowCount : instance.getSelectedRowModel().rows.length } selected</span>
                                    </div>
                                </TableCell>
                            </>
                            : headerGroup.headers.map((header) => (
                                <DraggableColumnHeader
                                    key={header.id}
                                    header={header}
                                    options={options}
                                    table={instance}
                                />
                            ))
                        }
                    </TableRow>
                ))}
            </TableHead>}

        </>
    );
}
