import React, { useEffect, useState } from "react";
import htmlToDraft from "html-to-draftjs";
import Draft, { ContentState, convertToRaw, EditorState } from "draft-js";
import { Card, InputLabel } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import FormControl from "@mui/material/FormControl";

export function RichTextEditor({
    html,
    onChange,
    isEditable = true,
    legacyStyle = false,
    maxHeight,
    isTwoWay = true,
    customCompontent
}: {
    customCompontent?: JSX.Element,
    isTwoWay?: boolean,
    isEditable?: boolean,
    html: any,
    onChange: (html: string) => void,
    legacyStyle?: boolean,
    maxHeight?: string
}) {
    const [editorState, setEditorState] = useState<any>(null);
    const [editedHtml, setEditedHtml] = useState<any>('');
    const [, setTouched] = React.useState<boolean>(false);

    function extracted() {
        const blocksFromHtml = htmlToDraft(html ?? '');
        const { contentBlocks, entityMap } = blocksFromHtml;
        const template = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(template));
        setEditedHtml(html ?? '');
    }

    const initialezed = React.useRef(false);

    useEffect(() => {
        if (!initialezed.current && html) {
            extracted();
            if(editorState)
            {
                setEditorState(EditorState.moveFocusToEnd(editorState));
            }
            initialezed.current = true;
        }
        if (isTwoWay) {
            extracted();
        }
    }, [html]);

    const cloneElement = customCompontent ? React.cloneElement(customCompontent!, {
        onChange: (value) => {
            const currentContent = editorState.getCurrentContent();
            const selection = editorState.getSelection();
            const entityKey = Draft.Entity.create('PLACEHOLDER', 'IMMUTABLE', { label: value.target.value });
            const enriched = '{{context.' + value.target.value + '}}';

            const textWithEntity = Draft.Modifier.insertText(currentContent, selection, enriched, undefined, entityKey);

            setEditorState(EditorState.push(editorState, textWithEntity, 'insert-characters'));
        }
    }) : null;

    return <Card>
        {cloneElement ? <FormControl className={"w-full"}>
            <InputLabel>Insert variable</InputLabel>
            {cloneElement}
        </FormControl> : null}
        <Editor editorState={editorState}
            handlePastedText={() => false}
            editorStyle={{ minHeight: '200px', maxHeight: maxHeight ?? '500px', paddingInline: '15px' }}
            toolbarStyle={{
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}
            toolbar={legacyStyle ? {
                options: ['inline', 'colorPicker'],

                inline: { options: ['bold'] }
            } : undefined}

            onEditorStateChange={(editorState) => {
                const s = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                setEditedHtml(s);
                setEditorState(editorState);
                setTouched(true);
                onChange(s);
            }}
            wrapperClassName="rdw-editor-wrapper"
            editorClassName="rdw-editor-main"
            toolbarClassName="rdw-editor-toolbar"
            readOnly={!isEditable}
        />
    </Card>;
}