import {TableOptions} from "./FilteredTable";
import {Row} from "@tanstack/react-table";
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {ActionWrapper} from "./ActionWrapper";
import {toast} from "react-toastify";
import {TooltipButton} from "../tooltip-button";
import {FaCopy} from "react-icons/fa";
import {CopyToClipboard} from "react-copy-to-clipboard";

export function FilterTableContextMenu<T>({
                                              options,
                                              actions,
                                              contextMenu, setContextMenu
                                          }: {
    options: TableOptions<T>,
    actions?: (props: { row: Row<T> }) => React.ReactElement,
    contextMenu: { mouseX: number; mouseY: number } | null,
    setContextMenu: (value: { mouseX: number; mouseY: number } | null) => void
}) {
    const {
        instance,
        rowElement

    } = options;


    const handleClose = () => {
        setContextMenu(null);
    };

    return (
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? {
                        top: contextMenu.mouseY,
                        left: contextMenu.mouseX
                    }
                    : undefined
            }
            onKeyDownCapture={(e) => {
                if (e.code === 'KeyQ') {
                    e.stopPropagation();
                }
            }}
        >
            <MenuItem>
                {actions && instance.getSelectedRowModel().rows.length == 1 &&
                    <div>
                        <div>
                            {rowElement && `Actions sur ${rowElement(instance.getSelectedRowModel().rows[0].original)}`}
                        </div>
                        <div className="flex gap-2">
                            {actions({row: instance.getSelectedRowModel().rows[0]})}
                            <ActionWrapper actionId={"copyToClipBoard"}><CopyToClipboard
                                onCopy={() => toast.success('Copié dans le presse-papier')}
                                text={options.rowElement && options.rowElement(instance.getSelectedRowModel().rows[0].original)}
                            >
                                <TooltipButton
                                    onClick={() => {
                                    }}
                                    label='Copier identifiant dans le presse papier'
                                    icon={<FaCopy/>}
                                    highlight={undefined}
                                    mutation={undefined}/>
                            </CopyToClipboard></ActionWrapper>
                        </div>
                    </div>}

            </MenuItem>

        </Menu>
    );
}
