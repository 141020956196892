import { AdministratorService } from "./interfaces/AdministratorService";

/**
 * Service helper allowing to store application settings
 */
class AppSettings {
    settings: any;

    constructor() {
        this.settings = {};
    }

    /**
     * Store all properties of the object in parameter in the AppSettings
     * @param {*} obj - key/value object of application settings
     */
    setObject(obj: { [x: string]: any; }) {
        for(var propertyName in obj) {
            this.set(propertyName, obj[propertyName]);
        }
        return this;
    }

    set(name: string, value: any) {
        this.settings[name] = value;
        return this;
    }

    get(name: string) { return this.settings[name]; }

    setCulture(value: any) {
        this.settings['culture'] = value;
        return this;
    }

    getCulture() { return this.settings['culture']; }

    setAgorastoreApiUrl(value: any) {
        this.settings['agorastoreApiUrl'] = value;
        return this;
    }


    getAdminServiceIds(): AdministratorService[] {
        const user = this.get(UserKey);
        if(!user) {
            return [];
        }

        return user.serviceIds || [];
    }

    getAgorastoreApiUrl() { return this.settings['agorastoreApiUrl']; }

    setSignalRHubUrl(value: string): AppSettings {
        this.settings['signalRHubUrl'] = value;
        return this;
    }

    getSignalRHubUrl(): string {
        return this.settings['signalRHubUrl'];
    }
}

export default (new AppSettings());

export const SiteIdKey = 'siteId'; // site the user is logged on
export const UserKey = 'user'; // information on the logged on user
export const EmbeddedKey = 'embedded'; // information on the logged on user
export const AzureCdnUrlKey = 'azureCdnUrl';
export const AzureBlobStorageUrlKey = 'azureBlobStorageUrl';
export const ApiUrlNew = 'BO_NEW_API_URL';
export const BoUrl = "BO_URL"
export const DesktopUrl = "MP_URL"
export const DesktopUrlStaging = "MP_URL_STAGING"

export const OauthAccessTokenKey = 'OauthAccessToken';
export const OauthAccessTokenExpirationKey = 'OauthAccessTokenExpirationKey';

export const GoogleRecaptchaSiteKey = "GoogleRecaptchaSiteKey"
