import {AppBar, Card, CardContent, FormControlLabel, Switch, Toolbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {Row} from "@tanstack/react-table";
import {TooltipButton} from "../tooltip-button";
import {ExportButton} from "../ExportButton";
import React from "react";
import {TableOptions} from "./FilteredTable";
import {useViewConfigForRoute} from "../../dashboards/_common/UseViewConfigForRoute";
import {TableColumnSelectMenu} from "./TableColumnSelectMenu";
import {useConfig} from "context/auth-context";
import MoreMenu from "./MoreMenu";
import {TablePaginationWrapper} from "./TablePaginationWrapper";
import {IoMdRefresh} from "react-icons/io";
import {EmojiDisplay} from "sidebar/StyledMenuItem";

export function TableMenuBar<T>({
                                    options,
                                    children,
                                    multiLineActions
                                }: {
    multiLineActions?: React.ReactNode,
    options: TableOptions<T>,
    children?: React.ReactNode;
    actions?: (props: { row: Row<T> }) => React.ReactElement
}) {
    const {
        instance,
        title,
        emojis

    } = options;

    const viewConfiguration = useViewConfigForRoute();
    const hideMultiLineActions = viewConfiguration?.hideMultiLineActions ?? false;
    const {config} = useConfig();
    const hasMultiLinaActions = !hideMultiLineActions && multiLineActions && instance.getSelectedRowModel().rows.length >= 1;
    const className = config.isBoLegacy ? "bg-blue-dark-legacy" : "bg-blue-dark";
    const hasNoRows = instance.getRowModel()?.rows.length == 0;
    return (

        <AppBar className={className} position="static">
            <Toolbar variant="dense" className="flex items-center justify-between">

                {!hasMultiLinaActions && <div className="flex gap-3 justify-center">
                    <span
                        className={"shrink w-48 whitespace-nowrap overflow-hidden overflow-ellipsis lg:w-full"}> <div className={"flex gap-2 items-center"}>
                        <EmojiDisplay
                            emojis={emojis}/>
                        {title}
                    </div> </span>
                </div>}

                {hasMultiLinaActions &&
                    <div className="">
                        {multiLineActions}
                    </div>}

                {!hasMultiLinaActions &&
                    <div className="flex items-center">
                        {<>
                            {!options.hideActions && <Box className="flex flex-col align-middle justify-center">
                                {children}
                            </Box>}

                            {<MoreMenu options={options}/>}
                        </>}
                        <div className={"hidden md:flex items-center px-4"}>
                            <TableColumnSelectMenu
                                key={`column-select-${options.queryKey}`}
                                options={options} table={instance}/>
                            <TooltipButton key={`tooltip-${options.queryKey}`}
                                data-testid={`refresh-button-${options.queryKey}`}
                                label={'Actualiser'}
                                mutation={options.dataQuery}
                                onClick={async () => {
                                    await options.dataQuery.refetch({})
                                }}
                                icon={<IoMdRefresh className={"text-white"} size={20}/>}
                                highlight={undefined}
                                className="bg-transparent shadow border-0 text-white">
                            </TooltipButton>
                            <ExportButton options={options}/>
                        </div>
                        {!hasNoRows && <div className="shrink-0"><TablePaginationWrapper options={options}/></div>}

                    </div>}

            </Toolbar>
        </AppBar>
    );
}


export default TableMenuBar;


