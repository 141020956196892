// A debounced input react component
import React from "react";
import {resetSearchQuery} from "../../../utils/ResetSearchQuery";
import {IoCloseOutline} from "react-icons/io5";
import {HtmlTooltip} from "../../forms/Tooltip";

export function DebouncedInput({
                                   value: initialValue,
                                   onChange,
                                   debounce = 500,
                                   ...props
                               }: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)


    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <div className="flex gap-2">
            <input type="search" {...props} value={value} onChange={e => setValue(e.target.value)} className={"w-full rounded border-gray-300"}/>
            {value && <HtmlTooltip
                title={'Réinitaliser filtre'}>
                <button onClick={(event) => {
                    setValue('');
                    onChange('');
                }}><IoCloseOutline className="text-xl"/></button>
            </HtmlTooltip>}
        </div>
    )
}
