import {post, postAnonymous} from "./common/services/api/service";

const localStorageKey = 'accessToken'
const localStorageKey2 = 'refreshToken'

async function getToken() {
    try {
        return window.localStorage.getItem(localStorageKey)
    } catch (e) {
        window.localStorage.removeItem(localStorageKey);
    }
}

// @ts-ignore
function handleUserResponse({data: {accessToken, refreshToken}}) {
    window.localStorage.setItem(localStorageKey, accessToken.token)
    window.localStorage.setItem(localStorageKey2, refreshToken.token)
    return accessToken.token
}

function login({username, password}: { username: string, password: string }) {
    // @ts-ignore
    return postAnonymous('/login', {login: username, password}).then(handleUserResponse);
}

function impersonateUser(token: any) {
    // @ts-ignore
    window.localStorage.removeItem(localStorageKey)
    window.localStorage.removeItem(localStorageKey2)
    handleUserResponse({data: {accessToken: token.accessToken, refreshToken: token.refreshToken}})
}

function register({username, password}: { username: string, password: string }) {
    return client('register', {username, password}).then(handleUserResponse)
}

async function logout() {
    try{
        window.localStorage.removeItem(localStorageKey)
        window.localStorage.removeItem(localStorageKey2)
    }catch(e){
        console.log(e);
    }
   
}

// an auth provider wouldn't use your client, they'd have their own
// so that's why we're not just re-using the client
const authURL = process.env.REACT_APP_AUTH_URL

async function client(endpoint: string, data: { username: string; password: string; }) {
    const config = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'},
    }

    return window.fetch(`${authURL}/${endpoint}`, config).then(async response => {
        const data = await response.json()
        if (response.ok) {
            return data
        } else {
            return Promise.reject(data)
        }
    })
}

export {getToken, login, register, logout, localStorageKey, impersonateUser}
