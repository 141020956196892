import create from "zustand";
import {ISite} from "../common/services/interfaces/Site";

type LayoutStore = {
   size: number;
   width: number;
   setSize: (size: number, width: number) => void;
};

export const useLayoutStore = create<LayoutStore>((set) => ({
    size: 0,
    width: 0,
    setSize: (size, width) => set(() => ({size: size, width: width})),
}));
