import {useLiveConnectionInternal} from "./LiveConnection";
import {useEffect, useState} from "react";
import {datadogLogs} from "@datadog/browser-logs";
import {useAuth} from "../context/auth-context";
import {useAdminActionConnectedStore} from "../stores/signalr";

export default function useAdminActions(onMessage?: () => void, methodName?: string, newMethod?: (result: any) => void) {
    const {connectedState, setConnectedState} = useAdminActionConnectedStore();
    const [localState, setLocalState] = useState<boolean | undefined>(undefined);
    const {connected, connectionRef} = useLiveConnectionInternal("admin-actions", message => {
        setLocalState(false)
    });
    const {user} = useAuth();

    useEffect(() => {
        setConnectedState({...connectedState, global: localState})
    }, [localState])

    useEffect(() => {
        if (connected) {
            if (onMessage) {
                connectionRef.current?.on("demandeAutorisationAdded", () => {
                    onMessage()
                })
            }

            if (methodName && user) {
                connectionRef.current?.invoke("Subscribe", user.id)
                    .then(() => {
                        setLocalState(true)

                        connectionRef.current?.on(methodName, (result: any) => {
                            newMethod && newMethod(result);
                        })
                    })
                    .catch((err: any) => {
                    })
            }
        }
    }, [connected])
}