export const readStorage = (space: string, keyName: string) => {
  if (typeof Storage !== 'undefined') return localStorage.getItem(`${space}::${keyName}`) || '';
  else {
    console.warn('Unable to read from localStorage. localStorage not defined');
    return '';
  }
};
export const writeStorage = (space: string, keyName: string, value: string) => {
  if (typeof Storage !== 'undefined') localStorage.setItem(`${space}::${keyName}`, value);
  else console.warn('Unable to write into localStorage. localStorage not defined');
};
export const clearStorage = (space: string, keyName: string) => {
  if (typeof Storage !== 'undefined') localStorage.removeItem(`${space}::${keyName}`);
  else console.warn('Unable to clear localStorage. localStorage not defined');
};
