import * as React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect} from "react";
import {StyledInput} from "./MultiSelectFilterRemoteData";
import {Box, BoxProps, Card, Checkbox, Divider, Popper} from "@mui/material";

export type MultiSelectFilterProps = {
    id: number,
    name: string,
    width?: string,
    group?: string,
    icon?: JSX.Element,
    styles?: any
};

export default function MultiSelectFilter({
                                              options,
                                              onChange,
                                              title,
                                              initialFilterValue,
                                              filterSelector,
                                              disabled = false,
                                              readonly = false,
                                              freeSolo = false,
                                          }: {
    freeSolo?: boolean,
    initialFilterValue: [] | unknown,
    filterSelector: 'id' | 'name',
    title: string,
    options: MultiSelectFilterProps[],
    onChange: (value: string) => void,
    disabled?: boolean,
    readonly?: boolean
}) {

    const [value, setValue] = React.useState<any[]>(initialFilterValue
        ? Array.isArray(initialFilterValue)
            ? initialFilterValue
            : [{id: 0, name: initialFilterValue}]
        : []);

    useEffect(() => {
        if (!initialFilterValue) setValue([]);
    }, [initialFilterValue]);

    return (
        <Autocomplete
            disabled={disabled}
            freeSolo={freeSolo}
            readOnly={readonly}
            multiple
            size="small"
            limitTags={1}
            id="fixed-tags-demo"
            value={value}
            onChange={(event, newValue) => {
                setValue([...newValue]);
                onChange(newValue.map((v: any) => {
                    const newVar = filterSelector == 'name' ? v.name : v.id;
                    return newVar ?? v;
                }).join('|'));
            }}
            options={options}

            PopperComponent={(param) => {
                let boxProps = param as BoxProps;
                return (
                    <Popper   {...param}>
                        <Box {...boxProps} />
                    </Popper>
                );
            }}


            groupBy={(option: MultiSelectFilterProps) => option.group || ''}
            getOptionLabel={(option: any) => option.name ?? option}
            renderTags={(tagValue, getTagProps) =>
                tagValue.sort((a, b) => (a.name > b.name) ? 1 : -1).map((option, index) => (
                    <Chip
                        label={option.name ?? option}
                        {...getTagProps({index})}
                    />
                ))
            }
            style={{ minWidth: '200px', backgroundColor: 'white', padding: '0px'}}
            renderInput={(params) => (
                <StyledInput {...params} label={title} placeholder={title}/>
            )}
            isOptionEqualToValue={(option, value) => option.id == value.id || option.name == value.name}
        />
    );
}

