import {useQuery} from "react-query";
import {get} from "../common/services/api/service";
import AppSettings, {DesktopUrl, DesktopUrlStaging} from "../common/services/AppSettings";
import {useEnvironment} from "../context/auth-context";
import {useFeatureFlagCheck} from "../dashboards/_common/UseFeatureFlagCheck";

export function useAdditionalParentToPost() {
    const {environment} = useEnvironment();

    if (environment == 'production') {
        return false;
/*
        const stagingActivated = useFeatureFlagCheck('STAGING_MESSAGING');
*/
        /*if (stagingActivated) {
           return true;
        }*/
    }
}
