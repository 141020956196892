import React from 'react';
import img500 from '../../images/error/500.png';
import {Button} from "../../components/stories/Button/Button";
import {useNavigate} from "react-router-dom";

export type Error500Props = {
    visible?:boolean;
    message?:string;
    stack?:string;
}

const Error500Page = (props:Error500Props) => {
    return (<div className="flex flex-col justify-center items-center px-6 mx-auto h-screen xl:px-0 ">
    {/*<div className="block md:max-w-lg">*/}
    {/*    <img  src={img500} alt="astronaut image"/>*/}
    {/*</div>*/}
    <div className="text-center xl:max-w-4xl">
        <h1 className="mb-3 text-2xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl ">Page temporairement indisponible. </h1>
        {<p className="mb-5 text-base font-normal text-gray-500 md:text-lg ">Veuillez réessayer dans quelques instants.</p> }
        {props.visible && props.message && props.stack &&
            <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                 role="alert">
                <span className="font-bold block">{props.message}</span>
                <span className="italic flex-wrap">{props.stack}</span>
            </div>
        }
        <Button onClick={() => {
            window.location.href = "/";
        }}>
           Retournez à l'accueil 
        </Button>
    </div>
</div>)
}
export default Error500Page;