import { Column } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { DebouncedInput } from "./DebouncedInput";
import MultiSelectFilter from "./MultiSelectFilter";
import { FacetOptions, TableOptions } from "../FilteredTable";
import BooleanFilter from "./ButtonFilter";
import SimpleDateRangeFilter from "./SimpleDateRangeFilter";
import OperatorMenu from "./OperatorMenu";
import ButtonToggleFilter from "./ButtonToggleFilter";
import SimpleDatePickerFilter from "./SimpleDatePickerFilter";
import _ from "lodash";
import { SearchParamFilter } from "../../../utils/UseFullTable";
import { MultiSelectFilterRemoteDataWrapper } from "./MultiSelectFilterRemoteDataWrapper";

export function Filter<T>({
  column,
  facets,
  options,
  onChange,
  hideOperatorMenu,
  autoFocus,
}: {
  autoFocus?: boolean;
  hideOperatorMenu?: boolean;
  column: Column<any, any>;
  facets: FacetOptions<T>[] | null;
  options: TableOptions<T>;
  onChange: (value: string | number | any[]) => void;
  onOperatorChange: (value: string | number | any[]) => void;
}) {
  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      Array.from(column.getFacetedUniqueValues().keys())
        .sort()
        .filter((r) => r),
    [column.getFacetedUniqueValues()]
  );

  // @ts-ignore
  const header = column.columnDef.header();
  const [operator, setOperator] = useState("==");
  const [filtereValue, setFiltereValue] = useState("");
  const [searchBarParamsState, setSearchBarParamsState] = useState<
    SearchParamFilter<T>[] | undefined
  >(undefined);
  const [filterRange, setFilterRange] = useState({ min: "", max: "" });
  const facetOptions = facets?.find((f) => f.key === column.id);

  const applyCombinedFilters = () => {
    const minPart = filterRange.min ? `${filterRange.min}` : "noval";
    const maxPart = filterRange.max ? `${filterRange.max}` : "noval";

    let filterValue = `==${minPart}|${maxPart}`;

    if (filterRange.min || filterRange.max) {
      column.setFilterValue(`${filterValue}`);
      onChange(`${filterValue}`);
    }
  };

  useEffect(() => {
    applyCombinedFilters();
  }, [filterRange]);

  // const defe= facetOptions.operator
  useEffect(() => {
    if (facetOptions?.operator) {
      setOperator(facetOptions?.operator);
    }
    if (facetOptions?.searchParamFilters) {
      setSearchBarParamsState(facetOptions?.searchParamFilters);
    }
  }, [facetOptions?.operator]);
  const columnFilters = options?.filterOptions?.columnFilters;
  const operators = ["!_-=", "_-=", "!_=", "_=", "!=", "==", "!@=", "@="];

  let valuesForFilter = columnFilters?.find((f) => f.id === column.id)?.value;

  //check is valuesForFilter is a boolean
  if (typeof valuesForFilter === "boolean") {
    valuesForFilter = valuesForFilter.toString();
  }

  const valuesForFilter1 = (valuesForFilter as unknown as string) ?? "";

  const containsOperator =
    _.findIndex(operators, (value) => {
      return valuesForFilter1 ? valuesForFilter1.startsWith(value) : false;
    }) !== -1;
  valuesForFilter = containsOperator
    ? valuesForFilter1?.toString().substring(2)
    : valuesForFilter;

  const getOnChange = (value, op: string | null = null) => {
    if (value === "" || value === undefined || value === null) {
      column.setFilterValue(undefined);
    } else {
      let newVar = options.showAllRows
        ? value
        : `${op ?? operator}${value ?? ""}`;
      column.setFilterValue(newVar);
    }
    onChange(value);
    setFiltereValue(value);
  };

  useEffect(() => {
    if (
      options.defaultLocalFilters &&
      options.defaultLocalFilters.find((f) => f.id === column.id)
    ) {
      const value1 = options.defaultLocalFilters.find((f) => f.id === column.id)
        ?.value as unknown;
      getOnChange(value1);
    }
  }, [options.defaultLocalFilters, column]);

  const operatorVal = containsOperator
    ? (columnFilterValue as any)?.substring(0, 2)
    : undefined;

  return (
    <>
      {facets && facetOptions && facetOptions.component ? (
        <div className="flex">
          {facetOptions.component === "MultiSelectFilter" &&
          facetOptions!.values ? (
            <div className="flex w-full">
              <MultiSelectFilter
                filterSelector={facetOptions.selector || "name"}
                initialFilterValue={valuesForFilter}
                title={header}
                options={facetOptions!.values}
                onChange={getOnChange}
              />
              {!hideOperatorMenu && (
                <OperatorMenu
                  filterType={facetOptions.component}
                  initialValue={operatorVal}
                  onChange={(e) => {
                    setOperator(e);
                    column.setFilterValue(`${e}${filtereValue}`);
                  }}
                />
              )}
            </div>
          ) : null}

          {facetOptions.component === "MultiSelectFilterRemoteData" ? (
            <div className="flex w-64">
              <MultiSelectFilterRemoteDataWrapper
                searchBarParamsState={searchBarParamsState}
                options={options}
                props={{
                  filterSelector: facetOptions.selector || "name",
                  initialFilterValue: valuesForFilter,
                  title: header,
                  onChange: getOnChange,
                  url: facetOptions.url!,
                  additionalQueryParameters:
                    facetOptions.additionalQueryParameters,
                  width: "250px",
                  disableCloseOnSelect: facetOptions.disableCloseOnSelect,
                  hideOptionId: facetOptions.hideOptionId,
                  onFullChange: (values) => {
                    facetOptions.onSelection &&
                      facetOptions.onSelection(values);
                  },
                  placeHolder: facetOptions.placeHolder ?? header,
                }}
              />
              {!hideOperatorMenu && (
                <OperatorMenu
                  filterType={facetOptions.component}
                  initialValue={operatorVal}
                  onChange={(e) => {
                    setOperator(e);
                    column.setFilterValue(`${e}${filtereValue}`);
                  }}
                />
              )}
            </div>
          ) : null}

          {facetOptions.component === "BooleanToggle" ? (
            <div className="flex w-20">
              <BooleanFilter
                initialFilterValue={valuesForFilter}
                onChange={getOnChange}
                indeterminate={facetOptions.indeterminate}
                meta={facetOptions.meta}
                values={facetOptions.booleanValues}
              />
            </div>
          ) : null}

          {facetOptions.component === "ButtonToggleFilter" ? (
            <ButtonToggleFilter
              options={facetOptions!.values}
              initialFilterValue={valuesForFilter}
              onChange={getOnChange}
            />
          ) : null}

          {facetOptions.component === "SimpleDateRangeFilter" ? (
            <SimpleDateRangeFilter
              initialFilterValue={valuesForFilter}
              title={column.id}
              options={options}
              onChange={getOnChange}
            />
          ) : null}

          {facetOptions.component === "SimpleDatePickerFilter" ? (
            <SimpleDatePickerFilter
              initialFilterValue={valuesForFilter}
              onChange={getOnChange}
            />
          ) : null}

          {facetOptions.component === "SimpleNumericFilter" ? (
            <div className={"flex gap-2"}>
              <DebouncedInput
                type="number"
                style={{ width: "100%", minWidth: "115px" }}
                onChange={(val) => {
                  const prefixes = [">", "<", ">=", "<="];
                  const prefix = prefixes.find((e) =>
                    val?.toString().startsWith(e)
                  );
                  if (prefix) {
                    getOnChange(val?.toString().replace(prefix!, ""), prefix);
                  } else {
                    getOnChange(val, operator);
                  }
                }}
                value={
                  ((columnFilterValue as string)?.replace("==", "") ??
                    "") as string
                }
                className="w-full h-10 border border-gray-300 shadow rounded text-sm"
                list={column.id + "list"}
              />
              <OperatorMenu
                filterType={facetOptions.component}
                initialValue={operatorVal}
                onChange={(e) => {
                  setOperator(e);
                  column.setFilterValue(`${e}${filtereValue}`);
                }}
              />
            </div>
          ) : null}

          {facetOptions.component === "NumericFilter" ? (
            <>
              <div className="flex flex-row gap-2">
                <DebouncedInput
                  type="number"
                  style={{ width: "100%", minWidth: "115px" }}
                  value={filterRange.min}
                  onChange={(val) => {
                    setFilterRange((prev) => ({ ...prev, min: String(val) }));
                  }}
                  placeholder={`min`}
                  className="w-full h-10 border border-gray-300 shadow rounded text-sm"
                />

                <DebouncedInput
                  type="number"
                  style={{ width: "100%", minWidth: "115px" }}
                  value={filterRange.max}
                  onChange={(val) => {
                    setFilterRange((prev) => ({ ...prev, max: String(val) }));
                  }}
                  placeholder={`max`}
                  className="w-full h-10 border border-gray-300 shadow rounded text-sm"
                />
              </div>
            </>
          ) : null}
        </div>
      ) : (
        <div className="flex w-full">
          <MultiSelectFilter
            filterSelector={"name"}
            freeSolo={true}
            initialFilterValue={valuesForFilter}
            title={header}
            options={sortedUniqueValues.slice(0, 5000).map((value) => ({
              name: value,
              id: value,
            }))}
            onChange={getOnChange}
          />

          {!hideOperatorMenu && (
            <OperatorMenu
              initialValue={operatorVal}
              onChange={(e) => {
                setOperator(e);
                column.setFilterValue(`${e}${filtereValue}`);
              }}
            />
          )}
        </div>
      )}
    </>
  );
}

export default Filter;
