import React from 'react';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import {Dialog, DialogTitle, Grid, IconButton, Typography} from '@mui/material';
import {AiOutlineClose} from "react-icons/ai";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const ConfirmDialog = (props: { onCustomAction?: any, customAction?: string |null, title: any; denyLabel?: string; children: any; open: any; setOpen: any; onConfirm: any; onDeny?: (() => void) | undefined; confirmButtonDisabled?: boolean }) => {
    const {
        customAction, onCustomAction, title, denyLabel, children, open, setOpen, onConfirm, onDeny = () => {
        }
    } = props;
    const {t} = useTranslation();

    const handleEnterOnMontantEnchereAuto = (e) => {
        if (e.key === 'Enter' ) {
            setOpen(false);
            onConfirm();
        }
        if (e.key === 'Escape' ) {
            setOpen(false);
            onDeny();
        }
    }

    return (
        <Dialog
            open={open}
            onKeyDown={handleEnterOnMontantEnchereAuto}
            fullWidth={true}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
            onClick={(e) => e.stopPropagation()}
        >
            <DialogTitle>
                <div className="flex justify-start items-center">
                    {title}
                </div>
                {/*<div className="flex justify-end items-center">*/}
                {/*    <IconButton onClick={() => setOpen(false)}>*/}
                {/*           <AiOutlineClose title={"Fermer"}/>*/}
                {/*    </IconButton>*/}
                {/*</div>*/}
            </DialogTitle>

            <DialogContent>{children}</DialogContent>

            <DialogActions>
                <Button
                    onClick={() => {
                        setOpen(false);
                        onDeny();
                    }}
                    color="info"
                >
                    {denyLabel ? denyLabel : t("common::Annuler")}
                </Button>
                <Button
                    variant={"text"}
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                    disabled={props.confirmButtonDisabled}
                >
                    { t("customerSpace::Oui")}
                </Button>
                {onCustomAction && customAction && <Button
                    onClick={() => {
                        setOpen(false);
                        onCustomAction();
                    }}
                    color="warning"
                >
                    {customAction}
                </Button>}
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmDialog;
