import {useLiveConnectionInternal} from "./LiveConnection";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function useFeatureFlagsUpdated(onMessage: () => void) {
    const location = useLocation();

    if (!location.pathname.startsWith("/banniere")) {
        return
    }

    const {connected, connectionRef} = useLiveConnectionInternal("feature-flags");

    useEffect(() => {
        if (connected)
            connectionRef.current?.on("featureFlagsChanged",  () => {
                onMessage()
            })

        return () => {
            connectionRef.current?.off("featureFlagsChanged")
        }
    }, [connected])
}


