import * as React from "react";
import {format} from "date-fns";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import {FormikErrors, useFormikContext} from "formik";
import {fr} from 'date-fns/locale'
import {useEffect} from "react";
import {PopperProps} from "@mui/material";
import {useDebounce} from "react-use";
import TextfieldWrapper from "./wrappers/TextfieldWrapper";

export function DateTimePicker({
                                   onChange,
                                   dateInput,
                                   label = undefined,
                                   name = undefined,
                                   disabled = false,
                                   required = false,
                                   isYearMonthMode = false,
                                   error = false,
                                   minDate,
                                   maxDate,
                                   helperText = '',
                                   popperProps,
                                   onKeyDown,
                                   margin = 'normal'
                               }: {
    label?: string,
    name?: string,
    dateInput?: Date | null,
    onChange?: (value: string) => void,
    isYearMonthMode?: boolean,
    minDate?: Date,
    maxDate?: Date,
    disabled?: boolean,
    required?: boolean,
    error?: boolean,
    helperText?: FormikErrors<Date>,
    popperProps?: Partial<PopperProps>,
    onKeyDown?: (e) => void,
    margin?: 'none' | 'dense' | 'normal'
}) {
    const [value, setValue] = React.useState<Date | null>(
        dateInput ?? null,
    );

    useEffect(() => setValue(dateInput ?? null), [dateInput]);
    useDebounce(() => handleChange(value), 750, [value]);

    const context = useFormikContext()
    const handleChange = (newValue: Date | null) => {
        let value1 = '';
        if (newValue) {
            const newDate = new Date(newValue);
            if (!isNaN(newDate.getTime())) {
                value1 = format(newDate, 'yyyy-MM-dd');
            }
        }

        onChange && onChange(value1);
        context && name && context.setFieldValue(name, value1);
    };

    return (
        <>
            <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    disabled={disabled}
                    inputFormat={isYearMonthMode ? "MMM yyyy" : "dd/MM/yyyy"}
                    views={isYearMonthMode ? ['year', 'month'] : undefined}
                    value={value}
                    maxDate={maxDate}
                    minDate={minDate}
                    onChange={setValue}
                    PopperProps={popperProps}
                    renderInput={(params: any) => <TextfieldWrapper {...params}
                                                                    dataTag={name}
                                                                    label={label}
                                                                    margin={margin}
                                                                    required={required}
                                                                    error={error}
                                                                    onKeyDown={onKeyDown}
                                                                    helperText={helperText}/>}
                />

            </LocalizationProvider>
        </>

    );
}
