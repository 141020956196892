import {Table as MuiTable, TableRow, useMediaQuery} from "@mui/material";
import React, {Dispatch, SetStateAction, useEffect, useRef} from "react";
import {DateRange} from "../DateRange";
import Box from '@mui/material/Box';
import {MultiSelectFilterProps} from "./filters/MultiSelectFilter";
import {ICustomFilterBoolean, TableQueryOptions} from "../../utils/UseFilteringSortingPagination";
import {ColumnFiltersState, Row, SortingState, Table} from "@tanstack/react-table";
import './index.css'
import {createTheme, styled, ThemeProvider, useTheme} from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import {useInterval} from "react-use";
import {TableMenuBar} from "./TableMenuBar";
import {FilterSearches} from "./FilterSearches";
import {FullScreenSpinner} from "../stories/FullScreenSpinner";
import {useTranslation} from "react-i18next";
import {deDE, enUS, frFR, Localization, plPL} from '@mui/material/locale'
import {Summary} from "./Summary";
import {TableBodyWrapper} from "./TableBody";
import {TableHeadWrapper} from "./TableHeadWrapper";
import {PaginationOptions, TablePaginationWrapper} from "./TablePaginationWrapper";
import {Element} from 'react-scroll'
import {SearchBarParams, SearchParamFilter} from "../../utils/UseFullTable";
import {SearchBar} from "./SearchBar";
import {useLayoutStore} from "../../stores/layout";
import {UseQueryResult} from "react-query";
import {Spinner} from "flowbite-react";

export const DEV_SETTINGS_KEY = 'DEV_SETTINGS';

export type FilterTypes =
    'MultiSelectFilter'
    | 'BooleanToggle'
    | 'SimpleDateRange'
    | 'SimpleDateRangeFilter'
    | 'MultiSelectFilterRemoteData'
    | 'ButtonToggleFilter'
    | 'SimpleDatePickerFilter'
    | 'NumericFilter'
    | 'SimpleNumericFilter';
export type FacetOptions<T> = {
    url?: string,
    searchParamFilters?: SearchParamFilter<T>[],
    additionalQueryParameters?: { left: string; operator: string; right: any }[]
    selector?: 'id' | 'name',
    key: keyof T | 'immo',
    onSelection?: (selected: { id: number, name: string }[]) => void,
    mapper?: (row: T) => ({ id: number, name: string }),
    onReset?: () => void,
    placeHolder?: string,
    values?: MultiSelectFilterProps[],
    booleanValues?: { value: boolean, label: string }[],
    component?: FilterTypes,
    operator?: '==' | '>=' | '<=' | '>' | '<' | '_=' | "@=" | "!@=" | "!_=" | "_-=" | "!_-=",
    indeterminate?: boolean
    disableCloseOnSelect?: boolean,
    width?: string,
    hideOptionId?: boolean,
    meta?: any,
};

export type TableOptions<T> = {
    resize?: boolean,
    instance: Table<T>,
    hidePagination?: boolean,
    dataQuery: UseQueryResult<any, unknown>,
    dataQuerySummary?: any,
    dataQueryExtracts?: UseQueryResult<any, unknown>,
    title: string,
    emojis?: number[],
    facets: FacetOptions<T>[] | null,
    onDateChange?: (selection: (DateRange | null)) => void,
    onDbleClick?: (row: T, instance?: Table<T>) => void,
    rowElement?: (row: T) => any,
    dateFilter?: Partial<{ startDate: string; endDate: string }> | null,
    filterOptions: TableQueryOptions<T>,
    renderSubComponent?: (props: { row: Row<T> }) => React.ReactElement,
    keyField: keyof T,
    hideActions?: boolean
    hideToolbar?: boolean
    hideHeader?: boolean
    showFiltersOnTop?: boolean
    hideFilters?: boolean,
    hideDefaultSearch?: boolean,
    showDefaultSearchBar?: boolean,
    defaultFilters?: ColumnFiltersState;
    defaultLocalFilters?: ColumnFiltersState;
    defaultSorts?: SortingState;
    isFixedColumnMode?: boolean;
    key: string;
    autoExpandRows: boolean;
    canExpandCollapse?: boolean;
    showSpinnerOnLoading?: boolean;
    disableExpandOnClick?: boolean;
    queryKey: string;
    showAllRows?: boolean;
    customFilterBoolean?: ICustomFilterBoolean<T>[],
    selectedRow?: number | null,
    setSelectedRow?: Dispatch<SetStateAction<number | null>>,
    defaultSearchKey?: keyof T,
    defaultSearchKeys?: keyof T[],
    createLink?: React.ReactElement,
    fitContent?: boolean,
    className?: (row: T) => string,
    refetchAutomatically?: boolean
    multilineActionsOnHeader?: boolean,
    filtersInModal?: boolean,
    useCustomPagination?: boolean,
    customPaginationOptions?: PaginationOptions,
    dateFilterColumn?: keyof T,
    hiddenCols?: Array<keyof T | 'select' | 'expander' | 'actions'>,
    fixedCols?: Array<keyof T | 'select' | 'expander' | 'actions'>,
    coumpoundFilter?: string,
    searchBarParams?: SearchBarParams<T>,
    filtersFromUrl?: ColumnFiltersState,
    isExportAsynchronous?: boolean,
    id?: string,
    disableLocalstorage?: boolean
}


export const StyledTableRow = styled(TableRow)(({theme}) => ({

    "&$selected, &$selected:hover": {
        backgroundColor: '#fae5ab !important'
    },
}));

export function FilteredTable<T>({
                                     options,
                                     actions,
                                     multiLineActions,
                                     children
                                 }: {
    multiLineActions?: React.ReactNode,
    options: TableOptions<T>,
    children?: React.ReactNode;
    actions?: (props: { row: Row<T> }) => React.ReactElement
}) {
    const {
        instance,
        dataQuery,
        resize = true,
    } = options;

    const {t, i18n} = useTranslation();


    useEffect(() => {
        if (dataQuery.status === 'success' && options.autoExpandRows) {
            instance.toggleAllRowsExpanded(true);
        }
    }, [dataQuery.status]);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    useInterval(
        () => {
            options.dataQuery.refetch({});
            options.dataQuerySummary?.refetch({});
        },
        options.refetchAutomatically ? 3600000 : null
    );

    const langObj: { [key: string]: Localization } = {
        fr: frFR,
        en: enUS,
        de: deDE,
        pl: plPL
    }

    const themeWithLocale = React.useMemo(
        () => createTheme(theme, langObj[i18n.language]),
        [langObj, theme],
    );

    const {size: sizeFromStore, width: widthFromStore} = useLayoutStore();
    const ref = useRef<any>();

    const hasNoRows = instance.getRowModel()?.rows.length == 0;
    const boundingClientRect = ref?.current?.getBoundingClientRect();
    const maxHeight = sizeFromStore + 50 - boundingClientRect?.y;

    return (
        <ThemeProvider theme={themeWithLocale}>
            {options.dataQuery.isFetching && <div className="fixed top-0 bg-yellow-200 rounded-lg z-50 p-3 left-1/2">
                <span>Chargement en cours...</span>
            </div>}
            <div style={options.fitContent ? {width: widthFromStore -56} : {}}>
                {isSmall && multiLineActions && instance.getSelectedRowModel().rows.length >= 1 && !options.multilineActionsOnHeader &&
                    <div>
                        {multiLineActions}
                    </div>
                }
                {options.searchBarParams && <div className="mb-3"><SearchBar options={options}/></div>}
                {!isSmall && !options.hideToolbar && !options.multilineActionsOnHeader &&
                    <Box>
                        <div >
                            <TableMenuBar options={options}
                                          key={`table-menu-bar-${options.queryKey}`}
                                          multiLineActions={multiLineActions}>{children}</TableMenuBar>
                        </div>
                    </Box>}
                {options.showFiltersOnTop &&
                    <FilterSearches options={options} table={instance}/>}
                <div ref={ref} className="overscroll-x-contain overflow-x-auto overflow-y-auto  max-w-[100%] relative">
                    <TableContainer
                        style={resize ? maxHeight ? {maxHeight: maxHeight}:{maxHeight: 600} : {maxHeight: 600}}
                        key={`container-${options.queryKey}`}>
                        <Element key={`element-${options.queryKey}`} id="scroll-container">
                            <MuiTable key={`table-${options.queryKey}`} size={"small"}
                                    stickyHeader
                                    aria-label="sticky table">
                                <TableHeadWrapper options={options} multiLineActions={multiLineActions}/>
                                <TableBodyWrapper options={options} actions={actions}/>
                                <Summary options={options}/>
                            </MuiTable>
                        </Element>
                        {hasNoRows && <div
                            className="flex flex-row justify-center items-center w-full h-full p-10">{dataQuery.isFetching ?
                            <Spinner/> : 'Aucune donnée'}</div>}
                    </TableContainer>
                    {options.showSpinnerOnLoading && options.dataQuery.isFetching && <FullScreenSpinner/>}
                </div>
                <div className="h-2"/>
                {options.customPaginationOptions && <TablePaginationWrapper options={options}/>}
            </div>
        </ThemeProvider>
    )
        ;
}

export default FilteredTable;


