import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, {useState} from "react";
import {FaFilter} from 'react-icons/fa';
import {FiFilter} from 'react-icons/fi';
import {FilterTypes} from "../FilteredTable";

export default function OperatorMenu({initialValue = "@=", onChange, filterType}:  {
    filterType?: FilterTypes,
    initialValue?: string, onChange: (value: string) => void }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [value, setValue] = useState<string>(initialValue);

    const operators = [

        { key: '==', value: 'est égal' },
        { key: '!=', value: 'n\'est pas égal' },

    ];

    //for number filter we need to add the numeric operators
     if (filterType == "NumericFilter" || filterType == "SimpleNumericFilter") {
         operators.push(
             { key: '>', value: 'est supérieur à' },
             { key: '>=', value: 'est supérieur ou égal à' },
             { key: '<', value: 'est inférieur à' },
             { key: '<=', value: 'est inférieur ou égal à' }
         );
     }
     else{
            operators.push(
                { key: '_=', value: 'commence par' },
                { key: '@=', value: 'contient' },
                { key: '!@=', value: 'ne contient pas par' },
                { key: '!_=', value: 'ne commence pas par' },
                { key: '_-=', value: 'finit par' },
                { key: '!_-=', value: 'ne finit pas par' }
            );
     }
    const menuItems = operators.map((operator,index) =>
        <MenuItem key={index}  selected={value == operator.key} onClick={() => {
                    handleClose();
                    onChange(operator.key);
                    setValue(operator.key);
                }}>{operator.value}</MenuItem>
    );


    return (
        <div className="ml-2 mt-4">
            <button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {value == "==" ? <FaFilter/> : <FiFilter/>}
            </button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {menuItems}
            </Menu>
        </div>
    );
}
