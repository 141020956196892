import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {DateRangePicker, defaultInputRanges, defaultStaticRanges} from "react-date-range";
import {useEffect, useState} from "react";
import {addDays, format, subDays} from "date-fns";
import {DateRange} from "../../DateRange";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import {IoCloseOutline} from "react-icons/io5";
import {resetSearchQuery} from "../../../utils/ResetSearchQuery";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function DateRangeFilter({
                                            onChange,
                                            dateFilter,
                                            children
                                        }: {
    children: React.ReactNode,
    onChange: (selection: DateRange | null) => void, dateFilter?: Partial<{ startDate: string; endDate: string } | null>
}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    useEffect(() => {
        if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
            setState([{
                startDate: new Date(dateFilter.startDate),
                endDate: new Date(dateFilter.endDate),
                key: 'selection'
            }])
            setState1(true)
        }
    }, [dateFilter])
    const [state, setState] = useState([
        {
            startDate: subDays(new Date(), 7),
            endDate: addDays(new Date(), 1),
            key: "selection"
        }
    ]);

    const [state1, setState1] = useState(false);

    const handleOnChange = (ranges: any) => {
        const {selection} = ranges;
        onChange({...selection, endDate: addDays(selection.endDate, 1)});

        setState([selection]);
        if (!state1) setState1(true);
    };

    const staticRangesLabels = {
        Today: "Aujourd'hui",
        Yesterday: "Hier",
        "This Week": "Cette semaine",
        "Last Week": "La semaine dernière",
        "This Month": "Ce mois-ci",
        "Last Month": "Le mois dernier",
    };

    const inputRangesLabels = {
        "days up to today": "jours jusqu'à aujourd'hui",
        "days starting today": "jours à partir d'aujourd'hui",
    };

    function translateRange(dictionary) {
        return (item) =>
            dictionary[item.label] ? {...item, label: dictionary[item.label]} : item;
    }

    const ruStaticRanges = defaultStaticRanges.map(
        translateRange(staticRangesLabels)
    );
    const ruInputRanges = defaultInputRanges.map(translateRange(inputRangesLabels));

    return (
        <div>
            <Button onClick={handleOpen}>
                <div className="p-2 border-0 shadow rounded cursor-pointer">{state1 ?
                    <div className="flex gap-2 items-center ">
                    <span>
                        <span>{format(state[0].startDate, 'dd/MM/yyyy')}</span>
                        <span className="p-2">→</span>
                        <span>{format(state[0].endDate, 'dd/MM/yyyy')}</span>
                    </span>
                        <button onClick={(event) => {
                            setState1(false);
                            onChange(null);
                            event.stopPropagation();
                            resetSearchQuery();
                        }}><IoCloseOutline size={30}/></button>
                    </div>
                    : <span className="whitespace-nowrap overflow-hidden overflow-ellipsis">{children}</span>}
                </div>
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <DateRangePicker
                        onChange={handleOnChange}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        staticRanges={ruStaticRanges}
                        inputRanges={ruInputRanges}
                    />
                </Box>
            </Modal>
        </div>
    );
}
