import * as React from 'react'
import {useState} from 'react'
import {FullPageSpinner2} from "../dashboards/_common/lib";
import * as auth from "../auth-provider";
import {useAsync} from "../utils/hooks";
import {get, setInstanceNew} from "../common/services/api/service";
import AppSettings, {
    ApiUrlNew,
    AzureBlobStorageUrlKey,
    AzureCdnUrlKey,
    BoUrl,
    DesktopUrl,
    DesktopUrlStaging,
    GoogleRecaptchaSiteKey
} from "../common/services/AppSettings";
import LoginPage from 'pages/authentication/LoginPage';
import {IBlacklist} from "../common/services/interfaces/IBlacklist";
import {useUserStore} from "../stores/user";
import {useQueryClient} from "react-query";
import {AutorisationProduit} from "../common/services/interfaces/MyCurrentSale";
import {PayboxAbonnement} from "../common/services/interfaces/IClientExtranet";
import {IAdministrateurDto} from "../common/services/interfaces/IAdministrateurRightsDto";
import {postMessageToParent} from "../services/PostMessageToParent";
import {useFeatureFlagCheck} from "../dashboards/_common/UseFeatureFlagCheck";

async function getUser(userFromStore: IUserContext | undefined, token?: string) {
    if (userFromStore) return userFromStore;

    let user = null
    const t = await auth.getToken()
    if (t || token) {
        // @ts-ignore
        user = {}
        user = await get('me');
    }

    return user
}


export interface IAuth {
    user?: IUserContext,
    login?: any,
    register?: any,
    logout?: any
    logoutSimple?: any
    impersonate?: any
    fetchUser?: any
    setToken?: any

    setLocation?: any
    location?: any
}

export interface IEnv {

    environment?: any,
    setEnvironment?: any,
    disconnected?: any,
    setDisconnected?: any
    check403?: any,
    setCheck403?: any,
    impersonateMode?: any,
    setImpersonateMode?: any,
}

export interface IDocumentUser {
    id: number;
    documentTypeId: number;
    isApprovedDocument: boolean;
    raisonsNonConformite: string[];
}

export interface ILabelUser {
    id: number;
    libelle: string;
    idLabel?: number;
}

export interface IUserContext {
    id: number,
    login: string,
    emailValid: boolean;
    nom: string,
    oidProfil: number,
    prenom: string,
    rights?: number[],
    droits?: number[],
    extracts?: number[],
    services?: number[],
    isAccountManager: boolean,
    isAccountManagerImmo: boolean,
    defaultUrl?: string,
    isClient?: boolean,
    langue?: string,
    langueId?: number,
    statut: string,
    isCertifie: boolean,
    documents?: IDocumentUser[],
    labels?: ILabelUser[],
    blackListTotal?: boolean,
    blackList?: IBlacklist[],
    autorisationProduits?: AutorisationProduit[],
    autorisationDemandes?: number[],
    ville?: string,
    countryId?: number,
    codePostal?: string,
    adresse?: string,
    complementAdresse?: string,
    abonnements?: PayboxAbonnement[],
    sites?: number[],
    childs: IAdministrateurDto[],
    favoris?: number[],
    siren?: string,
    cgvValider?: boolean,
    settings?: Settings[];
    serviceSettings?: Settings[];

}


export interface Settings {
    id: number;
    name: string;
    entity: string;
    payload: string;
    viewId: string;
}


interface IConfig {
    embedded: boolean;
    hideMenu: boolean;
    offerDocumentOpened: boolean;
    language?: string;
    isBoLegacy?: boolean;
}

const AuthContext = React.createContext<IAuth>({})
AuthContext.displayName = 'AuthContext'


function AuthProvider(props: any) {
    const [tokenFromRoute, setTokenFromRoute] = React.useState<string | null>(null)
    const [location, setLocation] = React.useState<string | null>(null)
    const {config} = useConfig();
    const {user: userFromStore, resetUser} = useUserStore();
    const queryClient = useQueryClient();
    const {
        data: user,
        isLoading,
        isIdle,
        isError,
        run,
        setData,
    } = useAsync()

    React.useEffect(() => {
        run(getUser(userFromStore, tokenFromRoute || undefined))
    }, [run, tokenFromRoute])

    const login = (form: { username: any; password: any; }) => auth.login(form).then(user => {
        setData(user);
        run(getUser(userFromStore))
    })

    const impersonate = (token: any) => {
        auth.impersonateUser(token);
        resetUser();
        queryClient.refetchQueries("me")
        run(getUser(undefined, token))
    }

    const fetchUser = (token: any) => {
        run(getUser(userFromStore, token))
    }

    const setToken = (token: any) => {
        setTokenFromRoute(token)
    }

    const register = (form: { username: any; password: any; }) => auth.register(form).then(user => setData(user))

    const postLogoutMessageToParentIfEmbedded = () => {
        if (!config.embedded) return;
        postMessageToParent({'subject': 'logoutBo'}, false, AppSettings.get(BoUrl));
    };

    const logout = () => {
        auth.logout()
        setData(null);
        resetUser();
        postLogoutMessageToParentIfEmbedded();
        // @ts-ignore
        window.location.reload()
        return <LoginPage/>
    }

    const logoutSimple = () => {
        auth.logout()
        setData(null);
        resetUser();
        queryClient.removeQueries("me");
        postLogoutMessageToParentIfEmbedded();
        // @ts-ignore
        return <LoginPage/>
    }

    if (isLoading || isIdle) {
        //todo fix spinner rule
        return <FullPageSpinner2/>
    }

    if (isError) {
        postLogoutMessageToParentIfEmbedded();
        auth.logout();
    }

    const value = {user, login, register, logout, logoutSimple, impersonate, fetchUser, setToken, location, setLocation}
    return <AuthContext.Provider value={value} {...props} />
    // throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
    const context = React.useContext(AuthContext)
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`)
    }
    return context
}


const ConfigContext = React.createContext({
    config: {embedded: false, hideMenu: false, offerDocumentOpened: false, isBoLegacy: false} as IConfig,
    setConfig: (_: IConfig) => {
    }
});


function useConfig() {
    const context = React.useContext(ConfigContext)
    if (context === undefined) {
        throw new Error(`useEmbedded must be used within a ConfigProvider`)
    }
    return context
}


function ConfigProvider(props: { children: any; }) {
    const {children} = props
    const [config, setConfig] = React.useState({
        embedded: false,
        hideMenu: false,
        offerDocumentOpened: false,
        isBoLegacy: false
    } as IConfig)

    const value = {config, setConfig}
    return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
}

const EnvironmentContext = React.createContext<IEnv>({
    environment: process.env.REACT_APP_PROXY_TARGET,
    setEnvironment: (_: any) => {
    }
});

function useEnvironment() {
    const context = React.useContext(EnvironmentContext)
    if (context === undefined) {
        throw new Error(`useEnvironment must be used within a EnvironmentProvider`)
    }
    return context
}

function EnvironmentProvider(props: { children: any; }) {
    const {children} = props
    // @ts-ignore
    const reactappproxytarget = window.REACT_APP_PROXY_TARGET;
    const reactappproxytarget1 = process.env.REACT_APP_PROXY_TARGET;

    const [environment, setEnvironment] = useState(reactappproxytarget || reactappproxytarget1)
    const [disconnected, setDisconnected] = useState(false)
    const [check403, setCheck403] = useState(false)
    const [impersonateMode, setImpersonateMode] = useState(false)
    const value = {
        environment,
        setEnvironment,
        disconnected,
        setDisconnected,
        check403,
        setCheck403,
        impersonateMode,
        setImpersonateMode
    }
    const shouldUseApiSignalR = useFeatureFlagCheck('USE_API_SIGNALR')

    switch (environment) {
        case "production":
        case "Production":
            AppSettings.setAgorastoreApiUrl("https://api.agorastore.fr/")
                .set(AzureCdnUrlKey, "https://ag-cdn-production.azureedge.net/")
                .set(AzureBlobStorageUrlKey, "https://agorastoredocuments.blob.core.windows.net/")
                .set(BoUrl, "https://admin.agorastore.fr/")
                .set(
                    ApiUrlNew,
                    "https://app-agorastore-api-vnext-prod-westeu-001.azurewebsites.net/"
                )
                .set(DesktopUrl, "https://www.agorastore.fr/")
                .set(DesktopUrlStaging, "https://staging.agorastore.fr/")
                .set(GoogleRecaptchaSiteKey, "6Ld3mokUAAAAACPq9VybPwRAS2EOy2_EEqOa4w2h")
                .setSignalRHubUrl(shouldUseApiSignalR
                    ? "https://app-agorastore-apisignalr-prod-westeu-001.azurewebsites.net/"
                    : "https://app-agorastore-api-vnext-prod-westeu-001.azurewebsites.net/"
                );
            break;
        case "uat":
        case "Uat":
            AppSettings.setAgorastoreApiUrl(
                "https://app-agorastore-api-uat-westeu-001.azurewebsites.net/"
            )
                .set(
                    AzureCdnUrlKey,
                    "https://cdn-endpoint-uat-westeu-001.azureedge.net/"
                )
                .set(AzureBlobStorageUrlKey, "https://sauatwesteu001.blob.core.windows.net/")

                .set(
                    BoUrl,
                    "https://app-agorastore-backoffice-uat-westeu-001.azurewebsites.net/"
                )
                .set(
                    ApiUrlNew,
                    "https://app-agorastore-apivnext-uat-westeu-001.azurewebsites.net/"
                )
                .set(DesktopUrl, "https://uat.agorastore.fr/")
                .set(GoogleRecaptchaSiteKey, "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI")
                .setSignalRHubUrl(shouldUseApiSignalR
                    ? "https://app-agorastore-apisignalr-uat-westeu-001.azurewebsites.net/"
                    : "https://app-agorastore-apivnext-uat-westeu-001.azurewebsites.net/"
                );
            break;
        case "development":
        case "Development":
        case "dev":
        case "Dev":
            AppSettings.setAgorastoreApiUrl(
                "https://app-agorastore-api-dev-westeu-001.azurewebsites.net/"
            )
                .set(
                    AzureCdnUrlKey,
                    "https://cdn-endpoint-dev-westeu-001.azureedge.net/"
                )
                .set(AzureBlobStorageUrlKey, "https://sadevwesteu001.blob.core.windows.net/")

                .set(
                    BoUrl,
                    "https://app-agorastore-backoffice-dev-westeu-001.azurewebsites.net/"
                )
                .set(
                    ApiUrlNew,
                    "https://app-apivnext-dev-westeu-001.azurewebsites.net/"
                )
                .set(DesktopUrl, "https://dev.agorastore.fr/")
                .set(GoogleRecaptchaSiteKey, "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI")
                .setSignalRHubUrl(shouldUseApiSignalR
                    ? "https://app-agorastore-apisignalr-dev-westeu-001.azurewebsites.net/"
                    : "https://app-apivnext-dev-westeu-001.azurewebsites.net/"
                );
            break;
        default:
            AppSettings.setAgorastoreApiUrl("http://localhost:58297/")
                .set(ApiUrlNew, "http://localhost:5150/")
                .set(AzureCdnUrlKey, "http://127.0.0.1:10000/devstoreaccount1/")
                .set(AzureBlobStorageUrlKey, "http://127.0.0.1:10000/devstoreaccount1/")
                .set(BoUrl, "http://localhost:58792/")
                .set(DesktopUrl, "http://localhost:58298/")
                .set(GoogleRecaptchaSiteKey, "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI")
                .setSignalRHubUrl(shouldUseApiSignalR ? "http://localhost:5241/" : "http://localhost:5150/");
    }

    setInstanceNew(AppSettings.get(ApiUrlNew), () => setDisconnected(true), () => setCheck403(true));

    return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>
}


export {AuthProvider, useAuth, ConfigProvider, useConfig, EnvironmentProvider, useEnvironment}
