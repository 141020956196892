import {TableOptions} from "./FilteredTable";
import {Row} from "@tanstack/react-table";
import React, {Fragment} from "react";
import {TableBody, TableRow} from "@mui/material";
import {styled} from "@mui/material/styles";
import {FilteredTableCell} from "./FilteredTableCell";
import {FilterTableContextMenu} from "./FilterTableContextMenu";

const TRow = styled(TableRow)(() => (
    {
        '&:nth-of-type(odd)': {
            backgroundColor: "white",
        },
        '&:nth-of-type(even)': {
            backgroundColor: "#f3f3f3",
        },
        "&:hover": {
            backgroundColor: "#caeafb !important"
        },
        "&.Mui-selected": {
            backgroundColor: "#fae5ab",
            color: "yellow",
            "&:hover": {
                backgroundColor: "#cdc72e"
            }
        }
    }));


export function TableBodyWrapper<T>({
                                        options, actions
                                    }: {
    options: TableOptions<T>,
    actions?: (props: { row: Row<T> }) => React.ReactElement
}) {
    const {instance} = options;
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const rows = instance.getRowModel()?.rows;

    return (<>
            {rows?.length > 0 && <TableBody>
                {rows?.map((row, index) => {
                    return (
                        <Fragment key={row.id}>
                            <TRow className="mb-10" key={row.id}
                                  onContextMenu={(event: React.MouseEvent) => {
                                      if (actions) {
                                          const isCurrentRowSelected = instance.getSelectedRowModel().rows.map(r => r.id).includes(row.id);
                                          if (!isCurrentRowSelected) {
                                              row.toggleSelected(true);
                                          }
                                          options.instance.getSelectedRowModel().rows.forEach((r) => {
                                              if (r.id !== row.id) {
                                                  r.toggleSelected(false)
                                              }
                                          });

                                          event.preventDefault();
                                          setContextMenu(
                                              contextMenu === null
                                                  ? {
                                                      mouseX: event.clientX + 2,
                                                      mouseY: event.clientY - 6,
                                                  }
                                                  : null,
                                          );
                                      }
                                  }}
                                  hover
                                  onDoubleClick={(e: any) => {
                                      if (e.target.nodeName === 'TD') {
                                          options.onDbleClick && options.onDbleClick(row.original, options.instance);
                                      }
                                  }}
                                  onClick={(e: any) => {
                                      if (e.target.nodeName === 'TD' || e.target.nodeName === 'DIV' || e.target.nodeName === 'SPAN') {
                                          !options.disableExpandOnClick && row.getCanExpand() && options.renderSubComponent && !row.getIsExpanded() && row.toggleExpanded(true);
                                          !options.disableExpandOnClick && row.getCanExpand() && options.renderSubComponent && row.getIsExpanded() && row.toggleExpanded(false);
                                      }
                                  }}

                                  selected={instance.getSelectedRowModel().rows.map(r => r.id).includes(row.id)}
                            >

                                <FilterTableContextMenu options={options} actions={actions} contextMenu={contextMenu} setContextMenu={setContextMenu}/>
                                {row.getVisibleCells().map((cell, indexCol) => <FilteredTableCell indexRow={index} indexColumn={indexCol} options={options} row={row} cell={cell} key={indexCol}/>)}
                            </TRow>
                            {
                                options.renderSubComponent && row.getIsExpanded() && (
                                    <tr>
                                        {/* 2nd row is a custom 1 cell row */}
                                        <td className="pl-16 pr-4" colSpan={row.getVisibleCells().length}>
                                            {options.renderSubComponent({row})}
                                        </td>
                                    </tr>
                                )
                            }
                        </Fragment>
                    )
                })}
            </TableBody>}
        </>
    );
}

export default TableBody;
