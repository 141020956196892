import {FormControlLabel, FormGroup, Switch} from '@mui/material';
import React, {useEffect, useRef} from 'react';
import {DebugRootKeyName, useFlags} from "./Flags";
import Tooltip from "@mui/material/Tooltip";
import {datadogLogs} from '@datadog/browser-logs';
import {Datadog} from "../dashboards/immo/Datadog";

type Props = {};

export default function DebugWatcher(props: Props) {
    const {toggleFlag, isFlagActive} = useFlags();
    const lastKeysRef = useRef([] as number[]);

    useEffect(() => {
        // up > up > down > down > left > right > left > right > B > A
        const konamiCode = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
        const keyUpListener = (e: KeyboardEvent) => {
            lastKeysRef.current.push(e.which);
            if (lastKeysRef.current.length > konamiCode.length) {
                lastKeysRef.current = lastKeysRef.current.slice(lastKeysRef.current.length - konamiCode.length);
            }
            if (lastKeysRef.current.join(',') === konamiCode.join(',')) {
                toggleFlag('debug', DebugRootKeyName);
            }
        };
        document.addEventListener('keyup', keyUpListener);
        return () => document.removeEventListener('keyup', keyUpListener);
    }, [toggleFlag]);

    return isFlagActive('debug', DebugRootKeyName) ? <DebugWatcherWindow/> : null;
}


function DebugWatcherWindow(props) {
    const [state] = React.useState(window.localStorage.getItem("DEV_SETTINGS"));

    const [checked, setChecked] = React.useState(false);
    useEffect(() => {
        const debug = JSON.parse(state ?? '{}').debug;
        debug && setChecked(debug === "true");
    }, [state]);

    return (

        <Tooltip title={""}>
            <div className="flex flex-row gap-2 fixed bottom-0 left-20">
                {checked && <Datadog fromLocation={true}/>}
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch checked={checked} onChange={(e) => {
                                if (e.target.checked) {
                                    setChecked(true);
                                    datadogLogs.logger.info('Debug', {Properties: {Debug: e.target.checked}})
                                    window.localStorage.setItem("DEV_SETTINGS", JSON.stringify({"debug": "true"}));
                                    location.reload()
                                } else {
                                    setChecked(false);
                                    window.localStorage.removeItem("DEV_SETTINGS");
                                    location.reload()
                                }
                            }}/>} label="Debug"/>
                </FormGroup>

            </div>
        </Tooltip>

    );
}


