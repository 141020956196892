import React from "react";
import {TableOptions} from "./FilteredTable";
import {ColumnFilter, Row} from "@tanstack/react-table";
import {useViewConfigForRoute} from "../../dashboards/_common/UseViewConfigForRoute";
import {Chip, Stack} from "@mui/material";
import {FaFilter, FaSortAmountDown, FaSortAmountUp} from "react-icons/fa";
import {FiFilter} from "react-icons/fi";
import {HtmlTooltip} from "../forms/Tooltip";
import Button from "@mui/material/Button";
import {RiFilterOffFill} from "react-icons/ri";

export function SortChip<T>({options}: {
    multiLineActions?: React.ReactNode,
    options: TableOptions<T>,
    children?: React.ReactNode;
    actions?: (props: { row: Row<T> }) => React.ReactElement
}) {
    const {
        instance,
    } = options;

    return (
        <>
            <Stack>
                <div className="flex gap-2 flex-col space-x-1">
                    {options?.filterOptions?.sorting?.map((sort, index: number) => {
                            const columns = instance.getAllLeafColumns().filter(f => f.id === sort.id);
                            if (columns.length == 0) {
                                return null;
                            }

                            const column = columns[0]
                            // @ts-ignore
                            const header = column?.columnDef?.header() || sort.id;

                            return <Chip key={index}
                                         icon={!!sort?.desc ? <FaSortAmountDown/> :
                                             <FaSortAmountUp size={20}/>}
                                         label={header}
                                         size="small"
                                         className=""
                                         variant="outlined"
                                         onDelete={() => column.clearSorting()}/>
                        })}
                </div>
            </Stack>

        </>
    );
}
