import {useDroits} from "../dashboards/_common/UseRights";
import {useAuth} from "../context/auth-context";

export function useDroitCheck(droit: Droits) {
    const droits = useDroits();
    const {user} = useAuth();

    return user?.droits
        ?.map(f => droits?.find(tt => tt.id === f))
        .map(l => l?.label)
        .includes(Droits[droit]) ?? false;
}

export enum Droits {
    SUPER_ADMIN = 1,
    PRODUIT = 3,
    DESCRIPTIF = 4,
    PAGE_ACCUEIL = 5,
    ADMINISTRATEUR = 8,
    UTILISATEUR = 9,
    COMMANDE = 10,
    ENCHERE = 11,
    PAIEMENT = 13,
    MOYEN_PAIEMENT = 14,
    CONTRAT_BANCAIRE = 15,
    PRODUIT_MISE_EN_LIGNE = 17,
    STATISTIQUES = 18,
    CGV = 19,
    SITE = 20,
    MAIL_PARAMS = 22,
    MODIF_UTILISATEUR = 24,
    ENVOI_MAIL_CONF = 25,
    NOUVEAUTES = 26,
    IMAGE_COMM = 27,
    GERER_ADMIN_AGORA = 33,
    GERER_FACTURATION = 35,
    PRODUITS_PORTAIL = 36,
    BANDEAU_NOUVEAUTE = 37,
    CREATION_SITE = 38,
    LISTE_ENCHERISSEURS = 39,
    GESTION_CAUTION = 40,
    FACTURE = 41,
    QUESTION = 42,
    RETRAIT = 43,
    STAT_VENTE = 44,
    STAT_VISITE = 45,
    TYPOLOGIE = 46,
    DEPOT_FICHIER = 47,
    PROVENANCE = 49,
    HISTORIQUE_PRIX = 50,
    CGV_VENTE_DIRECTE = 51,
    PARAMETRE_SITE = 52,
    AUTORISE_ENCHERISSEUR = 53,
    MANAGE_ACCESS_TO_THE_NUMBER_PLATE_AUTOFILL_SERVICE = 54,
    NUMBER_PLATE_AUTOFILL_SERVICE = 55,
    PRODUIT_PHARE = 56,
    FEEDBACK = 57,
    AUTORISATION = 58,
    SUPPRESSION_PRODUIT = 59
}