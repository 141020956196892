import {ErrorBoundary} from "react-error-boundary";
import React, {FC, Suspense, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth, useConfig, useEnvironment} from "./context/auth-context";
import Error500Page from "pages/error/Error500Page";
import i18next from "i18next";
import {I18nextProvider, initReactI18next} from "react-i18next";
import {ToastContainer} from "react-toastify";
import {injectStyle} from "react-toastify/dist/inject-style";
import {Rights} from "./utils/UseRightCheck";
import {postMessageToParent} from "services/PostMessageToParent";
import {useAdditionalParentToPost} from "services/UsePostToParent";
import {datadogLogs} from "@datadog/browser-logs";
import {useFeatureFlagCheck} from "./dashboards/_common/UseFeatureFlagCheck";
import {useSiteStore} from "./stores/site";
import AppSettings, {DesktopUrl} from "./common/services/AppSettings";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import useFeatureFlagsUpdated from "./utils/useFeatureFlagsUpdated";
import {pdfjs} from "react-pdf";


if (typeof window !== "undefined") {
    injectStyle();
}

const AuthenticatedPage = React.lazy(() => import("pages/AuthenticatedPage"));
const UnAuthenticatedPage = React.lazy(() => import("pages/UnauthenticatedPage"));
const TrackingPage = React.lazy(() => import("pages/TrackingPage"));
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export type WindowProps = Window & { PUBLIC_URL_BO: string | undefined, PUBLIC_URL_MP: string | undefined };
declare const window: WindowProps;

export const Root: FC = () => {
    const location = useLocation();
    const search = location.search;
    let urlSearchParams = new URLSearchParams(search);
    let language = urlSearchParams.get("language");
    const isProduction = process.env.NODE_ENV === "production";
    const isMP = window.location.origin === window.PUBLIC_URL_MP;

    const supportedLngs = ["fr", "en", "de", "pl", "es", "pt", "it", "nl", "dev"];
    const {user, setToken, location: locationFromRoute, setLocation, logout} = useAuth();


    i18next
        .use(HttpBackend)
        .use(initReactI18next);
    if (!i18next.isInitialized) {
        i18next.init({
            lng: language && supportedLngs.includes(language) ? language : (localStorage.getItem("language") || "fr"),
            supportedLngs: supportedLngs,
            fallbackLng: "fr",
            debug: false,
            ns: ["admin", "common"],
            defaultNS: "admin",
            nsSeparator: ":::",
            keySeparator: "::",
            backend: {
                backends: [
                    HttpBackend,
                    LocalStorageBackend
                ],
                backendOptions: [{
                    expirationTime: 7 * 24 * 60 * 60 * 1000 // 7 days
                }, {
                    loadPath: "/locales/{{lng}}/{{ns}}.json",
                }]
            },
            react: {
                useSuspense: false
            },
        });
    }

    if (language && supportedLngs.includes(language)) {
        localStorage.setItem("language", language);
        i18next.changeLanguage(language);
    }

    let embeddedFromRoute = urlSearchParams.get("embedded");
    let hideMenuFromRoute = urlSearchParams.get("hideMenu");
    let hideTabs = urlSearchParams.get("hideTabs");
    let offerDocumentOpenedFromRoute = urlSearchParams.get("offerDocumentOpened");

    const {setEnvironment, disconnected, setDisconnected, check403, setCheck403, impersonateMode} = useEnvironment();

    const {addSite, resetSite, site} = useSiteStore();

    useEffect(() => {
        datadogLogs.setGlobalContextProperty("location", location.pathname)
        if (location.pathname.indexOf("/sites/") === -1) {
            resetSite();
        }
    }, [location]);

    useEffect(() => {
        if (user) {
            datadogLogs.setUser({
                id: user.id.toString(),
                email: user.login,
            });
            datadogLogs.setGlobalContextProperty("Properties.usr", {
                id: user.id.toString(),
                email: user.login,
                isCertifie: user.isCertifie,
                isClient: user.isClient,
            });
        } else {
            datadogLogs.clearUser();
            datadogLogs.removeGlobalContextProperty("Properties.usr");
        }
    }, [user]);

    // @ts-ignore
    const env = window.REACT_APP_PROXY_TARGET || process.env.REACT_APP_PROXY_TARGET;
    const ffDatadogLocal = env === 'local'
        ? useFeatureFlagCheck('DATADOG_FRONTEND_LOCAL_TEST')
        : false;
    useEffect(() => {
        if (ffDatadogLocal) {
            datadogLogs.logger.setHandler('http');
        }
    }, [ffDatadogLocal]);

    const stage = urlSearchParams.get("releaseStage");
    if (stage) {
        setEnvironment(stage);
    }

    useFeatureFlagsUpdated(() => {
        if (location.pathname.indexOf("/parametres/featureflags") === -1) {
            window.location.reload();
        }
    })

    const parentToPost = useAdditionalParentToPost();

    useEffect(() => {
        if (disconnected) {
            if (isMP) {
                try {
                    datadogLogs.logger.log(`postMessage to parent logout`, {
                        Properties: {
                            isMP: isMP,
                            impersonateMode: impersonateMode,
                            check403: check403,
                            origin: window.location.origin,
                            PUBLIC_URL_MP: window.PUBLIC_URL_MP
                        }
                    });
                    postMessageToParent({'subject': 'logout'}, parentToPost, AppSettings.get(DesktopUrl));
                } catch (e) {
                    console.log(e)
                }
            }
            logout();
        }
    }, [disconnected])

    useEffect(() => {
        if (user && ((user.isClient && !isMP) || (!user.isClient && isMP))) {
            datadogLogs.logger.error(`User disconnected from wrong environment`, {
                Properties: {
                    isMP: isMP,
                    origin: window.location.origin,
                    PUBLIC_URL_MP: window.PUBLIC_URL_MP
                }
            });
            setDisconnected(true);
        }
    }, [isMP, user]);

    useEffect(() => {
        if (check403) {
            if (!isMP && !impersonateMode) {
                setDisconnected(true);
            } else {
                setCheck403(false);
            }
        }
    }, [check403])

    const token = urlSearchParams.get("token");

    if (token) {
        let parse: any;
        try {
            parse = JSON.parse(token);
        } catch (e) {
        }

        if (parse) {
            try {
                const {AccessToken, RefreshToken} = parse;
                window.localStorage.setItem("accessToken", AccessToken.Token);
                window.localStorage.setItem("refreshToken", RefreshToken.Token);
            } catch (e) {
                // temporary fix to avoid error when token is serialized in camel case
                const {accessToken, refreshToken} = parse;
                window.localStorage.setItem("accessToken", accessToken.token);
                window.localStorage.setItem("refreshToken", refreshToken.token);
            }
        }

        //embeddedFromRoute = "true";
    }
    let navigate = useNavigate();

    useEffect(() => {
        if (token) {
            setLocation(location)
            setToken(token);
        }

    }, []);


    const {setConfig, config} = useConfig();

    const embedded = embeddedFromRoute === "true"

    useEffect(() => {
        if (embeddedFromRoute || hideMenuFromRoute || offerDocumentOpenedFromRoute) {
            setConfig({
                embedded: embedded,
                hideMenu: Boolean(hideMenuFromRoute),
                offerDocumentOpened: Boolean(offerDocumentOpenedFromRoute),
                isBoLegacy: Boolean(hideTabs)
            });
        }
    }, [search])

    useEffect(() => {
        if (user?.langue) {
            const langPart = user?.langue.split('-')[0];
            try {
                i18next.changeLanguage(langPart).then(r => {
                    const langPartFromLs = localStorage.getItem("language");
                    if (langPartFromLs && langPartFromLs != langPart) {
                        localStorage.setItem("language", langPart!);
                        //window.location.reload();
                    }
                }).catch(err => console.log(err));
            } catch (e) {
                console.log(e);
            }

        }
        if (impersonateMode) navigate('/');
        else if (user && user.defaultUrl && location.pathname == '/') navigate(user.defaultUrl)
        else if (user && locationFromRoute) {
            navigate({pathname: locationFromRoute.pathname, search: locationFromRoute.search})
        } else if (user && user.isAccountManagerImmo && user.rights?.includes(Rights.DashboardImmoTab) && location.pathname == '/') navigate('/dashboard-immo/global')
        else if (user && user.isAccountManager && user.rights?.includes(Rights.ComptesTab) && location.pathname == '/') navigate('/comptes')
    }, [user, locationFromRoute]);

    const ErrorHandler = (error: Error, info: { componentStack: string }) => {
        // datadogLogs.logger.error(`Component : Error : ${error.message}`, {...info, stack: error.stack});
        console.trace(error, info);
    }
    const ErrorFallback = (error: any) => {
        return <Error500Page message={error.error.message} stack={error.error.stack} visible={!isProduction}/>
    }

    const embedde = embeddedFromRoute && embeddedFromRoute === 'true' || config.embedded;

    const isIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    return (
        <Suspense>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={ErrorHandler}
                onReset={() => {
                }}
            >
                <I18nextProvider i18n={i18next}>
                    {user ? <AuthenticatedPage embedded={embedde}/> : <UnAuthenticatedPage/>}
                    {!embedde && !isIframe && <TrackingPage/>}
                </I18nextProvider>
                <ToastContainer/>
            </ErrorBoundary>
        </Suspense>
    );
};
