import React, {useEffect} from "react";
import {TableOptions} from "./table/FilteredTable";
import {FormControlLabel, FormGroup, Switch} from "@mui/material";
import {Column} from "@tanstack/react-table";

export function CustomFilter<T>(props: { options: TableOptions<T> }) {
    const {
        instance,
        customFilterBoolean,
    } = props.options;

    if (!customFilterBoolean) {
        return null;
    }

    const [toggleValue, setToggleValue] = React.useState(false);
    const [targetOnValue, setTargetOnValue] = React.useState<boolean | number | undefined>();
    const [targetOffValue, setTargetOffValue] = React.useState<boolean | null>();
    const [header, setHeader] = React.useState('');
    const [col, setCol] = React.useState<Column<any, any>>();

    useEffect(() => {
        const {key, targetOnValue, targetOffValue, defaultActivated, detailOn, detailOff} = customFilterBoolean[0]
        setToggleValue(defaultActivated);
        setHeader(detailOn);
        setTargetOnValue(targetOnValue);
        setTargetOffValue(targetOffValue);
        if (defaultActivated) {
            col?.setFilterValue(`==${targetOnValue}`)
        }
        const column = instance.getColumn(key.toString());
        setCol(column)
    }, []);

    useEffect(() => {
        const {key, detailOn, detailOff} = customFilterBoolean[0]
        setHeader(!toggleValue ? detailOn : detailOff);
    }, [toggleValue]);
    return (
        <FormGroup>
            <FormControlLabel
                labelPlacement="end"
                control={<Switch
                    color="warning"
                    checked={toggleValue}
                    onChange={(val  ) => {
                        const checked = val.target.checked;
                        setToggleValue(checked);
                        if (!checked) {
                            col?.setFilterValue(targetOffValue !== undefined ? `==${targetOffValue}` : undefined);
                        } else {
                            col?.setFilterValue(`==${targetOnValue}`)
                        }
                    }}
                />}
                label={header}
            />
        </FormGroup>
    )
}


