import {TableOptions} from "./FilteredTable";
import {Cell, flexRender, Row} from "@tanstack/react-table";
import {TableCell} from "@mui/material";
import {MdKeyboardArrowDown, MdKeyboardArrowRight} from "react-icons/md";
import React from "react";
import {Element} from 'react-scroll'

export function FilteredTableCell<T>({
                                         options,
                                         row,
                                         cell,
                                         indexRow,
                                         indexColumn

                                     }: {
    indexRow: number,
    indexColumn: number,
    options: TableOptions<T>,
    row: Row<T>,
    cell: Cell<T, any>
}) {
    const {
        instance,
    } = options;

    //get meta data
    const meta = cell.column.columnDef.meta;
    //get flexCenter value from meta
    const flexCenter = (meta && (meta as any).flexCenter) ?? "start";
    const className = "justify-" + flexCenter + " items-center";
    return (
        <TableCell 
            className={options.className && options.className(row.original)}
            {...{
                key: `row-${indexRow}_col-${indexColumn}`,
                style: {
                    width: cell.column.getSize(),
                    cursor: 'context-menu'
                },
            }}>
            <Element id={`scroll-element-${indexColumn}-${indexRow}`} key={`scroll-element-${indexColumn}-${indexRow}`}>
                {cell.getIsGrouped() ? (
                    // If it's a grouped cell, add an expander and row count
                    <>
                        <button
                            {...{
                                onClick: row.getToggleExpandedHandler(),
                                style: {
                                    cursor: row.getCanExpand()
                                        ? 'pointer'
                                        : 'normal',
                                },
                            }}
                        >
                            <div className='flex flex-row gap-2'>
                                {row.getIsExpanded() ?
                                    <MdKeyboardArrowDown className="text-3xl"/> :
                                    <MdKeyboardArrowRight className="text-3xl"/>}{' '}
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}{' '}
                                <div className="font-bold">({row.subRows.length})</div>
                            </div>

                        </button>
                    </>
                ) : cell.getIsAggregated() ? (
                    // If the cell is aggregated, use the Aggregated
                    // renderer for cell
                    flexRender(
                        cell.column.columnDef.aggregatedCell ??
                        cell.column.columnDef.cell,
                        cell.getContext()
                    )
                ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                    // Otherwise, just render the regular cell
                    <div className={`flex ${className}`}>{flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext())
                    }</div>)}
            </Element>
        </TableCell>
    );
}
