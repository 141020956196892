import React, {useState} from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ButtonBase from "@mui/material/ButtonBase";

export default function ExpandCard({children, title, initialOpen}: {
    initialOpen?: boolean,
    children: JSX.Element,
    title?: string
}) {
    const [open, setOpen] = useState(initialOpen);

    return <Card sx={{border: "1px solid rgba(211,211,211,0.6)"}}>
        <ButtonBase onClick={() => setOpen(!open)} className="w-full">
            <CardHeader title={title}
                        action={<IconButton onClick={(event) => {
                            event.stopPropagation();
                            setOpen(!open);
                        }}
                                            aria-label="expand"
                                            size="small">
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>}/>
        </ButtonBase>
        <div style={{backgroundColor: 'rgba(211,211,211,0.4)'}}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <CardContent>
                    {children}
                </CardContent>
            </Collapse>
        </div>
    </Card>;
}