import {useViewConfigStore} from "../../stores/view";
import {useLocation} from "react-router-dom";
import _ from "lodash";
import {FeatureTableProps} from "../../utils/UseFullTable";

export function useViewConfigForRoute() {
    const {viewConfig} = useViewConfigStore();
    const location = useLocation()
    const filter = viewConfig?.filter((view) => view && view.label === "Produits");
    const flatten = function (item) {
        return [item, _.flatMapDeep(item.items, flatten)];
    };

    const result = _.flatMapDeep(filter, flatten).filter(r => r.id) as unknown as FeatureTableProps<unknown>[];

    return result.find(v => `/${v.id}` === location.pathname);

}
