import React, {useState} from "react";
import {DEV_SETTINGS_KEY} from "../components/table/FilteredTable";

export function useDebugMode(): boolean {

    const saved = localStorage.getItem(DEV_SETTINGS_KEY);
    const initialValue = saved && JSON.parse(saved);
    if (initialValue) {
        return initialValue.debug
    } else
        return false;

}
