import Tooltip from "@mui/material/Tooltip";
import {CircleButton, FullPageSpinner} from "../dashboards/_common/lib";
import {FaTimesCircle} from "react-icons/fa";
import * as React from "react";
import {postMessageToParent} from "../services/PostMessageToParent";
import {Button} from "@mui/material";
import ButtonWrapper from "./wrappers/ButtonWrapper";

export function LoadingButton({label, highlight, onClick, children, mutation, className, ...rest}) {
    return (
        <div>
            <ButtonWrapper
                onClick={(event) => {
                    onClick();
                    event.preventDefault();
                }}
                disabled={(mutation?.isFetching || mutation?.isLoading) || rest.disabled}
                className={className ? className : `${!highlight ? 'bg-blue-electric' : 'bg-red-500'}  h-14 disabled:bg-[#D8D8D8] text-[11px] text-white py-2 px-4 w-full active:shadow-md rounded-full focus:outline-none active:shadow-none mr-2 md:h-12 font-extrabold md:mt-8 md:block w-48 text-center`}

                {...rest}>
                {(mutation?.isFetching || mutation?.isLoading) ?
                    <div className="flex items-center justify-center"><FullPageSpinner/></div> : mutation?.isError ?
                        <FaTimesCircle/> : children}
            </ButtonWrapper>
        </div>
    )
}
