import React, {useEffect, useState} from 'react';
import {TableOptions} from "./FilteredTable";
import {Table} from "@tanstack/react-table";
import {BiColumns, BiDownArrowAlt} from "react-icons/bi";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {
    Button,
    Checkbox,
    FormControlLabel
} from "@mui/material";
import ModalEditComponent from "../modals/ModalEditComponent";
import {MdDragIndicator} from 'react-icons/md';
import {useMutation, useQueryClient} from "react-query";
import {put} from "../../common/services/api/service";
import {Rights, useRightCheck} from "../../utils/UseRightCheck";
import {MyTextAreaOrJson} from "../forms/TextArea";
import {SimpleFormModal} from "../SimpleFormModal";
import {useAdministrateurSettings} from "../../dashboards/_common/UseAdministrateurSettings";

const getLocalStorageItem = (key: string) => {
    const saved = localStorage.getItem(key);
    return saved && JSON.parse(saved);
}

export function TableColumnSelectMenu<T>({
                                             table,
                                             options
                                         }: {
    options: TableOptions<T>,
    table: Table<T>
}) {

    const filter = table.getAllLeafColumns().filter(f => f.id !== 'select' && f.id !== 'expander');
    const selectColumn = table.getAllLeafColumns().find(f => f.id === 'select');
    const expanderColumn = table.getAllLeafColumns().find(f => f.id === 'expander');
    const {setColumnOrder, resetColumnOrder, setColumnVisibility} = table
    const {
        instance,
        key,
        id,
    } = options;

    const localStorageItem = getLocalStorageItem(key);
    const hasLocalStorageItem = !!localStorageItem;
    const [colOrder, setColOrder] = useState<string[]>([]);
    const useLocal = localStorageItem;
    const adminSettings = useAdministrateurSettings();


    const entity = adminSettings.find(s => s.viewId === id);
    function extracted() {
        const obj = options.fixedCols && !useLocal ? options.fixedCols.map(c => c.toString())
            : localStorageItem ? localStorageItem : instance.getAllLeafColumns().filter(c => c.getIsVisible()).map(c => c.id);
        setColOrder(obj);
    }

    useEffect(() => {
        extracted();
    }, []);

    const handleDrop = (droppedItem: any) => {
        if (!droppedItem.destination) return;
        let updatedList = [...filter.map(d => d.id)];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        if (expanderColumn) updatedList = ['expander', ...updatedList];
        if (selectColumn) updatedList = ['select', ...updatedList];
        setColumnOrder(updatedList)
        const visibleColumns = updatedList.map((id: string) => instance.getAllLeafColumns().find(c => c.id === id)).filter((c: any) => c.getIsVisible());
        const value = visibleColumns.map((c: any) => c.id);
        localStorage.setItem(key, JSON.stringify(value));
        setColOrder(value)
    };

    const queryClient = useQueryClient();
    const mutation = useMutation((data: any) =>
        put(`/administrateurSettings/${id}/fixedCols`, {fixedCols: data}), {
        onSuccess: () => {
            queryClient.refetchQueries(['me']);
        }
    })

    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = React.useState('');
    let options1 = filter.map((value,index) => {
        // @ts-ignore
        let columnDef = value?.columnDef.header();
        return ({
            name: columnDef,
            id: value.id,
            key:index,
            column: value,
        });
    });
    options1 = options1.sort((a, b) => a.name.localeCompare(b.name));
    const canEditColumns = useRightCheck(Rights.AdministrationSettingsTab)


    return (
        <ModalEditComponent
            maxWidth={"md"}
            onCloseDialog={() => {
                setSearchQuery('');
            }}
            fullHeight={false}
            title={"Choix des colonnes à afficher"}
            icon={<BiColumns className={"text-white"} size={20}/>}
            label={"Choix des colonnes à afficher"}
            displaymode={'component'}
            tooltip={"Choix des colonnes à afficher"}
        >
            <div className={"flex  gap-2  max-h-[400px] w-full"}>
                <div className={"flex flex-col w-full gap-2"}>
                    <input type="text" placeholder={"rechercher une colonne"}
                           autoFocus
                           className="border border-black rounded p-2 w-full"
                           onChange={(e) => {
                               const value = e.target.value;
                               setSearchQuery(value);
                               const filter = instance.getAllLeafColumns().filter(f => f.id !== 'select' && f.id !== 'expander');
                               const filtered = filter.filter(f => {
                                   // @ts-ignore
                                   const columnDef = f?.columnDef.header();
                                   return columnDef.includes(value);
                               });
                               setSelectedColumns(filtered.map(f => f.id));
                           }}/>
                    <div className={"overflow-auto  w-full"}>
                        {options1
                            .filter((column: any) => column.name.toLowerCase().includes(searchQuery.toLowerCase()))
                            .map((column: any, index: number) => {
                                return (
                                    <div key={index}>
                                        <FormControlLabel key={index}
                                            control={
                                                <Checkbox 
                                                    onChange={(e) => {
                                                        const checked = e.target.checked;
                                                        column.column.toggleVisibility(checked);

                                                        //update the localstorage key => remove the key from the list if checked is false
                                                        if (!checked) {
                                                            const updatedList = colOrder.filter((c: string) => c !== column.id);
                                                            setColOrder(updatedList);
                                                            localStorage.setItem(key, JSON.stringify(updatedList));
                                                        } else {
                                                            //add the key to the list if checked is true
                                                            const updatedList = [...colOrder, column.id];
                                                            setColOrder(updatedList);
                                                            //update localstorage
                                                            localStorage.setItem(key, JSON.stringify(updatedList));
                                                        }
                                                    }}
                                                    checked={column.column.getIsVisible()}
                                                    name={column.name}
                                                    key={index}
                                                />
                                            }
                                            label={column.name}
                                        /></div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className={"overflow-auto  w-full"}>
                    <div className={"flex gap-2 items-center mb-2"}>
                        <BiDownArrowAlt/>
                        <span>Colonnes sélectionnées ({filter.filter(c => c.getIsVisible()).length})</span>
                    </div>

                    <DragDropContext onDragEnd={handleDrop}>
                        <Droppable droppableId="list-container">
                            {(provided) => {
                                return (
                                    <div className="flex gap-2 flex-col w-full"
                                         {...provided.droppableProps}
                                         ref={provided.innerRef}>
                                        {filter
                                            .map((column: any, index: number) => {
                                                const visible = column.getIsVisible();
                                                const className = visible ? "" : "hidden";
                                                return (
                                                    <div className={className}>
                                                        <Draggable key={column.id.toString()}
                                                                   draggableId={column.id}
                                                                   index={index}>
                                                            {(provided, snapshot) => {
                                                                const header = column?.columnDef?.header() || column.id
                                                                return (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        className="p-2 mx-1 border border-gray-300 rounded flex items-center bg-white justify-between"
                                                                    >
                                                                        <div
                                                                            className={"flex gap-2 items-center"}>
                                                                            <MdDragIndicator/>
                                                                            {header}
                                                                        </div>
                                                                        <button
                                                                            onClick={() => {
                                                                                column.toggleVisibility(false);
                                                                                const updatedList = colOrder.filter((c: string) => c !== column.id);
                                                                                setColOrder(updatedList);
                                                                                localStorage.setItem(key, JSON.stringify(updatedList));

                                                                            }}
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable></div>
                                                );
                                            })}
                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </DragDropContext></div>
            </div>

            {canEditColumns && <div className={"flex justify-between mt-4"}>
                <Button
                    color={"primary"}
                    disabled={mutation.isLoading}
                    onClick={() => {
                        const lists = filter.filter(c => c.getIsVisible()).map(c => c.id);
                        mutation.mutate(lists);
                    }}
                >
                    {mutation.isLoading ? 'Chargement...' : 'Enregistrer'}
                </Button>

                {entity && <SimpleFormModal
                    showDelete={() => false}
                    fullHeight={false}
                    title={entity => entity.name}
                    cols={[
                        {
                            key: "payload", field:
                                <MyTextAreaOrJson label="payload"
                                                name="payload"/>
                        }]}
                    right={Rights.IT}
                    entity={entity}
                    queryKey={'administrateurSettings'}
                    url={`administrateurSettings/${entity.id}`}/>}


            </div>}

            {hasLocalStorageItem && <Button
                color={"primary"}
                onClick={() => {
                    localStorage.removeItem(key);
                    resetColumnOrder();
                    extracted();
                    window.location.reload();
                }}
            >
                {'Réinitialiser'}
            </Button>}
        </ModalEditComponent>
    );
}


