import React, {useEffect} from 'react';
import DialogContent from "@mui/material/DialogContent";
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from "@mui/icons-material/Close";
import {EnchereSource, useEnchereContextStore} from "../../stores/enchere";
import {DialogTitle} from '@mui/material';
import {FullPageSpinner} from "../../dashboards/_common/lib";
import ButtonWrapper from "../wrappers/ButtonWrapper";

const useStyles = makeStyles({
    dialog: {
        position: 'fixed',
        left: 0,
        top: 0
    }
});
const ConfirmDialogMarketPlace = (props: {
    children: any;
    open: any;
    setOpen: any;
    onConfirm: any;
    onDeny?: (() => void) | undefined;
    withActions?: boolean,
    noMargin?: boolean,
    isLoadingOnConfirm?: boolean,
    leftAction?: JSX.Element,
    title?: string,
    submitDisabled?: boolean,
    dataTag?: string,
    initialOpen?: boolean,
    disabled?: boolean
}) => {
    const {
        dataTag,
        disabled,
        initialOpen = false,
        children, open, setOpen, onConfirm, onDeny = () => {
        }, withActions = true, noMargin = false, isLoadingOnConfirm = false, leftAction, title, submitDisabled = false
    } = props;
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = React.useState(false);
    const classes = useStyles();
    const {enchereContext} = useEnchereContextStore();

    const handleEnterOnMontantEnchereAuto = (e) => {
        if (e.key === 'Enter') {
            setOpen(false);
            onConfirm();
        }
        if (e.key === 'Escape') {
            setOpen(false);
            onDeny();
        }
    }

    return (

        <Dialog
            open={open}
            onKeyDown={handleEnterOnMontantEnchereAuto}
            fullWidth={true}
            classes={enchereContext.source === EnchereSource.SideBar ? {
                paper: noMargin ? `${classes.dialog} mx-0 w-full` : classes.dialog
            } : {
                paper: noMargin ? 'm-0 w-full' : ''
            }}
            onClose={() => {
                setOpen(false);
                onDeny();
            }}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle className="bg-blue-dark flex justify-between px-0 py-0">
                <div className="text-center text-white my-auto ml-6">
                    {title}
                </div>
                <ButtonWrapper
                    dataTag={`${dataTag}-close`}
                    className="text-white h-full font-bold text-base py-5 px-3 md:py-6 md:px-4 hover:bg-blue-lightened-dark"
                    onClick={() => {
                        setOpen(false);
                        onDeny();
                    }}
                    disabled={isLoading}
                >
                    <p className="my-auto">{t("common::Fermer")}</p>
                    <CloseIcon className={"ml-2"}/>
                </ButtonWrapper>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            {withActions &&
                <div className="flex items-stretch h-fit">
                    {leftAction || <ButtonWrapper data-modal-toggle="defaultModal" type="button"
                                                  dataTag={`${dataTag}-cancel`}
                                                  className="uppercase text-blue-dark bg-white hover:bg-gray-100 rounded-bl-lg border-2 text-sm font-medium px-8 py-8 w-1/2 hover:text-gray-900 focus:z-10"
                                                  disabled={isLoading}
                                                  onClick={() => {
                                                      setOpen(false);
                                                      onDeny();
                                                  }}>{t("common::Annuler")}</ButtonWrapper>}
                    <ButtonWrapper
                        dataTag={`${dataTag}-confirm`}

                        data-modal-toggle="defaultModal" type="submit"
                        className={`uppercase text-white bg-blue-electric hover:opacity-70 font-medium border-2 rounded-br-lg text-sm px-8 py-8 text-center w-1/2 focus:z-10 ${isLoading ?? 'bg-gray-0'}`}
                        disabled={submitDisabled || isLoading || disabled}
                        onClick={async () => {
                            if (isLoadingOnConfirm) {
                                setIsLoading(true);
                            } else {
                                setOpen(false);
                            }
                            await onConfirm();
                            setIsLoading(false);

                        }}>{isLoading ? <div className="flex items-center justify-center"><FullPageSpinner/>
                    </div> : t("common::Valider")}</ButtonWrapper>
                </div>}

        </Dialog>
    );
};
export default ConfirmDialogMarketPlace;
