import React from "react";
import {useViewConfigForRoute} from "../../dashboards/_common/UseViewConfigForRoute";
import {Droits, useDroitCheck} from "utils/UseDroitCheck";

export function ActionWrapper({droit, actionId, isExclusive, children}: {
    children: React.ReactNode,
    actionId?: string,
    droit?: Droits,
    isExclusive?: boolean
}) {
    if (!actionId){
        return <>{children}</>;
    }

    const useViewConfigForRoute1 = useViewConfigForRoute();
    const actions = useViewConfigForRoute1?.actions;

    if (droit && !useDroitCheck(droit)) {
        return null;
    }


    if (!actions && !isExclusive) {
        return <> {children} </>;
    }

    const action = actions?.find(a => a === actionId);
    if (!action && actionId !== 'debug') {
        return null;
    }

    return <div className="flex gap-2 items-center">
        {children}
    </div>
}
