import { useRights } from "../dashboards/_common/UseRights";
import { useAuth } from "../context/auth-context";
import { log } from "console";

export function useRightCheck(right: Rights) {
  const { user } = useAuth();
  if (!user) {
    return false;
  }

  const rights = useRights();

  return (
    user?.rights
      ?.map((f) => rights?.find((tt) => tt.id === f))
      .map((l) => l?.label)
      .includes(Rights[right]) ?? false
  );
}

export function useRightCheckList(right: (Rights | undefined)[]): boolean[] {
  const allRights = useRights();
  const { user } = useAuth();
  const userRights = user?.rights
    ?.map((id) => allRights?.find((r) => r.id === id))
    .map((l) => l?.label);

  return right.map((r) => !r || (userRights?.includes(Rights[r]) ?? false));
}

export function useRightsCheck(rights: Rights[]) {
  const allRights = useRights();
  const { user } = useAuth();

  const userRights = user?.rights
    ?.map((id) => allRights?.find((r) => r.id === id))
    .map((l) => l?.label);
  const rightsToCheck = rights.map((right) => Rights[right]);
  const intersection = userRights?.filter(
    (right) => right && rightsToCheck.includes(right)
  );

  return (intersection && intersection.length > 0) ?? false;
}

export enum Rights {
  AutorisationsTab = 1,
  BlacklistTab = 2,
  ClientsTab = 3,
  DemandesTab = 4,
  DocumentsTab = 5,
  EncheresTab = 6,
  FacturesTab = 7,
  ProduitsTab = 8,
  SitesTab = 9,
  BlacklistsCreation = 10,
  BlacklistsSuppressionClientInterdiction = 11,
  BlacklistsSuppressionVendeurInterdiction = 12,
  ClientsCreation = 13,
  ClientsEdition = 14,
  ClientsEditionAutorisations = 15,
  ClientsEditionExtranet = 16,
  ClientsSuppression = 17,
  ClientsSuppressionSite = 18,
  ClientsTriggerChangePassword = 19,
  DocumentsEdition = 20,
  DocumentsUpload = 21,
  EncheresProgrammeesEdition = 22,
  FacturesDepotChorus = 23,
  FacturesEdition = 24,
  FacturesGenerationPdf = 25,
  FacturesSynchroChorus = 26,
  FacturesTelechargementPdf = 27,
  ProduitsRevocationDemandeAutorisation = 28,
  ProduitsValidationDemandeAutorisation = 29,
  SitesEdition = 30,
  SitesEditionUseChorus = 31,
  ProduitsEdition = 32,
  ProduitsEditionVerificationStatus = 33,
  ProduitAnnulerAnnulation = 35,
  ClientModifierEnchere = 36,
  AdministrateursTab = 37,
  AdministrateursEditionRights = 38,
  AdministrateursEditionExtracts = 39,
  AdministrateursEditionServices = 40,
  RegroupementEdition = 41,
  ProduitChangeStatutEnLigne = 42,
  ProduitEditionTauxCommission = 43,
  UserImpersonation = 44,
  DashboardImmoTab = 45,
  CreneauVisites = 46,
  DemandesAvoirsTab = 47,
  DemandesAvoirsCreation = 48,
  DemandesFacturesExceptionnellesTab = 49,
  DemandesFacturesExceptionnellesCreation = 50,
  IT = 51,
  DemandesFactureExceptionnelleValidationRefus = 52,
  ProduitAnnulerDemandeAnnulation = 53,
  ProduitAnnulerVente = 54,
  FacturesCreation = 55,
  BaseDocumentaireTab = 56,
  BaseDocumentaireCreation = 57,
  BaseDocumentaireModification = 58,
  BaseDocumentaireSuppression = 59,
  GestionBaseDocumentaireTab = 60,
  ProvenanceCreation = 61,
  ProvenanceModification = 62,
  EditTemplates = 63,
  BordereauxTab = 64,
  FacturationTab = 65,
  ComptesTab = 66,
  ProduitArchivage = 67,
  ProvenancesTab = 68,
  LieuRetraitTab = 69,
  ProduitAnnulerVenteQuandTerminee = 70,
  BordereauxFullView = 71,
  AlertesSuppression = 72,
  DemandeInfosVendreTab = 73,
  ErpTab = 74,
  PricingTab = 75,
  ExtractTab = 76,
  SiteCreation = 77,
  CrmTab = 78,
  FacturesAcheteursForceGeneration = 79,
  AdministrationSettingsTab = 80,
  DemandesFacturesImmoTab = 81,
  DemandesFacturesImmoCreation = 82,
}
