import { useRef, useLayoutEffect, useCallback } from 'react';

export function useMount() {
  const _mounted = useRef(true);
  useLayoutEffect(() => {
    return () => {
      _mounted.current = false;
    };
  }, []);
  return useCallback(() => _mounted.current, []);
}
